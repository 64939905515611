import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Context } from "../../backend/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

import firebase from "firebase/compat/app";
import LoadingScreen from "../loadingScreen";

import { connect } from "react-redux";

function Settings(props) {
  const { email, telegram_notifications } = React.useContext(Context);

  const [filled, setFilled] = useState(telegram_notifications.filled);
  const [isResetPasswordLinkSent, setResetPasswordLinkSent] = useState(false);
  const [isMMBotsCSVDownloaded, setMMbotsCSVDownloded] = useState(false);
  const [ishedgeBotsCSVDownloaded, sethedgebotsCSVDownloded] = useState(false);
  const [istickerBotsCSVDownloaded, settickerbotsCSVDownloded] =
    useState(false);
  const [isMMBotsCSVLoaded, setMMbotsCSVLoaded] = useState(0);
  const [ishedgeBotsCSVLoaded, sethedgebotsCSVLoaded] = useState(0);
  const [istickerBotsCSVLoaded, settickerbotsCSVLoaded] = useState(0);
  const [isMarketDataSyn, setMarketDataSyn] = useState(false);
  const [dealComplete, setDealComplete] = useState(
    telegram_notifications.dealComplete
  );

  const [uploading, setUploading] = useState(false);

  const [mmBotsNewCSV, setMMBotsNewCSV] = useState([]);

  useEffect(() => {
    setFilled(telegram_notifications.filled);
    setDealComplete(telegram_notifications.dealComplete);
  }, [telegram_notifications]);

  useEffect(() => {
    if (!uploading) {
      setUploading(true);

      if (
        telegram_notifications.filled !== filled ||
        telegram_notifications.dealComplete !== dealComplete
      ) {
        firebase
          .database()
          .ref(`/telegram_notifications`)
          .update({
            filled: filled,
            dealComplete: dealComplete,
          })
          .then((res) => {
            setUploading(false);
          })
          .catch((err) => {
            setUploading(false);
          });
      } else {
        setUploading(false);
      }
    }
  }, [filled, dealComplete]);

  const handleChangePassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        console.log("recover email sent");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
      });
    setResetPasswordLinkSent(true);
  };

  const synMarketData = async () => {
    setMarketDataSyn(true);
    var updateMarketData = firebase
      .functions()
      .httpsCallable("updateMarketData");

    try {
      var res = await updateMarketData();

      if (res.data.error) {
        alert(res.data.error);
        setMarketDataSyn(false);
        return;
      }
      alert(
        "All Market Data Successfully Syn. Please reload Web App to load new Market Data."
      );
      setMarketDataSyn(false);
      document.location.reload(true);
    } catch (error) {
      alert(error.response ? error.response.data : error);
      setMarketDataSyn(false);
      return;
    }
  };

  const generateCSVMMBots = () => {
    var JsonFields = [
      "type", // read only
      "market", // read only
      "id", // read only
      "name", // read only
      "secondEx", // read only
      "isSyntheticToken", // read only
      "minimumOrderSize",
      "maximumOrderSize",
      "baseAssetsTotal",
      "quoteAssetsTotal",
      "maxOrders",
      "minSpread",
      "orderPriceShapeFactor",
      "orderAmountShapeFactor",
      "orderInterval",
      "isTickerBotActivate",
      "tickerOrderSizeMax",
    ];

    var csvStr = JsonFields.join(",") + "\n";

    for (const key in props.allBots) {
      const bot = props.allBots[key];
      if (bot.type === "MARKET-MAKER") {
        csvStr +=
          bot.type +
          "," +
          bot.market.toUpperCase() +
          "," +
          key +
          "," +
          (bot.name && bot.name.length > 0 ? bot.name : bot.displayId) +
          "," +
          bot.secondEx +
          "," +
          bot.isSyntheticToken +
          "," +
          bot.minimumOrderSize +
          "," +
          bot.maximumOrderSize +
          "," +
          bot.baseAssetsTotal +
          "," +
          bot.quoteAssetsTotal +
          "," +
          bot.maxOrders +
          "," +
          bot.minSpread +
          "," +
          bot.orderPriceShapeFactor +
          "," +
          bot.orderAmountShapeFactor +
          "," +
          bot.orderInterval +
          "," +
          bot.isTickerBotActivate +
          "," +
          bot.tickerOrderSizeMax +
          "\n";
      }
    }

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = "market_maker_bot_settings.csv";
    hiddenElement.click();

    setMMbotsCSVDownloded(true);
  };

  // const generateCSVHedgeBots = () => {
  //   var JsonFields = [
  //     "type", // read only
  //     "market", // read only
  //     "id", // read only
  //     "name", // read only
  //     "secondEx", // read only
  //     "isSyntheticToken", // read only
  //     "minimumOrderSize",
  //     "maximumOrderSize",
  //     "baseAssetsTotal",
  //     "quoteAssetsTotal",
  //     "maxOrders",
  //     "minSpread",
  //     "orderPriceShapeFactor",
  //     "orderAmountShapeFactor",
  //     "orderInterval",
  //     "isTickerBotActivate",
  //     "tickerOrderSizeMax",
  //   ];

  //   var csvStr = JsonFields.join(",") + "\n";

  //   for (const key in props.allBots) {
  //     const bot = props.allBots[key];
  //     if (bot.type === "HEDGE") {
  //       csvStr +=
  //         bot.type +
  //         "," +
  //         bot.market.toUpperCase() +
  //         "," +
  //         bot.id +
  //         "," +
  //         (bot.name && bot.name.length > 0 ? bot.name : bot.displayId) +
  //         "," +
  //         bot.secondEx +
  //         "," +
  //         bot.isSyntheticToken +
  //         "," +
  //         bot.minimumOrderSize +
  //         "," +
  //         bot.maximumOrderSize +
  //         "," +
  //         bot.baseAssetsTotal +
  //         "," +
  //         bot.quoteAssetsTotal +
  //         "," +
  //         bot.maxOrders +
  //         "," +
  //         bot.minSpread +
  //         "," +
  //         bot.orderPriceShapeFactor +
  //         "," +
  //         bot.orderAmountShapeFactor +
  //         "," +
  //         bot.orderInterval +
  //         "," +
  //         bot.isTickerBotActivate +
  //         "," +
  //         bot.tickerOrderSizeMax +
  //         "\n";
  //     }
  //   }

  //   var hiddenElement = document.createElement("a");
  //   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
  //   hiddenElement.target = "_blank";
  //   hiddenElement.download = "hedge_bot_settings.csv";
  //   hiddenElement.click();

  //   sethedgebotsCSVDownloded(true);
  // };
  // const generateCSVTickerBots = () => {
  //   var JsonFields = [
  //     "type", // read only
  //     "market", // read only
  //     "id", // read only
  //     "name", // read only
  //     "secondEx", // read only
  //     "isSyntheticToken", // read only
  //     "minimumOrderSize",
  //     "maximumOrderSize",
  //     "baseAssetsTotal",
  //     "quoteAssetsTotal",
  //     "maxOrders",
  //     "minSpread",
  //     "orderPriceShapeFactor",
  //     "orderAmountShapeFactor",
  //     "orderInterval",
  //     "isTickerBotActivate",
  //     "tickerOrderSizeMax",
  //   ];

  //   var csvStr = JsonFields.join(",") + "\n";

  //   for (const key in props.allBots) {
  //     const bot = props.allBots[key];
  //     if (bot.type === "TICKER") {
  //       csvStr +=
  //         bot.type +
  //         "," +
  //         bot.market.toUpperCase() +
  //         "," +
  //         bot.id +
  //         "," +
  //         (bot.name && bot.name.length > 0 ? bot.name : bot.displayId) +
  //         "," +
  //         bot.secondEx +
  //         "," +
  //         bot.isSyntheticToken +
  //         "," +
  //         bot.minimumOrderSize +
  //         "," +
  //         bot.maximumOrderSize +
  //         "," +
  //         bot.baseAssetsTotal +
  //         "," +
  //         bot.quoteAssetsTotal +
  //         "," +
  //         bot.maxOrders +
  //         "," +
  //         bot.minSpread +
  //         "," +
  //         bot.orderPriceShapeFactor +
  //         "," +
  //         bot.orderAmountShapeFactor +
  //         "," +
  //         bot.orderInterval +
  //         "," +
  //         bot.isTickerBotActivate +
  //         "," +
  //         bot.tickerOrderSizeMax +
  //         "\n";
  //     }
  //   }

  //   var hiddenElement = document.createElement("a");
  //   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
  //   hiddenElement.target = "_blank";
  //   hiddenElement.download = "ticker_bot_settings.csv";
  //   hiddenElement.click();

  //   settickerbotsCSVDownloded(true);
  // };

  const handleFileChangeMarketMaker = (e) => {
    const file = e.target.files[0];

    if (file) {
      setMMBotsNewCSV([]);
      Papa.parse(file, {
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            setMMbotsCSVLoaded(1);
            setMMBotsNewCSV(result.data);
          } else {
            setMMbotsCSVLoaded(2);
          }
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    }
  };

  const submitNewMMbotsCSV = async () => {
    if (mmBotsNewCSV && mmBotsNewCSV.length > 0) {
      setMMbotsCSVLoaded(3);
      for (let index = 0; index < mmBotsNewCSV.length; index++) {
        const bot = mmBotsNewCSV[index];
        const botOld = props.allBots[bot.id];

        if (botOld) {
          if (
            parseFloat(bot.baseAssetsTotal) &&
            parseFloat(bot.quoteAssetsTotal) &&
            parseFloat(bot.orderInterval) &&
            parseFloat(bot.maxOrders) &&
            parseFloat(bot.orderAmountShapeFactor) &&
            parseFloat(bot.orderPriceShapeFactor) &&
            parseFloat(bot.maximumOrderSize) &&
            parseFloat(bot.minimumOrderSize) &&
            parseFloat(bot.minSpread) &&
            parseFloat(bot.tickerOrderSizeMax)
          ) {
            if (
              parseFloat(bot.baseAssetsTotal) !==
                parseFloat(botOld.baseAssetsTotal) ||
              parseFloat(bot.quoteAssetsTotal) !==
                parseFloat(botOld.quoteAssetsTotal) ||
              parseFloat(bot.orderInterval) !==
                parseFloat(botOld.orderInterval) ||
              parseFloat(bot.maxOrders) !== parseFloat(botOld.maxOrders) ||
              parseFloat(bot.orderAmountShapeFactor) !==
                parseFloat(botOld.orderAmountShapeFactor) ||
              parseFloat(bot.orderPriceShapeFactor) !==
                parseFloat(botOld.orderPriceShapeFactor) ||
              parseFloat(bot.maximumOrderSize) !==
                parseFloat(botOld.maximumOrderSize) ||
              parseFloat(bot.minimumOrderSize) !==
                parseFloat(botOld.minimumOrderSize) ||
              parseFloat(bot.minSpread) !== parseFloat(botOld.minSpread) ||
              parseFloat(bot.tickerOrderSizeMax) !==
                parseFloat(botOld.tickerOrderSizeMax)
            ) {
              let data = {
                ...botOld,
                baseAssetsTotal: parseFloat(bot.baseAssetsTotal),
                quoteAssetsTotal: parseFloat(bot.quoteAssetsTotal),
                orderInterval: parseFloat(bot.orderInterval),
                maxOrders: parseFloat(bot.maxOrders),
                orderAmountShapeFactor: parseFloat(bot.orderAmountShapeFactor),
                orderPriceShapeFactor: parseFloat(bot.orderPriceShapeFactor),
                maximumOrderSize: parseFloat(bot.maximumOrderSize),
                minimumOrderSize: parseFloat(bot.minimumOrderSize),
                minSpread: parseFloat(bot.minSpread),
                isTickerBotActivate: bot.isTickerBotActivate === true,
                tickerOrderSizeMax: parseFloat(bot.tickerOrderSizeMax),
              };

              console.log(bot.id, data);
              await firebase
                .firestore()
                .collection("bots")
                .doc(bot.id)
                .update(data)
                .catch((err) => {
                  console.log(err);
                });
            } else {
              console.log("Settings Not Changed!", bot.market, bot.id);
            }
          } else {
            console.log("Incorrect settings !!!", bot);
          }
        } else {
          console.log("Bot not found:", bot);
        }
      }

      setMMbotsCSVLoaded(4);
    }
  };

  return (
    <div className="dark-1 container-fluid">
      <div className="row">
        <div className="col-12 h4 ">
          <FontAwesomeIcon icon={faCog} size="1x" color="#d9d9d9" />
          <span className="pl-2">Settings</span>
        </div>
      </div>
      {isMarketDataSyn && <LoadingScreen />}
      <div className="row dark-2 rounded-lg py-3 px-2 my-3 mx-2">
        <div className="col-12">
          <div className="row">
            <div className="col-12 h5">Exchange Settings</div>
          </div>
          <div className="row dark-3 rounded-lg pt-3 pb-2 my-3 mx-2 verticalCenter">
            <div
              className="col-8 col-sm-3 offset-md-1 h6  verticalCenter "
              style={{ color: "gray" }}
            >
              Syn Market Data
            </div>
            <div className="col-1">-</div>
            <div className="col-12 col-sm-7 h6  verticalCenter  ">
              {" "}
              <button
                onClick={() => synMarketData()}
                style={{ background: "none", border: "none" }}
              >
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  style={{ fontSize: "1rem" }}
                  color="#d9d9d9"
                  className={isMarketDataSyn ? "fa-spin" : ""}
                />
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="row dark-2 rounded-lg py-3 px-2 my-3 mx-2">
        <div className="col-12">
          <div className="row">
            <div className="col-12 h5">Account Settings</div>
          </div>
          <div className="row dark-3 rounded-lg pt-3 pb-2 my-3 mx-2 verticalCenter">
            <div
              className="col-8 col-sm-3 offset-md-1 h6  verticalCenter "
              style={{ color: "gray" }}
            >
              Email
            </div>
            <div className="col-1">-</div>
            <div className="col-12 col-sm-7 h6  verticalCenter  ">{email} </div>
          </div>

          <div className="row dark-3 rounded-lg pt-3 pb-2 my-3 mx-2 verticalCenter">
            <div
              className="col-8 col-sm-3 offset-md-1  h6  verticalCenter  "
              style={{ color: "gray" }}
            >
              Change Password
            </div>{" "}
            <div className="col-1">-</div>
            <div className="col-11 offset-1 offset-sm-0 col-sm-6 h6  verticalCenter  ">
              {" "}
              {isResetPasswordLinkSent ? (
                <span className="text-success">
                  Email sent. Please check your inbox.
                </span>
              ) : (
                <button
                  className="btn btn-sm btn-dark "
                  onClick={() => handleChangePassword()}
                >
                  Send Reset Link
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row dark-2 rounded-lg py-3 px-2 my-3 mx-2">
        <div className="col-12">
          <div className="row">
            <div className="col-12 h5">Bot Settings</div>
          </div>
          <div className="row dark-3 rounded-lg pt-3 pb-2 my-3 mx-2 verticalCenter">
            <div
              className="col-8 col-sm-3 offset-md-1 h6  verticalCenter "
              style={{ color: "gray" }}
            >
              Change Market Maker Bot Settings
            </div>
            <div className="col-1">-</div>
            <div className="col-2  offset-sm-0 h6  verticalCenter  ">
              {" "}
              {isMMBotsCSVDownloaded ? (
                <span className="text-success">
                  Successful! Please update the CSV file and submit.
                </span>
              ) : (
                <button
                  className="btn btn-sm btn-dark "
                  onClick={() => generateCSVMMBots()}
                >
                  Download CSV
                </button>
              )}
            </div>{" "}
            <div className="col-5 offset-1 offset-sm-0 h6  verticalCenter  ">
              <label htmlFor="mmcsv" className="pr-2">
                Load Updated CSV :
              </label>
              <input
                type="file"
                id="mmcsv"
                name="mmcsv"
                onChange={handleFileChangeMarketMaker}
              />
              {isMMBotsCSVLoaded === 1 ? (
                <button
                  className="btn btn-sm btn-success "
                  onClick={() => submitNewMMbotsCSV()}
                  disabled={!(mmBotsNewCSV && mmBotsNewCSV.length > 0)}
                >
                  Submit
                </button>
              ) : isMMBotsCSVLoaded === 2 ? (
                <span className="text-danger pl-2">
                  Error!!! <br />
                  Incorrect file format.
                </span>
              ) : isMMBotsCSVLoaded === 3 ? (
                <span className="text-warning pl-2">
                  Please wait! Bot Settings Updating...
                </span>
              ) : isMMBotsCSVLoaded === 4 ? (
                <span className="text-success pl-2">
                  All Bots Successfully Updated!
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* <div className="row dark-3 rounded-lg pt-3 pb-2 my-3 mx-2 verticalCenter">
            <div
              className="col-5 col-sm-3 offset-md-1  h6  verticalCenter  "
              style={{ color: "gray" }}
            >
              Change Ticker Bot Settings
            </div>{" "}
            <div className="col-1">-</div>
            <div className="col-2 offset-1 offset-sm-0 h6  verticalCenter  ">
              {" "}
              {istickerBotsCSVDownloaded ? (
                <span className="text-success">
                  Email sent. Please check your inbox.
                </span>
              ) : (
                <button
                  className="btn btn-sm btn-dark "
                  onClick={() => generateCSVTickerBots()}
                >
                  Download CSV
                </button>
              )}
            </div>{" "}
            <div className="col-2 offset-1 offset-sm-0 h6  verticalCenter  ">
              <label htmlFor="hedgecsv" className="pr-2">
                Submit Updated CSV :
              </label>
              <input
                type="file"
                id="hedgecsv"
                name="hedgecsv"
                onChange={handleFileChangeMarketMaker}
              />
            </div>
          </div>
          <div className="row dark-3 rounded-lg pt-3 pb-2 my-3 mx-2 verticalCenter">
            <div
              className="col-5 col-sm-3 offset-md-1  h6  verticalCenter  "
              style={{ color: "gray" }}
            >
              Change Hedge Bot Settings
            </div>{" "}
            <div className="col-1">-</div>
            <div className="col-2 offset-1 offset-sm-0 h6  verticalCenter  ">
              {" "}
              {ishedgeBotsCSVDownloaded ? (
                <span className="text-success">
                  Email sent. Please check your inbox.
                </span>
              ) : (
                <button
                  className="btn btn-sm btn-dark "
                  onClick={() => generateCSVHedgeBots()}
                >
                  Download CSV
                </button>
              )}
            </div>{" "}
            <div className="col-2 offset-1 offset-sm-0 h6  verticalCenter  ">
              <label htmlFor="tickercsv" className="pr-2">
                Submit Updated CSV :
              </label>
              <input
                type="file"
                id="tickercsv"
                name="tickercsv"
                onChange={handleFileChangeMarketMaker}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="row dark-2 rounded-lg py-3 px-2 my-3 mx-2">
        <div className="col-12">
          <div className="row">
            <div className="col-12 h5">Notification Settings</div>
          </div>
          <div className="row dark-3 rounded-lg  px-3 py-4 m-2">
            <div className="col-12 ">
              <FontAwesomeIcon
                icon={faTelegram}
                style={{
                  margin: "auto 10px auto 0",
                  fontSize: "1.3rem",
                }}
                color="#d9d9d9"
              />

              <span className="m-auto h5">Telegram Bot</span>
            </div>

            <div className="col-12 mt-2">
              {" "}
              <div className="row">
                <div className="col-sm-8 col-lg-6 col-12 py-2 pr-0 text-left m-auto">
                  <div className="row mt-2 ">
                    <div className="col-md-6 offset-lg-1 col-12">
                      {" "}
                      <div className="form-check pb-1">
                        <input
                          disabled={uploading}
                          type="checkbox"
                          className="form-check-input"
                          id="Filled"
                          checked={filled}
                          onChange={(e) => {
                            setFilled(e.target.checked);
                          }}
                        />
                        <label className="form-check-label" htmlFor="Filled">
                          Filled Orders
                        </label>
                      </div>
                      {/* <div className="form-check pb-1">
                        <input
                          disabled={uploading}
                          type="checkbox"
                          className="form-check-input"
                          id="DealComplete"
                          checked={dealComplete}
                          onChange={(e) => {
                            setDealComplete(e.target.checked);
                          }}
                        />

                        <label
                          className="form-check-label"
                          htmlFor="DealComplete"
                        >
                          Deal Complete
                        </label>
                      </div>{" "} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  allBots: state.bots.allBots,
});

export default connect(mapStateToProps)(Settings);
