import { SET_ACTIVE_DEALS } from "../actions/types";

const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_DEALS:
      return {
        ...state,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
