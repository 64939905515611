import axios from "axios";

var baseURL = "https://bot-api.bexc.io";
var wsBaseURL = "wss://bot-api.bexc.io/streams";

const Bytedex = {
  init: function (config) {
    this.ws = {};
    this.lastOrderbookWS = null;
    Object.assign(this);
  },

  formatQuery: function (queryObj) {
    if (JSON.stringify(queryObj).length !== 2) {
      const params = new URLSearchParams(queryObj);

      return "?" + params.toString();
    } else {
      return "";
    }
  },
  /*  
  Get Ticker
  symbol = string
*/
  getTicker: async function (symbol) {
    let endpoint = `/market/markets/${symbol}`;
    let url = baseURL + endpoint;
    let result = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return result.data[symbol];
  },

  /* 
Get All Tickers
*/
  getAllTickers: async function () {
    let endpoint = "/market/markets";
    let url = baseURL + endpoint;
    let result = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    return result.data;
  },

  // ws
  initSocket: async function (params, eventHandler) {
    try {
      let [topic, endpoint, type] = this.topics(
        params.topic,
        params.symbol,
        params.interval
      );
      if (!this.ws[endpoint]) {
        let ws = new WebSocket(wsBaseURL);

        this.ws[endpoint] = ws;
        if (params.topic === "orderbooks" && this.lastOrderbookWS !== null) {
          this.lastOrderbookWS.close();
          this.lastOrderbookWS = ws;
        }
        ws.onopen = (event) => {
          console.log(topic + " opening websocket connection... ");

          ws.send(
            JSON.stringify({
              method: "subscribe",
              channels: [endpoint],
            })
          );
        };
        ws.onmessage = eventHandler;

        ws.onerror = (error) => {
          console.log(error);
        };

        ws.onclose = () => {
          console.log(topic + " websocket closed...");
          this.ws[endpoint] = null;
        };
      }
    } catch (err) {
      console.log(err);
    }
  },
  closeSocket: async function (params) {
    try {
      let [topic, endpoint] = this.topics(
        params.topic,
        params.symbol,
        params.interval
      );

      this.ws[endpoint].close();
    } catch (err) {
      console.log(err);
    }
  },

  topics: function (topic, symbol, interval) {
    if (topic === "allTicker") {
      return [topic, "tickers", "public"];
    } else if (topic === "orderbooks") {
      if (symbol) {
        return [topic, "books." + symbol, "public"];
      } else {
        throw "symbol required";
      }
    } else if (topic === "trades") {
      if (symbol) {
        return [topic, "trades." + symbol, "public"];
      } else {
        throw "symbol required";
      }
    } else if (topic === "charts") {
      if (symbol && interval) {
        return [topic, "kline." + symbol + "." + interval, "public"];
      } else {
        throw "symbol & interval required";
      }
    }
  },
};

export default Bytedex;
