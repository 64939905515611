import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer
        className="page-footer font-small mt-3 pt-2 bottom-0"
        style={{
          backgroundColor: "black",
          // paddingLeft: window.innerWidth < 600 ? 0 : "270px",
        }}
      >
        <div className="container-fluid text-left">
          <div className="row text-left mt-1">
            <div className="col-12 col-sm-6 mx-auto my-2">
              <div className="mx-1 font-smaller">
                <small>
                  This website allows you to trade and manage cryptocurrency
                  holdings by using an automated crypto trader bot that you
                  solely control. This platform is a product of Coinner, it is
                  prohibited to copy any content on this platform and share.
                </small>
              </div>
            </div>

            <div className="col-12 col-sm-6 mx-auto my-3 text-center">
              <div className="row">
                <div className="col-4 center">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="mailto:support@coinner.co"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="row d-flex py-auto">
            <div className="col-12  copyright my-auto">
              <div className="row no-gutters ">
                <div className="col-sm-6 col-12 text-center">
                  © 2023
                  <strong> Coinner.co</strong>
                  <small className="pl-2">All Rights Reserved</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
