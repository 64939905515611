import React, { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import Chart from "chart.js";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import "chartjs-plugin-zoom";
import Bytedex from "../backend/bytedex";

Chart.plugins.register([ChartAnnotation]); // Global

//
function OrdersChart(props) {
  const [minOrderPrice, setminOrderPrice] = useState(0);
  const [maxOrderPrice, setmaxOrderPrice] = useState(0);

  const [ticker, setTicker] = useState(null);

  const chartData = {
    labels: [],
    datasets: [
      {
        label: "OrderBook",
        backgroundColor: "rgba(102, 179, 255,0.2)",
        borderColor: "black",
        data: [],
      },
    ],
  };
  const [annotationsData, setAnnotationsData] = useState([]);

  useEffect(() => {
    if (props.marketData) {
      Bytedex.initSocket(
        { topic: "orderbooks", symbol: props.marketData.symbol.toUpperCase() },
        (msg) => {
          const json = JSON.parse(msg.data);
          try {
            if (
              json.data &&
              json.data.length > 0 &&
              json.data[0].length > 0 &&
              json.data[1].length > 0
            ) {
              var price = json.data[1][0][0];
              setTicker({
                bestAskPrice: price,
                bestBidPrice: json.data[0][0][0],
              });
              console.log({
                bestAskPrice: price,
                bestBidPrice: json.data[0][0][0],
              });
            }
          } catch (err) {
            console.log(err);
          }
        }
      );

      return () => {
        Bytedex.closeSocket({
          topic: "orderbooks",
          symbol: props.marketData.symbol.toUpperCase(),
        });
      };
    }
  }, [props.marketData, props.bot]);

  useEffect(() => {
    var annotations = [];

    var minP = 0;
    var maxP = 0;
    if (props.marketData && ticker) {
      annotations.push({
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: ticker.bestAskPrice,
        borderColor: "black",
        borderWidth: 1,
        label: {
          backgroundColor: "black",
          content:
            "Best Ask - " +
            ticker.bestAskPrice +
            " " +
            props.marketData.quoteAsset.toUpperCase(),
          enabled: true,
          fontColor: "white",
        },
      });
      if (minP > ticker.bestAskPrice || minP === 0) {
        minP = ticker.bestAskPrice - props.marketData.step * 10;
      }
      if (ticker.bestAskPrice > maxP || maxP === 0) {
        maxP = ticker.bestAskPrice + props.marketData.step * 10;
      }

      annotations.push({
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: ticker.bestBidPrice,
        borderColor: "black",
        borderWidth: 1,
        label: {
          backgroundColor: "black",
          content:
            "Best Bid - " +
            ticker.bestBidPrice +
            " " +
            props.marketData.quoteAsset.toUpperCase(),
          enabled: true,
          fontColor: "white",
        },
      });
      if (minP > ticker.bestBidPrice || minP === 0) {
        minP = ticker.bestBidPrice - props.marketData.step * 10;
      }
      if (ticker.bestBidPrice > maxP || maxP === 0) {
        maxP = ticker.bestBidPrice + props.marketData.step * 10;
      }
    }

    setmaxOrderPrice(maxP);
    setminOrderPrice(minP);
    setAnnotationsData(annotations);
  }, [props.marketData, ticker]);

  return (
    <div
      className="h-100 dark-2 rounded-lg py-2"
      style={{ maxHeight: "37rem" }}
    >
      <Line
        data={chartData}
        options={{
          maintainAspectRatio: false,
          tooltips: {
            fontColor: "#d7d9da",
            callbacks: {
              label: function (tooltipItem, chart) {
                return tooltipItem.yLabel;
              },
            },
          },
          annotation: {
            annotations: annotationsData,
          },
          pan: {
            enabled: true,
            mode: "xy",
          },
          zoom: {
            enabled: true,
            mode: "xy", // or 'x' for "drag" version
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                  fontColor: "#d7d9da",
                  max: maxOrderPrice,
                  min: minOrderPrice,

                  callback: function (value, index, values) {
                    value = props.marketData
                      ? Math.round(
                          (value + Number.EPSILON) * props.marketData.tickDigits
                        ) / props.marketData.tickDigits
                      : value;

                    return (
                      value +
                      " " +
                      (props.marketData
                        ? props.marketData.quoteAsset.toUpperCase()
                        : "USDT")
                    );
                  },
                },

                gridLines: {
                  zeroLineColor: "black",
                  color: "black",
                  lineWidth: 1,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  display: false,
                },

                gridLines: {
                  zeroLineColor: "black",
                  color: "black",
                  lineWidth: 1,
                },
              },
            ],
          },
          legend: {
            display: true,
            labels: {
              boxWidth: 0,
              fontColor: "white",
              fontSize: 18,
            },
          },
        }}
      />
    </div>
  );
}

export default OrdersChart;
