import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { Context } from "../../backend/context";

import Select from "react-select";

import Binance from "binance-api-node";
const client = Binance();

function SyntheticTokenHandler(props) {
  const { marketDataAllList, marketDataAll, loadMarketData } =
    React.useContext(Context);
  const [sourceList, setSourceList] = useState([]);
  const [addNewSource, setAddNewSource] = useState("");
  const [addNewWeight, setAddNewWeight] = useState(1);
  const [addNewMarket, setAddNewMarket] = useState("");
  const [addNewMarketList, setAddNewMarketList] = useState(false);

  function handleSetSourceMarket(val) {
    if (val && val.value) {
      setAddNewMarket(val.value);
    }
  }

  async function handleNewEntry(e) {
    e.preventDefault();
    if (
      addNewSource.length > 0 &&
      addNewMarket.length > 0 &&
      addNewWeight > 0
    ) {
      var getTicker = firebase.functions().httpsCallable("getTicker");

      var price = 0;

      console.log(addNewMarket.replace("_", "/").toUpperCase(), addNewSource);
      try {
        if (addNewSource === "binance") {
          var priceData = await client.prices({
            symbol: addNewMarket.replace("_", "").toUpperCase(),
          });
          if (priceData[addNewMarket.replace("_", "").toUpperCase()]) {
            price = parseFloat(
              priceData[addNewMarket.replace("_", "").toUpperCase()]
            );
          }
        } else {
          var priceData = await getTicker({
            exchange: addNewSource,
            market: addNewMarket.replace("_", "/").toUpperCase(),
          });

          console.log(priceData);

          if (priceData && priceData.data && priceData.data.last) {
            console.log(priceData.data.last, priceData.data.bid);
            price = priceData.data.last;
          }
        }
      } catch (error) {
        console.log(error);
      }

      var entry = {
        exchange: addNewSource,
        market: addNewMarket,
        weight: parseFloat(addNewWeight),
        price: price,
      };

      var lisst = sourceList.concat([entry]);

      setSourceList(lisst);
    }
  }

  useEffect(() => {
    var priceTotal = 0;
    var newSourceList = [];
    for (let index = 0; index < sourceList.length; index++) {
      const element = sourceList[index];
      priceTotal += element.price * element.weight;

      newSourceList.push({
        exchange: element.exchange,
        market: element.market,
        weight: element.weight,
      });
    }

    var newPrice =
      Math.floor((priceTotal + Number.EPSILON) * props.marketData.tickDigits) /
      props.marketData.tickDigits;

    props.setCurrencyPrice(newPrice);
    props.setSourceList(newSourceList);
  }, [sourceList, props.marketData]);

  //handle source
  useEffect(() => {
    if (marketDataAllList && marketDataAllList[addNewSource]) {
      var lisst = [];
      for (
        let index = 0;
        index < marketDataAllList[addNewSource].length;
        index++
      ) {
        const element = marketDataAllList[addNewSource][index];

        lisst.push({
          value: element.symbol,
          label: element.symbol.replace("_", "/").toUpperCase(),
        });
      }

      setAddNewMarketList(lisst);
    } else if (marketDataAllList && addNewSource !== "no") {
      loadMarketData(addNewSource);
    }
  }, [marketDataAllList[addNewSource], addNewSource, marketDataAll]);

  return (
    <div className="container-fluid dark-1 my-3 mx-0">
      <div
        // style={{ minWidth: "65rem" }}
        className="row  text-center  overflow-hidden py-1 dark-3 border-bottom border-dark verticalCenter mt-1"
      >
        <div
          className="col-3 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
          style={{ fontSize: "0.7rem", minHeight: "1rem" }}
        >
          Source Exchange
        </div>
        <div
          className="col-3 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
          style={{ fontSize: "0.7rem", minHeight: "1rem" }}
        >
          Source Market
        </div>
        <div
          className="col-3 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
          style={{ fontSize: "0.7rem", minHeight: "1rem" }}
        >
          Weight
        </div>

        <div
          className="col-3 overflow-hidden verticalCenter text-center justify-content-center border-dark"
          style={{ fontSize: "0.7rem", minHeight: "1rem" }}
        >
          Price
        </div>
      </div>
      {sourceList.map((item, index) => {
        return (
          <div
            key={index}
            // style={{ minWidth: "65rem" }}
            className="row  border-dark border-left border-bottom text-center bot-details-row"
          >
            <div
              style={{ fontSize: "0.7rem" }}
              className="col-3 center border-right border-dark py-2 px-0 overflow-hidden"
            >
              {item.exchange}
            </div>
            <div
              style={{ fontSize: "0.7rem" }}
              className="col-3 center border-right border-dark py-2 overflow-hidden"
            >
              {item.market.toUpperCase()}
            </div>
            <div
              style={{ fontSize: "0.7rem" }}
              className="col-3 center border-right border-dark py-2 overflow-hidden"
            >
              {item.weight}
            </div>
            <div
              style={{ fontSize: "0.7rem" }}
              className="col-3 center border-right border-dark py-2 overflow-hidden"
            >
              {item.price}
            </div>
          </div>
        );
      })}

      <div
        // style={{ minWidth: "65rem" }}
        className="row  border-dark border-left border-bottom text-center bot-details-row"
      >
        <div
          style={{ fontSize: "0.7rem" }}
          className="col-3  border-right border-dark p-2 overflow-hidden"
        >
          <select
            required
            onChange={(e) => {
              setAddNewSource(e.target.value);
            }}
            className="custom-select mr-sm-2 form-control"
            value={addNewSource}
          >
            <option value=""></option> <option value="kucoin">Kucoin</option>
            <option value="gateio">Gateio</option>
            <option value="binance">Binance</option>
            <option value="mexc">Mexc</option>
            <option value="huobi">Huobi</option>
            <option value="okx">OKX</option>
          </select>
        </div>
        <div
          style={{ fontSize: "0.7rem" }}
          className="col-3  border-right border-dark p-2 "
        >
          <Select
            required
            onChange={(e) => {
              handleSetSourceMarket(e);
            }}
            defaultValue={addNewMarket}
            isClearable={true}
            isSearchable={true}
            name="marketsNew"
            options={addNewMarketList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "#1a75ff",
                primary50: "#1a75ff",
                neutral0: "black",
                neutral5: "gray",
                neutral10: "gray",
                neutral20: "gray",
                neutral50: "rgb(158, 158, 158)",
                neutral80: "rgb(158, 158, 158)",
                neutral90: "white",
              },
            })}
          />
        </div>
        <div
          style={{ fontSize: "0.7rem" }}
          className="col-3 center border-right border-dark py-2 overflow-hidden"
        >
          <input
            onChange={(e) => setAddNewWeight(e.target.value)}
            type="number"
            pattern="[0-9]*"
            inputMode="decimal"
            className="form-control"
            required
            value={addNewWeight}
            style={{
              fontSize: "0.8rem",
            }}
          />
        </div>{" "}
        <div
          style={{ fontSize: "0.7rem" }}
          className="col-3 center border-right border-dark py-2 overflow-hidden"
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={(e) => handleNewEntry(e)}
            disabled={
              !(
                addNewSource.length > 0 &&
                addNewMarket.length > 0 &&
                addNewWeight > 0
              )
            }
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

export default SyntheticTokenHandler;
