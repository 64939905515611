import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { Context } from "../../backend/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import crypto from "crypto";
import LoadingScreen from "../loadingScreen";

function Exchanges() {
  const { exchanges } = React.useContext(Context);
  const [isConnectExchange, setConnectExchange] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [password, setPassword] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [apiName, setApiName] = useState("");
  const [userID, setUserID] = useState("");
  const [exchange, setExchange] = useState("bytedex");
  const [isLoading, setLoading] = useState(false);

  const publicKey =
    "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwsBb02fxekR6CgfHB8Iu\nO8JGLpqdjP+m2cKdoBag0Hn8hBbVtRotYDS+PjTBx/5IhFirEJ3fRtQuXi9CV/CS\n0oRuA03IOtisIU8rCj7i+tDBZd+apjJjMhyEiPjTPd6HM7cd43IYB3GeYbZdDUTW\n8+Gqv0WKNhyHLUD1xQpEMsoP4ND25du08xXQOyUCK2nCrr5raHs6A86atogDXpsN\nnVbIs+LccFaLux+k4u64FMfBfzX0KIsuevXSnn43S7dPhqHdca+o2CL6Qb5ejUUs\nzPv/4upjW/tAlSSF9l7NpcLYlaoGoQlzTp3QvpmkNQqhyxX7DTqA6Ucj6Wg42qoA\nZQIDAQAB\n-----END PUBLIC KEY-----";

  const addNewExchange = async (e) => {
    e.preventDefault();
    setLoading(true);

    var accountID = `${exchange}-${Date.now()}`;
    const secretKey = crypto.randomBytes(16).toString("hex");
    const obj = encryptWithSecret(apiSecret, secretKey);
    const secret = encryptWithRSA(secretKey, publicKey);

    var data = {
      name: apiName,
      exchange: exchange,
      api_key: apiKey,
      password: password,
      userID: userID,
      api_secret: { api_key_data: obj, secret_key: secret },
      accountID: accountID,
    };

    var addExchange = firebase.functions().httpsCallable("addExchange");

    try {
      var res = await addExchange(data);

      if (res.data.error) {
        alert(res.data.error);
        setLoading(false);
        return;
      }
    } catch (error) {
      alert(error.response ? error.response.data : error);
      setLoading(false);
      return;
    }

    setConnectExchange(false);
    setLoading(false);
  };
  const encryptWithSecret = (text, secretKey) => {
    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv("aes-256-ctr", secretKey, iv);

    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

    return {
      iv: iv.toString("hex"),
      content: encrypted.toString("hex"),
    };
  };
  const encryptWithRSA = (msg, publicKey) => {
    var buffer = Buffer.from(msg);
    var encrypted = crypto.publicEncrypt(publicKey, buffer);
    return encrypted.toString("hex");
  };
  const deleteExchange = async (ele) => {
    if (window.confirm(`Remove Exchange ${ele.name} ?`)) {
      try {
        var deleteExchange = firebase
          .functions()
          .httpsCallable("deleteExchange");

        await deleteExchange(ele);
      } catch (error) {
        console.log(error);
      }
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="dark-1 container-fluid">
      {isLoading && <LoadingScreen />}

      <div className="row">
        <div className="col-12 h4 ">
          <FontAwesomeIcon icon={faExchangeAlt} size="1x" color="#d9d9d9" />
          <span className="pl-2">My Accounts</span>
        </div>
      </div>
      <div className="row dark-2 rounded-lg py-3 my-3 mx-2">
        <div className="col-12">
          {isConnectExchange ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 h5">Connect an exchange</div>
              </div>

              <div className="row">
                <div className="col-12 px-4">
                  <form onSubmit={addNewExchange}>
                    <div className="row rounded-lg p-1 dark-2 ">
                      <div className="col-12 col-md-12 pt-3 text-left m-auto">
                        <div className="form-group row mb-1">
                          <label className="col-12 pt-2 col-form-label mb-2 pl-5">
                            Select Exchange :
                          </label>
                          <div className="col-12 pb-2 col-lg-3 col-md-4 col-sm-6 text-center">
                            <div
                              onClick={(e) => {
                                setExchange("bytedex");
                              }}
                              className={`pointer form-check form-check-inline mx-auto border border-dark rounded-lg py-1 px-2 ${
                                exchange === "bytedex" ? "dark-3" : ""
                              }`}
                            >
                              <input
                                className="form-check-input pointer"
                                type="radio"
                                name="exchange"
                                id="exchangeName1"
                                value="option2"
                                checked={exchange === "bytedex"}
                              />
                              <label
                                className="form-check-label pointer"
                                htmlFor="exchangeName1"
                                style={{ fontSize: "1rem" }}
                              >
                                Byte Exchange
                                <img
                                  src="../../img/bytedex.png"
                                  className="pl-1"
                                  alt="bytedex"
                                  style={{ height: "1.3rem" }}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="col-12 pb-2 col-lg-3 col-md-4 col-sm-6 text-center">
                            <div
                              onClick={(e) => {
                                setExchange("binance");
                              }}
                              className={`pointer form-check form-check-inline mx-auto border border-dark rounded-lg py-1 px-2 ${
                                exchange === "binance" ? "dark-3" : ""
                              }`}
                            >
                              <input
                                className="form-check-input pointer"
                                type="radio"
                                name="exchange"
                                id="exchangeName3"
                                value="option1"
                                checked={exchange === "binance"}
                              />
                              <label
                                className="form-check-label pointer"
                                htmlFor="exchangeName3"
                                style={{ fontSize: "1rem" }}
                              >
                                Binance
                                <img
                                  src="../../img/binance.png"
                                  className="pl-1"
                                  alt="binance"
                                  style={{ height: "1.3rem" }}
                                />
                              </label>
                            </div>
                          </div>{" "}
                          <div className="col-12 pb-2 col-lg-3 col-md-4 col-sm-6 text-center">
                            <div
                              onClick={(e) => {
                                setExchange("kucoin");
                              }}
                              className={`pointer form-check form-check-inline mx-auto border border-dark rounded-lg py-1 px-2 ${
                                exchange === "kucoin" ? "dark-3" : ""
                              }`}
                            >
                              <input
                                className="form-check-input pointer"
                                type="radio"
                                name="exchange"
                                id="exchangeName4"
                                value="option1"
                                checked={exchange === "kucoin"}
                              />
                              <label
                                className="form-check-label pointer"
                                htmlFor="exchangeName4"
                                style={{ fontSize: "1rem" }}
                              >
                                Kucoin
                                <img
                                  src="../../img/kucoin.png"
                                  className="pl-1"
                                  alt="kucoin"
                                  style={{ height: "1.3rem" }}
                                />
                              </label>
                            </div>
                          </div>{" "}
                          <div className="col-12 pb-2 col-lg-3 col-md-4 col-sm-6 text-center">
                            <div
                              onClick={(e) => {
                                setExchange("gateio");
                              }}
                              className={`pointer form-check form-check-inline mx-auto border border-dark rounded-lg py-1 px-2 ${
                                exchange === "gateio" ? "dark-3" : ""
                              }`}
                            >
                              <input
                                className="form-check-input pointer"
                                type="radio"
                                name="exchange"
                                id="exchangeName5"
                                value="option1"
                                checked={exchange === "gateio"}
                              />
                              <label
                                className="form-check-label pointer"
                                htmlFor="exchangeName5"
                                style={{ fontSize: "1rem" }}
                              >
                                Gate.io
                                <img
                                  src="../../img/gateio.png"
                                  className="pl-1"
                                  alt="gateio"
                                  style={{ height: "1.3rem" }}
                                />
                              </label>
                            </div>
                          </div>{" "}
                          <div className="col-12 pb-2 col-lg-3 col-md-4 col-sm-6 text-center">
                            <div
                              onClick={(e) => {
                                setExchange("okx");
                              }}
                              className={`pointer form-check form-check-inline mx-auto border border-dark rounded-lg py-1 px-2 ${
                                exchange === "okx" ? "dark-3" : ""
                              }`}
                            >
                              <input
                                className="form-check-input pointer"
                                type="radio"
                                name="exchange"
                                id="exchangeName6"
                                value="option1"
                                checked={exchange === "okx"}
                              />
                              <label
                                className="form-check-label pointer"
                                htmlFor="exchangeName6"
                                style={{ fontSize: "1rem" }}
                              >
                                OKX
                                <img
                                  src="../../img/okx.png"
                                  className="pl-1"
                                  alt="okx"
                                  style={{ height: "1.3rem" }}
                                />
                              </label>
                            </div>
                          </div>{" "}
                          <div className="col-12 pb-2 col-lg-3 col-md-4 col-sm-6 text-center">
                            <div
                              onClick={(e) => {
                                setExchange("mexc");
                              }}
                              className={`pointer form-check form-check-inline mx-auto border border-dark rounded-lg py-1 px-2 ${
                                exchange === "mexc" ? "dark-3" : ""
                              }`}
                            >
                              <input
                                className="form-check-input pointer"
                                type="radio"
                                name="exchange"
                                id="exchangeName7"
                                value="option1"
                                checked={exchange === "mexc"}
                              />
                              <label
                                className="form-check-label pointer"
                                htmlFor="exchangeName7"
                                style={{ fontSize: "1rem" }}
                              >
                                MEXC
                                <img
                                  src="../../img/mexc.png"
                                  className="pl-1"
                                  alt="mexc"
                                  style={{ height: "1.3rem" }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row py-1">
                          <label className="col-md-2 col-form-label pl-5 ">
                            Name :
                          </label>
                          <div className="col-md-4">
                            <input
                              onChange={(e) => setApiName(e.target.value)}
                              type="text"
                              className="form-control"
                              required
                              value={apiName}
                            />
                          </div>
                          {exchange === "kucoin" && (
                            <React.Fragment>
                              <label className="col-md-2 col-form-label pl-5 ">
                                Password :
                              </label>
                              <div className="col-md-4">
                                <input
                                  onChange={(e) => setPassword(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  required
                                  value={password}
                                />
                              </div>
                            </React.Fragment>
                          )}
                          {exchange === "bytedex" && (
                            <React.Fragment>
                              <label className="col-md-2 col-form-label pl-5 ">
                                User ID :
                              </label>
                              <div className="col-md-4">
                                <input
                                  onChange={(e) => setUserID(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  required
                                  value={userID}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </div>

                        <React.Fragment>
                          <div className="form-group row py-1">
                            <label className="col-md-2 col-form-label pl-5 ">
                              API Key :
                            </label>
                            <div className="col-md-4">
                              <input
                                onChange={(e) => setApiKey(e.target.value)}
                                type="text"
                                className="form-control"
                                required
                                value={apiKey}
                              />
                            </div>

                            <label className="col-md-2 col-form-label pl-5 ">
                              API Secret :
                            </label>
                            <div className="col-md-4">
                              <input
                                onChange={(e) => setApiSecret(e.target.value)}
                                type="text"
                                className="form-control"
                                required
                                value={apiSecret}
                              />
                            </div>
                          </div>{" "}
                        </React.Fragment>

                        <div className="form-group row py-1">
                          <div className="col-md-12 text-center">
                            <button type="submit" className="btn btn-dark px-5">
                              Connect
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="row my-3">
              <div className="col-11 col-md-10 m-auto">
                <button
                  className="btn btn-dark px-5 "
                  style={{
                    borderRadius: "30px",
                  }}
                  onClick={() => setConnectExchange(true)}
                >
                  Connect an exchange
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row dark-2 rounded-lg py-3 my-3 mx-2 px-3">
        <div
          className="col-12"
          style={{ overflowX: "auto", overflowY: "hidden", minHeight: "10rem" }}
        >
          <div className="row">
            <div className="col-12 h5 ">My Accounts</div>
          </div>
          <div
            className="row  text-center  overflow-hidden py-2 dark-3 border-bottom border-dark verticalCenter mt-2"
            style={{ minWidth: "700px" }}
          >
            <div
              className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
              style={{ fontSize: "0.9rem", minHeight: "2rem" }}
            >
              Name
            </div>{" "}
            <div
              className="col-2 overflow-hidden verticalCenter text-center justify-content-center "
              style={{ fontSize: "0.9rem", minHeight: "2rem" }}
            >
              User ID
            </div>
            <div
              className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
              style={{ fontSize: "0.9rem", minHeight: "2rem" }}
            >
              Exchange
            </div>
            <div
              className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
              style={{ fontSize: "0.9rem", minHeight: "2rem" }}
            >
              Created
            </div>
            <div
              className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
              style={{ fontSize: "0.9rem", minHeight: "2rem" }}
            >
              API Key
            </div>
            <div
              className="col-2 overflow-hidden border-left border-dark verticalCenter text-center justify-content-center"
              style={{ fontSize: "0.9rem", minHeight: "2rem" }}
            >
              Remove
            </div>
          </div>
          {exchanges.length > 0 ? (
            exchanges.map((element, key) => {
              return (
                <div
                  key={key}
                  className="row  border-dark border-left border-bottom text-center bot-details-row"
                  style={{ minWidth: "700px" }}
                >
                  <div className="col-2 center border-right border-dark py-2">
                    {element.name}
                  </div>
                  <div className="col-2 center border-right border-dark py-2">
                    {element.userID ? element.userID : "-"}
                  </div>
                  <div className="col-2 center border-right border-dark py-2">
                    {capitalizeFirstLetter(element.exchange)}
                  </div>
                  <div className="col-2 center border-right border-dark py-2">
                    {new Date(element.createdAt).toLocaleDateString()}
                  </div>
                  <div className="col-2 center border-right border-dark py-2 text-nowrap overflow-hidden">
                    {element.api_key.toString().slice(0, 8) + ".."}
                  </div>

                  <div className="col-2 center border-right border-dark py-2 ">
                    <button
                      style={{ background: "none", border: "none" }}
                      onClick={() => deleteExchange(element)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        size="1x"
                        color="#b30000"
                      />
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="row  border-dark border-left border-bottom text-center bot-details-row"
              style={{ minWidth: "650px" }}
            >
              <div className="col-12 center border-right border-dark py-2">
                No Data
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Exchanges;
