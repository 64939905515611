import React from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";

import { Context } from "../backend/context";

import Dashboard from "./dashboard/dashboard";
import Exchanges from "./exchanges/exchanges";
import TickerBotDetails from "./tickerBots/botDetails/botDetails";
import TickerBots from "./tickerBots/tickerBots";
import Settings from "./settings/settings";
import TopNotificationBar from "./topNotificationBar";
import MarketMakerBots from "./marketMakerBots/mmBots";
import MarketMakerBotDetails from "./marketMakerBots/botDetails/botDetails";
import HedgeBots from "./hedgeBots/hedgeBots";
import HedgeBotDetails from "./hedgeBots/botDetails/botDetails";

function Body() {
  const { screenWidth, isLoggedIn } = React.useContext(Context);

  return (
    <div
      className="dark-1 overflow-scroll "
      style={{
        top: 0,
        minHeight: "75vh",
        transition: "margin-left .5s",
        padding: "0.1rem",
        paddingLeft: screenWidth < 600 ? "0.8rem" : "170px",
        // height: "100%",
      }}
    >
      <TopNotificationBar />

      {!isLoggedIn ? <Redirect to="/" /> : null}
      <Switch>
        <Route
          exact
          path="/account/tickerBots/:id"
          component={TickerBotDetails}
        />
        <Route path="/account/tickerBots" component={TickerBots} />

        <Route
          exact
          path="/account/hedgeBots/:id"
          component={HedgeBotDetails}
        />

        <Route path="/account/hedgeBots" component={HedgeBots} />

        <Route
          exact
          path="/account/mmBots/:id"
          component={MarketMakerBotDetails}
        />

        <Route path="/account/mmBots" component={MarketMakerBots} />

        <Route path="/account/exchanges" component={Exchanges} />
        <Route path="/account/settings" component={Settings} />

        <Route path="/account" component={Dashboard} />
      </Switch>
    </div>
  );
}

export default Body;
