import React from "react";

import { Context } from "../backend/context";

function TopNotificationBar() {
  const { topNotification } = React.useContext(Context);
  return (
    <div className="container-fluid">
      {topNotification.isAvailable && (
        <div className="row">
          <div className="col-12">
            <div
              className={`alert text-center py-2 ${topNotification.type} `}
              style={{ backgroundColor: "rgb(255, 255, 0,0.05)" }}
            >
              {topNotification.msg}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TopNotificationBar;
