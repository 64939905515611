import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

import LoadingScreen from "../loadingScreen";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function HandleBots(props) {
  const [stoppedBotList, setstoppedBotList] = useState([]);
  const [activeBotList, setActiveBotList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (props.allBots) {
      var activeList = [];
      var stoppedList = [];
      for (const key in props.allBots) {
        const element = props.allBots[key];

        if (element.isRunning && !element.isPending) {
          activeList.push(element);
        } else if (!element.isPending) {
          stoppedList.push(element);
        }
      }
      setstoppedBotList(stoppedList);
      setActiveBotList(activeList);
    }

    console.log(props.allBots);
  }, [props.allBots]);

  const handleStartALL = async () => {
    if (window.confirm(`Start All Stopped Bots ?`)) {
      setLoading(true);
      var startBotList = firebase.functions().httpsCallable("startBotList");

      try {
        var res = await startBotList({ botList: stoppedBotList });

        if (res.data.error) {
          alert(res.data.error);
          setLoading(false);
          return;
        }
        setLoading(false);
      } catch (error) {
        alert(error.response ? error.response.data : error);
        setLoading(false);
        return;
      }
    }
  };

  const handleStopAll = () => {
    if (window.confirm(`Stop All Bots ?`)) {
      setLoading(true);
      activeBotList.forEach((element) => {
        firebase
          .firestore()
          .collection("bots")
          .doc(element.id)
          .update({ isRunning: false });
      });
      setLoading(false);
    }
  };

  const startStoppedBot = (item) => {
    if (
      window.confirm(
        `Start Bot ${
          item.name && item.name.length > 0 ? item.name : item.displayId
        } ?`
      )
    ) {
      let data = {
        ...item,
        startedAt: Date.now(),
        isRunning: false,
        isPending: true,
      };
      firebase
        .firestore()
        .collection("pendingBots")
        .doc(item.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });
      firebase
        .firestore()
        .collection("bots")
        .doc(item.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="container-fluid rounded-lg  border border-dark ">
      <div className="row px-3">
        <div className="col-12 center py-3">
          <button
            type="button"
            className="btn btn-danger w-50 px-3 mt-3"
            onClick={handleStopAll}
            disabled={activeBotList.length === 0}
          >
            Stop All
          </button>
        </div>
      </div>
      <div className="row px-3">
        <div className="col-12 p-3 ">
          <div className="row">
            <div className="col-6 text-left verticalCenter h5 px-0">
              Stopped Bots
            </div>
          </div>
          {isLoading && <LoadingScreen />}

          <div className="row">
            <div className="col-12" style={{ overflowX: "auto" }}>
              <div
                style={{ minWidth: "15rem", fontWeight: "bold" }}
                className="row  text-center  overflow-hidden py-2 dark-2 border-bottom border-dark verticalCenter mt-2"
              >
                <div className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark ">
                  Bot Type
                </div>
                <div className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark ">
                  Market
                </div>
                <div className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "></div>
              </div>
              {stoppedBotList.length > 0 ? (
                stoppedBotList.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      style={{ textDecoration: "none" }}
                      to={`/account/${
                        item.type === "TICKER"
                          ? "tickerBots"
                          : item.type === "MARKET-MAKER"
                          ? "mmBots"
                          : "hedgeBots"
                      }/${item.id}`}
                    >
                      <div
                        style={{ minWidth: "15rem" }}
                        className="row  border-dark border-left border-bottom text-center bot-details-row"
                      >
                        <div className="col-4 border-right border-dark py-2 px-0 overflow-hidden">
                          {item.type}
                        </div>
                        <div className="col-4 border-right border-dark py-2 overflow-hidden">
                          {item.market.toUpperCase()}
                        </div>
                        <div className="col-4 border-right border-dark py-2 overflow-hidden">
                          <button
                            style={{ fontSize: "0.7rem" }}
                            onClick={() => startStoppedBot(item)}
                            className="btn btn-sm btn-success"
                          >
                            Start
                          </button>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div
                  style={{ minWidth: "15rem" }}
                  className="row  border-dark border-left border-bottom text-center bot-details-row"
                >
                  <div className="col-12 border-right border-dark py-2">
                    All Bots Running
                  </div>
                </div>
              )}
            </div>
          </div>
          {stoppedBotList.length > 0 && (
            <div className="row">
              <div className="col-12 center  pt-3 ">
                <button
                  type="button"
                  className="btn btn-success mr-2 w-50"
                  onClick={handleStartALL}
                >
                  Start All Bots
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  allBots: state.bots.allBots,
});

export default connect(mapStateToProps)(HandleBots);
