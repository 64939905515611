import { SET_BOTS } from "./types";
export const setBotsData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BOTS,
      payload: {
        hedgeBots: data.hedgeBots,
        tickerBots: data.tickerBots,
        marketMakerBots: data.marketMakerBots,
        activeHedgeBots: data.activeHedgeBots,
        activeTickerBots: data.activeTickerBots,
        activeMarketMakerBots: data.activeMarketMakerBots,
        allBots: data.allBots,
      },
    });
  };
};
