import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { Redirect } from "react-router";
import Navbar from "./navbar";
import Body from "./body";
import Footer from "./footer";
import Sidebar from "./sidebar";
import { Context } from "../backend/context";
import { connect } from "react-redux";
import { setPendingBotsData } from "../actions/pendingBotsAction";
import { setBotsData } from "../actions/botsAction";
import { setVolumeNprofit } from "../actions/volumeNprofitAction";
import { setActiveDealsData } from "../actions/activeDealsAction";

import worker from "workerize-loader!../backend/worker"; // eslint-disable-line import/no-webpack-loader-syntax

const workerInstance = worker();

function Platform(props) {
  const { isLoggedIn } = React.useContext(Context);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      getPendingBots();
      getBots();
      getVolumeNprofit();
      getAvtiveDeals();
    } else {
      props.setVolumeNprofit({
        data: {},
      });
      props.setBotsData({
        hedgeBots: [],
        tickerBots: [],
        marketMakerBots: [],
        activeHedgeBots: [],
        activeTickerBots: [],
        activeMarketMakerBots: [],
        allBots: {},
      });
      props.setPendingBotsData({
        hedgeBots: [],
        tickerBots: [],
        marketMakerBots: [],
      });
    }

    workerInstance.addEventListener("message", (message) => {
      if (message.data && message.data.type === "volumeNprofit") {
        props.setVolumeNprofit(message.data.data);
      }
    });

    return () => {
      firebase.database().ref("/volumeNprofit/").off();
      firebase.database().ref("/activeDeals/").off();
    };
  }, [isLoggedIn]);

  const getVolumeNprofit = () => {
    firebase
      .database()
      .ref("/volumeNprofit/")
      .on("value", (snapshot) => {
        workerInstance.getVolumeNprofit(snapshot.val());
      });
  };
  const getAvtiveDeals = () => {
    firebase
      .database()
      .ref("/activeDeals/")
      .on("value", (snapshot) => {
        props.setActiveDealsData(snapshot.val());
      });
  };

  const getPendingBots = () => {
    // console.log("getting getPendingBots");
    firebase
      .firestore()
      .collection("pendingBots")
      .onSnapshot(
        (snapshot) => {
          var result = {
            hedgeBots: [],
            tickerBots: [],
            marketMakerBots: [],
          };

          snapshot.forEach((doc) => {
            if (doc.data().type === "HEDGE") {
              result.hedgeBots.push({
                ...doc.data(),
                id: doc.id,
              });
            } else if (doc.data().type === "TICKER") {
              result.tickerBots.push({
                ...doc.data(),
                id: doc.id,
              });
            } else if (doc.data().type === "MARKET-MAKER") {
              result.marketMakerBots.push({
                ...doc.data(),
                id: doc.id,
              });
            }
          });

          props.setPendingBotsData(result);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
  };
  const getBots = () => {
    firebase
      .firestore()
      .collection("bots")
      .orderBy("market")
      .onSnapshot(
        (snapshot) => {
          var result = {
            hedgeBots: [],
            tickerBots: [],
            marketMakerBots: [],
            activeHedgeBots: [],
            activeTickerBots: [],
            activeMarketMakerBots: [],
            allBots: {},
          };

          snapshot.forEach((doc) => {
            result.allBots[doc.id] = { ...doc.data(), id: doc.id };

            if (doc.data().type === "HEDGE") {
              if (doc.data().isRunning) {
                result.activeHedgeBots.push({
                  ...doc.data(),
                  id: doc.id,
                });
              } else {
                result.hedgeBots.push({
                  ...doc.data(),
                  id: doc.id,
                });
              }
            } else if (doc.data().type === "TICKER") {
              if (doc.data().isRunning) {
                result.activeTickerBots.push({
                  ...doc.data(),
                  id: doc.id,
                });
              } else {
                result.tickerBots.push({
                  ...doc.data(),
                  id: doc.id,
                });
              }
            } else if (doc.data().type === "MARKET-MAKER") {
              if (doc.data().isRunning) {
                result.activeMarketMakerBots.push({
                  ...doc.data(),
                  id: doc.id,
                });
              } else {
                result.marketMakerBots.push({
                  ...doc.data(),
                  id: doc.id,
                });
              }
            }
          });

          props.setBotsData(result);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
  };

  return (
    <div
      className="dark-1 "
      style={{
        marginTop: "3.5rem",
      }}
    >
      {!isLoggedIn ? (
        <Redirect to="/" />
      ) : (
        <React.Fragment>
          <Navbar />
          <Sidebar />
          <Body />
          <Footer />
        </React.Fragment>
      )}
    </div>
  );
}

export default connect(null, {
  setPendingBotsData,
  setBotsData,
  setVolumeNprofit,
  setActiveDealsData,
})(Platform);
