import React, { useEffect, useState } from "react";
import { Context } from "../../backend/context";
import firebase from "firebase/compat/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faPercent } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CreateBotPopup from "../createBotPopup";
import Select from "react-select";
import Bytedex from "../../backend/bytedex";
import SyntheticTokenHandler from "./syntheticTokenHandler";

function CreateMarketMakerBot(props) {
  const {
    exchanges,
    exchangesById,
    marketDataAll,
    marketDataAllList,
    loadMarketData,
  } = React.useContext(Context);
  const [name, setName] = useState("");
  const [orderInterval, setOrderInterval] = useState(20);
  const [baseAssetsTotal, setBaseAssetsTotal] = useState(0);
  const [quoteAssetsTotal, setQuoteAssetsTotal] = useState(500);
  const [maxOrders, setMaxOrders] = useState(20);
  const [orderAmountShapeFactor, setOrderAmountShapeFactor] = useState(10);
  const [orderPriceShapeFactor, setOrderPriceShapeFactor] = useState(1);
  const [riskFactor, setRiskFactor] = useState(1);
  const [minSpread, setMinSpread] = useState(0.5);
  const [targetBaseAssets, setTargetBaseAssets] = useState(50);
  const [alert, setAlert] = useState(null);
  const [account, setAccount] = useState("");
  const [accountEx, setAccountEx] = useState("");
  const [accountTwo, setAccountTwo] = useState("");
  const [accountTwoEx, setAccountTwoEx] = useState("");
  const [secondEx, setSecondEx] = useState("no");
  const [sourceMarket, setSourceMarket] = useState("btc_usdt");

  const [initialPrice, setInitialPrice] = useState(0);
  const [capitalPer1PerPriceChange, setCapitalPer1PerChange] = useState(500);
  const [tickerOrderSizeMax, setTickerOrderSizeMax] = useState(500);

  const [currencyPrice, setCurrencyPrice] = useState(0);
  const [market, setMarket] = useState("btc_usdt");
  const [marketData, setMarketData] = useState({
    symbol: "btc_usdt",
    symbolOpp: "usdt_btc",
    baseAsset: "btc",
    quoteAsset: "usdt",
    step: 0.1,
    tickDigits: 10,
    minOrderSizeBase: 0.0005,
    minOrderSizeQuote: 10,
  });
  const [assetBits, setAssetBits] = useState({
    base: 1000000,
    quote: 100,
  });
  const [submitType, setSubmitType] = useState("start");
  const [marketList, setMarketList] = useState([]);
  const [sourcemarketList, setSourceMarketList] = useState([]);
  const [isSyntheticToken, setSyntheticToken] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [isGrid, setGrid] = useState(true);

  const [isTickerBotActivate, setTickerBotActivate] = useState(true);
  const [minimumOrderSize, setMinimumOrderSize] = useState(0);
  const [maximumOrderSize, setMaximumOrderSize] = useState(0);

  useEffect(() => {
    if (marketDataAllList && marketDataAllList[accountEx]) {
      var lisst = [];
      for (
        let index = 0;
        index < marketDataAllList[accountEx].length;
        index++
      ) {
        const element = marketDataAllList[accountEx][index];

        lisst.push({
          value: element.symbol,
          label: element.symbol.replace("_", "/").toUpperCase(),
        });
      }

      setMarketList(lisst);
    }
  }, [marketDataAllList, accountEx, marketDataAll]);

  //handle source
  useEffect(() => {
    if (marketDataAllList && marketDataAllList[secondEx]) {
      var lisst = [];
      for (let index = 0; index < marketDataAllList[secondEx].length; index++) {
        const element = marketDataAllList[secondEx][index];

        lisst.push({
          value: element.symbol,
          label: element.symbol.replace("_", "/").toUpperCase(),
        });
      }

      setSourceMarketList(lisst);
    } else if (marketDataAllList && secondEx !== "no") {
      loadMarketData(secondEx);
    }
  }, [marketDataAllList[secondEx], secondEx, marketDataAll]);

  function handleSetMarket(val) {
    if (val && val.value) {
      setMarket(val.value);
    }
  }

  function handleSetSourceMarket(val) {
    if (val && val.value) {
      setSourceMarket(val.value);
    }
  }

  useEffect(() => {
    setAccountEx(exchangesById[account] ? exchangesById[account].exchange : "");
  }, [account, exchangesById]);

  useEffect(() => {
    setAccountTwoEx(
      exchangesById[accountTwo] ? exchangesById[accountTwo].exchange : ""
    );
  }, [accountTwo, exchangesById]);

  async function getTicker() {
    var ticker = await Bytedex.getTicker(marketData.symbol.toUpperCase());

    if (ticker && ticker.last_price) {
      setCurrencyPrice(ticker.last_price);
    }
  }

  useEffect(() => {
    if (marketData && marketData.symbol && !isSyntheticToken) {
      Bytedex.initSocket(
        { topic: "orderbooks", symbol: marketData.symbol.toUpperCase() },
        (msg) => {
          const json = JSON.parse(msg.data);
          try {
            if (
              json.data &&
              json.data.length > 0 &&
              json.data[0].length > 0 &&
              json.data[1].length > 0
            ) {
              var price = json.data[1][0][0];
              setCurrencyPrice(price ? price : 0);
              console.log(price);
            } else if (json.data) {
              getTicker();
            }
          } catch (err) {
            console.log(err);
          }
        }
      );

      return () => {
        Bytedex.closeSocket({
          topic: "orderbooks",
          symbol: marketData.symbol.toUpperCase(),
        });
      };
    }
  }, [marketData, isSyntheticToken]);
  useEffect(() => {
    if (currencyPrice && marketData.symbol) {
      var assetBitsQuote = {
        usdt: 100,
        btc: 1000000,
        try: 100,
        usdc: 100,
        dai: 100,
        eur: 100,
        bext: 1,
      };
      if (
        assetBits.base !==
          10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ) ||
        assetBits.quote !== assetBitsQuote[marketData.quoteAsset]
      ) {
        // console.log("updated assets");
        setAssetBits({
          base:
            10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ),
          quote: assetBitsQuote[marketData.quoteAsset],
        });
      }
    }
  }, [currencyPrice, marketData, assetBits]);

  //set alert
  useEffect(() => {
    if (account) {
      if (accountTwo) {
        if (accountEx === "bytedex") {
          if (!marketData) {
            setAlert("Unsupported Market !");
          } else if (
            !isSyntheticToken &&
            secondEx !== "no" &&
            market.split("_")[0] !== "botc" &&
            market.split("_")[0] !== sourceMarket.split("_")[0]
          ) {
            setAlert("Unsupported Source Market !");
          } else if (accountTwoEx !== "bytedex" || accountTwo === account) {
            setAlert("Unsupported Taker exchange's account !");
          } else {
            setAlert(null);
          }
        } else {
          setAlert("Unsupported exchange's account !");
        }
      } else {
        setAlert("Please connect Taker exchange !");
      }
    } else {
      setAlert("Please connect exchange !");
    }
  }, [
    marketData,
    currencyPrice,
    assetBits,
    account,
    accountEx,
    sourceMarket,
    secondEx,
    isSyntheticToken,
    accountTwoEx,
    accountTwo,
  ]);

  //get  market data
  useEffect(() => {
    var symbolD =
      marketDataAll && marketDataAll[accountEx]
        ? marketDataAll[accountEx][market]
        : null;
    if (symbolD) {
      setMarketData(symbolD);
    }
  }, [market, marketDataAll, accountEx]);

  const resetData = () => {
    props.setCreatingBot(false);
    setBaseAssetsTotal(0);
    setOrderInterval(0);
    setQuoteAssetsTotal(0);
    setTargetBaseAssets(0);
    setOrderAmountShapeFactor(0);
    setOrderPriceShapeFactor(0);
    setMaximumOrderSize(0);
    setMinimumOrderSize(0);
    setMaxOrders(0);
    setRiskFactor(0);
    setMinSpread(0);
    setSecondEx("no");
    setSourceMarket("btc_usdt");
    setSyntheticToken(false);
    setGrid(false);
    setTickerBotActivate(true);
    setSourceList([]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitType === "start") {
      submitNewBot();
    } else {
      handleSaveBot();
    }
  };
  const submitNewBot = () => {
    if (accountEx === "bytedex" && accountTwoEx === "bytedex") {
      if (!alert) {
        let data = {
          name: name,
          type: "MARKET-MAKER",
          market: market,
          sourceMarket: sourceMarket,
          baseAssetsTotal: parseFloat(baseAssetsTotal),
          quoteAssetsTotal: parseFloat(quoteAssetsTotal),
          orderInterval: parseFloat(orderInterval),
          maxOrders: parseFloat(maxOrders),
          orderAmountShapeFactor: parseFloat(orderAmountShapeFactor),
          orderPriceShapeFactor: parseFloat(orderPriceShapeFactor),
          riskFactor: parseFloat(riskFactor),
          minSpread: parseFloat(minSpread),
          targetBaseAssets: parseFloat(targetBaseAssets),
          maximumOrderSize: parseFloat(maximumOrderSize),
          minimumOrderSize: parseFloat(minimumOrderSize),
          initialPrice: parseFloat(initialPrice),
          capitalPer1PerPriceChange: parseFloat(capitalPer1PerPriceChange),
          tickerOrderSizeMax: parseFloat(tickerOrderSizeMax),
          isSyntheticToken: isSyntheticToken,
          syntheticData: sourceList,
          isGrid: isGrid,
          isTickerBotActivate: isTickerBotActivate,
          account: account,
          accountTwo: accountTwo,
          exchange: accountEx,
          secondEx: secondEx,
          createdAt: Date.now(),
          startedAt: Date.now(),
          runningTime: 0,
          isRunning: false,
          isRestarting: false,
          isPending: true,
          processID: "",
          displayId: "M" + (Date.now() - new Date("2022/1/1")).toString(),
        };
        firebase
          .firestore()
          .collection("pendingBots")
          .add(data)
          .catch((err) => {
            console.log(err);
          });

        document.getElementById("createBotPopupOpen").click();
      }
    } else {
      setAlert("Unsupported exchange's account !");
    }
  };
  const handleClosePopup = () => {
    // resetData();
    document.getElementById("closeCreateBotPopup").click();
  };
  const handleSaveBot = () => {
    if (accountEx === "bytedex" && accountTwoEx === "bytedex") {
      //save bot
      let data = {
        name: name,
        type: "MARKET-MAKER",
        market: market,
        sourceMarket: sourceMarket,
        baseAssetsTotal: parseFloat(baseAssetsTotal),
        quoteAssetsTotal: parseFloat(quoteAssetsTotal),
        orderInterval: parseFloat(orderInterval),
        maxOrders: parseFloat(maxOrders),
        orderAmountShapeFactor: parseFloat(orderAmountShapeFactor),
        orderPriceShapeFactor: parseFloat(orderPriceShapeFactor),
        riskFactor: parseFloat(riskFactor),
        minSpread: parseFloat(minSpread),
        targetBaseAssets: parseFloat(targetBaseAssets),
        maximumOrderSize: parseFloat(maximumOrderSize),
        minimumOrderSize: parseFloat(minimumOrderSize),
        initialPrice: parseFloat(initialPrice),
        capitalPer1PerPriceChange: parseFloat(capitalPer1PerPriceChange),
        tickerOrderSizeMax: parseFloat(tickerOrderSizeMax),
        isSyntheticToken: isSyntheticToken,
        syntheticData: sourceList,
        isGrid: isGrid,
        isTickerBotActivate: isTickerBotActivate,
        account: account,
        accountTwo: accountTwo,
        exchange: accountEx,
        secondEx: secondEx,
        createdAt: Date.now(),
        startedAt: 0,
        runningTime: 0,
        isRunning: false,
        isPending: false,
        isRestarting: false,
        processID: "",
        displayId: "M" + (Date.now() - new Date("2022/1/1")).toString(),
      };
      firebase
        .firestore()
        .collection("bots")
        .add(data)
        .catch((err) => {
          console.log(err);
        });
      //
      resetData();
    } else {
      setAlert("Unsupported exchange's account !");
    }
  };

  return (
    <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 h5">Create Market Maker Bot</div>
            <div className="col-2 h5">
              <button
                onClick={() => props.setCreatingBot(false)}
                className="border border-dark rounded-lg px-2 dark-3 sim-btn"
              >
                <FontAwesomeIcon icon={faChevronUp} size="1x" color="#d9d9d9" />
              </button>
            </div>
          </div>

          <CreateBotPopup handleClosePopup={handleClosePopup} />
          <button
            data-toggle="modal"
            data-target="#createBotPopup"
            type="button"
            id="createBotPopupOpen"
            style={{ display: "none" }}
          ></button>
          <div className="row justify-content-around">
            <div className="col-12 col-sm-10 px-3 my-2 rounded-lg dark-2 pt-2 mx-auto">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {alert && (
                    <div className="col-12 col-sm-6 mx-auto mt-2">
                      <div className="row">
                        <div className="col-10 m-auto">
                          <div
                            className="alert alert-warning py-2 text-center mb-2"
                            style={{
                              backgroundColor: "rgb(255, 204, 0,0.1)",
                              color: "yellow",
                            }}
                          >
                            {alert}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12  text-left m-auto">
                    <div className="form-group row my-3 ">
                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Account :
                      </label>
                      <div className="col-12 col-sm-4 text-center">
                        {exchanges.length > 0 ? (
                          <select
                            required
                            onChange={(e) => {
                              setAccount(e.target.value);
                            }}
                            className="custom-select mr-sm-2 form-control"
                            defaultValue={""}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {" "}
                            <option value=""></option>
                            {exchanges.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name + " ( " + item.exchange + " )"}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <Link
                            to="/account/exchanges"
                            className="btn btn-sm btn-primary m-auto w-100"
                            // style={{ backgroundColor: "#0080ff" }}
                          >
                            Connect Exchange
                          </Link>
                        )}
                      </div>
                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Account Taker :
                      </label>
                      <div className="col-12 col-sm-4 text-center">
                        {exchanges.length > 0 ? (
                          <select
                            required
                            onChange={(e) => {
                              setAccountTwo(e.target.value);
                            }}
                            className="custom-select mr-sm-2 form-control"
                            defaultValue={""}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {" "}
                            <option value=""></option>
                            {exchanges.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name + " ( " + item.exchange + " )"}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <Link
                            to="/account/exchanges"
                            className="btn btn-sm btn-primary m-auto w-100"
                            // style={{ backgroundColor: "#0080ff" }}
                          >
                            Connect Exchange
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="form-group row my-3 ">
                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Market :
                      </label>
                      <div className="col-12 col-sm-4">
                        <Select
                          required
                          onChange={(e) => {
                            handleSetMarket(e);
                          }}
                          defaultValue={market}
                          isClearable={true}
                          isSearchable={true}
                          name="markets"
                          options={marketList}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: "#1a75ff",
                              primary50: "#1a75ff",
                              neutral0: "black",
                              neutral5: "gray",
                              neutral10: "gray",
                              neutral20: "gray",
                              neutral50: "rgb(158, 158, 158)",
                              neutral80: "rgb(158, 158, 158)",
                              neutral90: "white",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="form-group row my-3 ">
                      <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                        Is Synthetic Token :
                      </label>
                      <div className="col-4 my-1 col-sm-3 verticalCenter ">
                        <input
                          type="checkbox"
                          className="pr-3 mr-3 verticalCenter"
                          // style={{ fontSize: "1.5rem" }}
                          checked={isSyntheticToken}
                          onChange={(e) => {
                            setSyntheticToken(e.target.checked);
                          }}
                        />
                      </div>

                      {isSyntheticToken && (
                        <React.Fragment>
                          <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                            Token Price :
                          </label>
                          <div className="col-4 my-1 col-sm-3 verticalCenter ">
                            {currencyPrice}
                          </div>
                        </React.Fragment>
                      )}
                    </div>

                    {isSyntheticToken ? (
                      <SyntheticTokenHandler
                        setCurrencyPrice={setCurrencyPrice}
                        setSourceList={setSourceList}
                        marketData={marketData}
                      />
                    ) : (
                      <div className="form-group row my-3 ">
                        <label className="col-12 col-sm-2 col-form-label pl-3 pb-1">
                          Price Source :
                        </label>
                        <div className="col-12 col-sm-4">
                          <select
                            required
                            onChange={(e) => {
                              setSecondEx(e.target.value);
                            }}
                            className="custom-select mr-sm-2 form-control"
                            value={secondEx}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <option value="no">Not Available</option>{" "}
                            <option value="kucoin">Kucoin</option>
                            <option value="gateio">Gateio</option>
                            <option value="binance">Binance</option>
                            <option value="mexc">Mexc</option>
                            <option value="huobi">Huobi</option>
                            <option value="okx">OKX</option>
                          </select>
                        </div>
                        {secondEx !== "no" && (
                          <React.Fragment>
                            <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                              Source Market :
                            </label>

                            <div className="col-12 col-sm-4">
                              <Select
                                required
                                onChange={(e) => {
                                  handleSetSourceMarket(e);
                                }}
                                defaultValue={market}
                                isClearable={true}
                                isSearchable={true}
                                name="markets"
                                options={sourcemarketList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#1a75ff",
                                    primary50: "#1a75ff",
                                    neutral0: "black",
                                    neutral5: "gray",
                                    neutral10: "gray",
                                    neutral20: "gray",
                                    neutral50: "rgb(158, 158, 158)",
                                    neutral80: "rgb(158, 158, 158)",
                                    neutral90: "white",
                                  },
                                })}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                    {secondEx === "no" && (
                      <div className="form-group row my-3 ">
                        <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                          Base Price :
                        </label>
                        <div className="col-12 col-sm-4 mb-2">
                          <input
                            onChange={(e) =>
                              setInitialPrice(parseFloat(e.target.value))
                            }
                            type="number"
                            step={marketData.step.toString()}
                            min={0}
                            className="form-control"
                            required
                            value={initialPrice}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          />
                          <span
                            className=""
                            style={{
                              minWidth: "2.8rem",
                              position: "absolute",
                              right: "1rem",
                              top: "0.5rem",
                              color: "gray",
                            }}
                          >
                            {marketData.quoteAsset &&
                              marketData.quoteAsset.toUpperCase()}
                          </span>
                        </div>
                        <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                          Volume per 1% Price Change :
                        </label>
                        <div className="col-12 col-sm-4 mb-2">
                          <input
                            onChange={(e) =>
                              setCapitalPer1PerChange(
                                parseFloat(e.target.value)
                              )
                            }
                            type="number"
                            step={(1 / assetBits.quote).toString()}
                            min={0}
                            className="form-control"
                            required
                            value={capitalPer1PerPriceChange}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          />
                          <span
                            className=""
                            style={{
                              minWidth: "2.8rem",
                              position: "absolute",
                              right: "1rem",
                              top: "0.5rem",
                              color: "gray",
                            }}
                          >
                            {marketData.quoteAsset &&
                              marketData.quoteAsset.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="form-group row my-3 ">
                      <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                        Is Activate Ticker Bot :
                      </label>
                      <div className="col-4 my-1 col-sm-3 verticalCenter ">
                        <input
                          type="checkbox"
                          className="pr-3 mr-3 verticalCenter"
                          // style={{ fontSize: "1.5rem" }}
                          checked={isTickerBotActivate}
                          onChange={(e) => {
                            setTickerBotActivate(e.target.checked);
                          }}
                        />
                      </div>
                      {isTickerBotActivate && (
                        <React.Fragment>
                          <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                            Ticker Max OrderSize :
                          </label>
                          <div className="col-12 col-sm-4 mb-2">
                            <input
                              onChange={(e) =>
                                setTickerOrderSizeMax(
                                  parseFloat(e.target.value)
                                )
                              }
                              type="number"
                              step={(1 / assetBits.quote).toString()}
                              min={0}
                              className="form-control"
                              required
                              value={tickerOrderSizeMax}
                              style={{
                                fontSize: "0.8rem",
                              }}
                            />
                            <span
                              className=""
                              style={{
                                minWidth: "2.8rem",
                                position: "absolute",
                                right: "1rem",
                                top: "0.5rem",
                                color: "gray",
                              }}
                            >
                              USDT
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Min Order Size :
                      </label>
                      <div className="col-12 col-sm-4 mb-2">
                        <input
                          onChange={(e) =>
                            setMinimumOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={minimumOrderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Max Order Size :
                      </label>
                      <div className="col-12 col-sm-4 ">
                        <input
                          onChange={(e) =>
                            setMaximumOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={maximumOrderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Initial Base Assets Total :
                      </label>
                      <div className="col-12 col-sm-4 mb-2">
                        <input
                          onChange={(e) =>
                            setBaseAssetsTotal(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.base).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={baseAssetsTotal}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.baseAsset &&
                            marketData.baseAsset.toUpperCase()}
                        </span>
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "2rem",
                            top: "2rem",
                            color: "gray",
                          }}
                        >
                          ~{" "}
                          {(baseAssetsTotal * currencyPrice).toFixed(
                            Math.log10(assetBits.quote)
                          )}{" "}
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Initial Quote Assets Total :
                      </label>
                      <div className="col-12 col-sm-4 ">
                        <input
                          onChange={(e) =>
                            setQuoteAssetsTotal(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={quoteAssetsTotal}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Min Spread :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMinSpread(parseFloat(e.target.value))
                          }
                          type="number"
                          step="0.01"
                          min="0.01"
                          max="100"
                          className="form-control"
                          required
                          value={minSpread}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "0",
                            top: "0.5rem",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPercent}
                            size="1x"
                            color="gray"
                          />
                        </span>
                      </div>

                      {secondEx === "no" && !isGrid && (
                        <React.Fragment>
                          <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                            Target Base Assets :
                          </label>
                          <div className="col-12 col-sm-4">
                            <input
                              onChange={(e) =>
                                setTargetBaseAssets(parseFloat(e.target.value))
                              }
                              type="number"
                              step="1"
                              min="0"
                              max="100"
                              className="form-control"
                              required
                              value={targetBaseAssets}
                              style={{
                                fontSize: "0.8rem",
                              }}
                            />
                            <span
                              className=""
                              style={{
                                minWidth: "2.8rem",
                                position: "absolute",
                                right: "0",
                                top: "0.5rem",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPercent}
                                size="1x"
                                color="gray"
                              />
                            </span>
                          </div>{" "}
                        </React.Fragment>
                      )}
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Extra Order Price Shape Factor :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setOrderPriceShapeFactor(parseFloat(e.target.value))
                          }
                          type="number"
                          step="0.01"
                          min="0.01"
                          max="100"
                          className="form-control"
                          required
                          value={orderPriceShapeFactor}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "0",
                            top: "0.5rem",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPercent}
                            size="1x"
                            color="gray"
                          />
                        </span>
                      </div>

                      <React.Fragment>
                        <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                          Extra Order Amount Shape Factor :
                        </label>
                        <div className="col-12 col-sm-4">
                          <input
                            onChange={(e) =>
                              setOrderAmountShapeFactor(
                                parseFloat(e.target.value)
                              )
                            }
                            type="number"
                            step="0.01"
                            min="0.01"
                            max="100"
                            className="form-control"
                            required
                            value={orderAmountShapeFactor}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          />
                          <span
                            className=""
                            style={{
                              minWidth: "2.8rem",
                              position: "absolute",
                              right: "0",
                              top: "0.5rem",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPercent}
                              size="1x"
                              color="gray"
                            />
                          </span>
                        </div>{" "}
                      </React.Fragment>
                    </div>
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Extra Orders :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMaxOrders(parseFloat(e.target.value))
                          }
                          type="number"
                          step="1"
                          min="1"
                          max="100"
                          className="form-control"
                          required
                          value={maxOrders}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                      </div>

                      {secondEx === "no" && !isGrid && (
                        <React.Fragment>
                          <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                            Risk Factor :
                          </label>
                          <div className="col-12 col-sm-4">
                            <input
                              onChange={(e) =>
                                setRiskFactor(parseFloat(e.target.value))
                              }
                              type="number"
                              step="1"
                              min="0"
                              max="1000"
                              className="form-control"
                              required
                              value={riskFactor}
                              style={{
                                fontSize: "0.8rem",
                              }}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="form-group row  mb-3">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Order Interval <span className="px-1"> (Seconds) </span>{" "}
                        :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setOrderInterval(parseFloat(e.target.value))
                          }
                          type="number"
                          step="1"
                          min="1"
                          className="form-control"
                          required
                          value={orderInterval}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-3 ">
                      <div className="col-6 text-center">
                        <button
                          disabled={alert && alert.length > 0}
                          type="submit"
                          className="btn btn-dark btn-sm w-75"
                          onClick={() => setSubmitType("save")}
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-6 text-center">
                        <button
                          disabled={alert && alert.length > 0}
                          type="submit"
                          className="btn btn-success btn-sm w-75"
                          onClick={() => setSubmitType("start")}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMarketMakerBot;
