import React, { useEffect, useState } from "react";
import { Context } from "../../../backend/context";
import { Link } from "react-router-dom";

import HedgeBotSummery from "./summery";
import HedgeBotSettings from "./botSettings";

import { connect } from "react-redux";
import OrdersChart from "../../ordersChart";
import AvtiveDealDetails from "../../activeDealsDetails";
import BotNotifications from "../../botNotifications";
import BotOrderDetails from "../../botOrderDetails";

function BotDetails(props) {
  const { getMarketData } = React.useContext(Context);
  const [bot, setBot] = useState(null);
  const [botVnP, setBotVnP] = useState(null);

  useEffect(() => {
    setBot(props.allBots[props.match.params.id]);

    if (props.volumeNprofitData[props.match.params.id]) {
      setBotVnP(props.volumeNprofitData[props.match.params.id]);
    } else {
      setBotVnP({
        volume: 0,
        bottodayProfit: 0,
        bottodayVolume: 0,
        profit: 0,
      });
    }
  }, [props.match.params.id, props.allBots, props.volumeNprofitData]);

  if (bot) {
    return (
      <div className="container-fluid dark-1 ">
        <div className="row">
          <div className="col-10 h5 px-5 font-weight-bold">
            <Link
              to="/account/hedgeBots"
              className=""
              style={{ color: "#b3b3b3" }}
            >
              Hedge Bot
            </Link>
          </div>{" "}
        </div>
        <div className="row ">
          <div className="col-12">
            <HedgeBotSummery
              bot={bot}
              marketData={getMarketData(bot)}
              botVnP={botVnP}
            />
          </div>
        </div>

        <div className="row ">
          <div className={`col-12 col-lg-6`}>
            <HedgeBotSettings bot={bot} />
          </div>

          <div className="col-12 col-lg-6 py-3  ">
            <div className="row">
              <div className="col-12 ">
                <AvtiveDealDetails bot={bot} marketData={getMarketData(bot)} />
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ minHeight: "14.7rem" }}>
                <OrdersChart bot={bot} marketData={getMarketData(bot)} />
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-12 col-sm-6">
            <BotOrderDetails
              id={props.match.params.id}
              market={bot.market}
              marketData={getMarketData(bot)}
              pendingOrders={[]}
              type="onlyFilled&profit"
            />
          </div>
          <div className="col-12 col-sm-6">
            <BotNotifications id={props.match.params.id} bot={bot} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-fluid dark-1 ">
        <div className="row ">
          <div className="col-12">Bot Not Found.</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allBots: state.bots.allBots,
  volumeNprofitData: state.volumeNprofit.botData,
});

export default connect(mapStateToProps)(BotDetails);
