import React, { useState, useEffect } from "react";
import { Context } from "../../backend/context";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";

import LoadingScreen from "../loadingScreen";
import { connect } from "react-redux";
import StartBotPopup from "../startBotPopup";

function MarketMakerBotList(props) {
  const { getMarketData, exchangesById } = React.useContext(Context);

  const [stoppedBotList, setStoppedBotList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const stopActiveBot = (item) => {
    if (window.confirm(`Stop bot ${item.displayId} ?`)) {
      var docu = firebase.firestore().collection("bots").doc(item.id);
      docu.update({ isRunning: false });
    }
  };
  const startStoppedBot = (item) => {
    if (
      window.confirm(
        `Start Bot ${
          item.name && item.name.length > 0 ? item.name : item.displayId
        } ?`
      )
    ) {
      let data = {
        ...item,
        startedAt: Date.now(),
        isRunning: false,
        isPending: true,
      };
      firebase
        .firestore()
        .collection("pendingBots")
        .doc(item.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });
      firebase
        .firestore()
        .collection("bots")
        .doc(item.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });

      document.getElementById("StartBotPopupOpen").click();
    }
  };
  const deleteStoppedBot = async (item) => {
    if (window.confirm(`Delete bot ${item.displayId} ?`)) {
      firebase
        .firestore()
        .collection("bots")
        .doc(item.id)
        .delete()
        .then(() => {
          console.log("bot successfully deleted!");
        })
        .catch((error) => {
          console.log(error, "Error deleted bot");
        });

      firebase.database().ref(`userNotifications/${item.id}`).remove();
      firebase.database().ref(`filledOrders/${item.id}`).remove();
    }
  };
  const handleStartALL = async () => {
    if (window.confirm(`Start All Stopped Bots ?`)) {
      setLoading(true);
      var startBotList = firebase.functions().httpsCallable("startBotList");

      try {
        var res = await startBotList({ botList: stoppedBotList });

        if (res.data.error) {
          alert(res.data.error);
          setLoading(false);
          return;
        }
        setLoading(false);
      } catch (error) {
        alert(error.response ? error.response.data : error);
        setLoading(false);
        return;
      }
    }
  };

  const handleDeleteALL = () => {
    if (window.confirm(`Delete All Stopped Bots ?`)) {
      setLoading(true);

      stoppedBotList.forEach((element) => {
        firebase.firestore().collection("bots").doc(element.id).delete();

        firebase.database().ref(`userNotifications/${element.id}`).remove();
        firebase.database().ref(`filledOrders/${element.id}`).remove();
      });
      setLoading(false); // getBotData();
    }
  };

  const handleStopAll = () => {
    if (window.confirm(`Stop All Bots ?`)) {
      setLoading(true);

      props.activeMarketMakerBots.forEach((element) => {
        firebase
          .firestore()
          .collection("bots")
          .doc(element.id)
          .update({ isRunning: false });
      });
      setLoading(false);
    }
  };
  function compare(a, b) {
    if (a.account < b.account) {
      return 1;
    }
    if (a.account > b.account) {
      return -1;
    }
    return 0;
  }

  useEffect(() => {
    var stoppedlist = [];

    props.marketMakerBots.forEach((element) => {
      if (!element.isPending) {
        stoppedlist.push(element);
      }
    });
    setStoppedBotList(stoppedlist.sort(compare));
  }, [props.marketMakerBots]);

  return (
    <div className="row ">
      <div className="col-12">
        <StartBotPopup />
        <button
          data-toggle="modal"
          data-target="#StartBotPopup"
          type="button"
          id="StartBotPopupOpen"
          style={{ display: "none" }}
        ></button>
        {isLoading && <LoadingScreen />}
        {props.pendingMarketMakerBots.length > 0 && (
          <div className="row dark-2 rounded-lg pt-3 pb-2 my-4 mx-0 px-2">
            <div className="col-12 h6">
              Pending Bots ( {props.pendingMarketMakerBots.length} )
            </div>
            <div className="col-12 " style={{ overflowX: "auto" }}>
              <div
                style={{ minWidth: "65rem" }}
                className="row  text-center  overflow-hidden py-2 dark-3 border-bottom border-dark verticalCenter mt-2"
              >
                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Bot Name
                </div>
                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Account
                </div>
                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Market
                </div>

                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Base Assets Total
                </div>
                <div
                  className="col-2 px-0 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Quote Assets Total
                </div>

                <div
                  className="col-2  overflow-hidden verticalCenter text-center justify-content-center"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                ></div>
              </div>
              {props.pendingMarketMakerBots.length > 0 ? (
                props.pendingMarketMakerBots
                  .sort(compare)
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{ minWidth: "65rem" }}
                        className="row  border-dark border-left border-bottom text-center bot-details-row"
                      >
                        <div
                          style={{ fontSize: "0.7rem" }}
                          className="col-2 center border-right border-dark py-2 px-0 overflow-hidden"
                        >
                          {item.name && item.name.length > 0
                            ? item.name
                            : item.displayId}
                        </div>
                        <div
                          style={{ fontSize: "0.7rem" }}
                          className="col-2 center border-right border-dark py-2 px-0 overflow-hidden"
                        >
                          {exchangesById[item.account]
                            ? exchangesById[item.account].name
                            : "Deleted Account"}
                        </div>
                        <div
                          style={{ fontSize: "0.7rem" }}
                          className="col-2 center border-right border-dark py-2 overflow-hidden"
                        >
                          {item.market.toUpperCase()}
                        </div>

                        <div
                          style={{ fontSize: "0.7rem" }}
                          className="col-2 center border-right border-dark py-2 overflow-hidden"
                        >
                          {parseFloat(item.baseAssetsTotal.toFixed(6))}
                          <small className="pl-1">
                            {getMarketData(item) &&
                              getMarketData(item).baseAsset.toUpperCase()}
                          </small>
                        </div>
                        <div
                          style={{ fontSize: "0.7rem" }}
                          className="col-2 center border-right border-dark py-2 overflow-hidden"
                        >
                          {parseFloat(
                            item.quoteAssetsTotal.toFixed(
                              getMarketData(item) &&
                                getMarketData(item).quoteAsset.includes("usd")
                                ? 2
                                : 6
                            )
                          )}
                          <small className="pl-1">
                            {getMarketData(item) &&
                              getMarketData(item).quoteAsset.toUpperCase()}
                          </small>
                        </div>

                        <div
                          style={{ fontSize: "0.7rem" }}
                          className="col-2 center border-right border-dark py-2 overflow-hidden"
                        >
                          <button
                            style={{ fontSize: "0.7rem" }}
                            className="btn btn-sm btn-danger "
                            disabled
                          >
                            Stop
                          </button>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="row  border-dark border-left border-bottom text-center bot-details-row">
                  <div className="col-12 border-right border-dark py-2">
                    No Pending Bots
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="row   dark-2 rounded-lg pt-3 pb-2 my-2 mx-0 px-2">
          <div className="col-6 h6  mr-0 pr-0">
            Active Bots ( {props.activeMarketMakerBots.length} )
          </div>

          <div className="col-6  text-right">
            <button
              type="button"
              className="btn btn-danger   btn-sm px-1"
              onClick={handleStopAll}
              style={{ fontSize: "0.7rem" }}
            >
              Stop All
            </button>
          </div>
          <div className="col-12 " style={{ overflowX: "auto" }}>
            <div
              style={{ minWidth: "65rem" }}
              className="row  text-center  overflow-hidden py-2 dark-3 border-bottom border-dark verticalCenter mt-2"
            >
              <div
                className="col-1  px-0overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              ></div>
              <div
                className="col-1 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Bot Name
              </div>
              <div
                className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Account
              </div>
              <div
                className="col-1 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Market
              </div>
              <div
                className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Base Assets Total
              </div>
              <div
                className="col-2 px-0 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Quote Assets Total
              </div>
              <div
                className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Total Profit ($)
              </div>{" "}
              <div
                className="col-1  overflow-hidden verticalCenter text-center justify-content-center"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              ></div>
            </div>
            {props.activeMarketMakerBots.length > 0 ? (
              props.activeMarketMakerBots.sort(compare).map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ minWidth: "65rem" }}
                    className="row  border-dark border-left border-bottom text-center bot-details-row"
                  >
                    <div
                      style={{ fontSize: "0.8rem" }}
                      className="col-1 center border-right border-dark py-2 overflow-hidden px-0"
                    >
                      <Link
                        // style={{ textDecoration: "none" }}
                        className="font-weight-bold"
                        to={"/account/mmBots/" + item.id}
                      >
                        Details
                      </Link>
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-1 center border-right border-dark py-2 px-0 overflow-hidden"
                    >
                      {item.name && item.name.length > 0
                        ? item.name
                        : item.displayId}
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-2 center border-right border-dark py-2 px-0 overflow-hidden"
                    >
                      {exchangesById[item.account]
                        ? exchangesById[item.account].name
                        : "Deleted Account"}
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-1 center border-right border-dark py-2 overflow-hidden"
                    >
                      {item.market.toUpperCase()}
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-2 center border-right border-dark py-2 overflow-hidden"
                    >
                      {parseFloat(item.baseAssetsTotal.toFixed(6))}
                      <small className="pl-1">
                        {getMarketData(item) &&
                          getMarketData(item).baseAsset.toUpperCase()}
                      </small>
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-2 center border-right border-dark py-2 overflow-hidden"
                    >
                      {parseFloat(
                        item.quoteAssetsTotal.toFixed(
                          getMarketData(item) &&
                            getMarketData(item).quoteAsset.includes("usd")
                            ? 2
                            : 6
                        )
                      )}
                      <small className="pl-1">
                        {getMarketData(item) &&
                          getMarketData(item).quoteAsset.toUpperCase()}
                      </small>
                    </div>
                    <div className="col-2 center border-right border-dark py-2 overflow-hidden">
                      {props.volumeNprofitData[item.id] &&
                      props.volumeNprofitData[item.id].profit
                        ? props.volumeNprofitData[item.id].profit.toFixed(2)
                        : 0}
                    </div>{" "}
                    <div className="col-1 center border-right border-dark py-2 overflow-hidden">
                      <button
                        style={{ fontSize: "0.7rem" }}
                        className="btn btn-sm btn-danger"
                        onClick={() => stopActiveBot(item)}
                      >
                        Stop
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="row  border-dark border-left border-bottom text-center bot-details-row">
                <div className="col-12 border-right border-dark py-2">
                  No Active Bots
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row dark-2 rounded-lg pt-3 pb-2 my-4 mx-0 px-2">
          <div className="col-6 h6 mr-0 pr-0">
            Stopped Bots ( {stoppedBotList.length} )
          </div>
          <div className="col-6  text-right">
            <button
              type="button"
              className="btn btn-success ml-2 btn-sm px-1"
              onClick={handleStartALL}
              style={{ fontSize: "0.7rem" }}
            >
              Start All
            </button>{" "}
            <button
              type="button"
              className="btn btn-danger ml-2 btn-sm px-1"
              onClick={handleDeleteALL}
              style={{ fontSize: "0.7rem" }}
            >
              Delete All
            </button>
          </div>
          <div className="col-12 " style={{ overflowX: "auto" }}>
            <div
              style={{ minWidth: "65rem" }}
              className="row  text-center  overflow-hidden py-2 dark-3 border-bottom border-dark verticalCenter mt-2"
            >
              <div
                className="col-1  px-0overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              ></div>
              <div
                className="col-1 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Bot Name
              </div>
              <div
                className="col-1 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Account
              </div>
              <div
                className="col-1 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Market
              </div>
              <div
                className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Base Assets Total
              </div>
              <div
                className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Quote Assets Total
              </div>
              <div
                className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              >
                Total Profit ($)
              </div>{" "}
              <div
                className="col-1 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              ></div>
              <div
                className="col-1  overflow-hidden verticalCenter text-center justify-content-center"
                style={{ fontSize: "0.7rem", minHeight: "2rem" }}
              ></div>
            </div>
            {stoppedBotList.length > 0 ? (
              stoppedBotList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ minWidth: "65rem" }}
                    className="row  border-dark border-left border-bottom text-center bot-details-row"
                  >
                    <div
                      style={{ fontSize: "0.8rem" }}
                      className="col-1 center border-right border-dark py-2 overflow-hidden px-0"
                    >
                      <Link
                        // style={{ textDecoration: "none" }}
                        className="font-weight-bold"
                        to={"/account/mmBots/" + item.id}
                      >
                        Details
                      </Link>
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-1 center border-right border-dark py-2 px-0 overflow-hidden"
                    >
                      {item.name && item.name.length > 0
                        ? item.name
                        : item.displayId}
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-1 center border-right border-dark py-2 px-0 overflow-hidden"
                    >
                      {exchangesById[item.account]
                        ? exchangesById[item.account].name
                        : "Deleted Account"}
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-1 center border-right border-dark py-2 overflow-hidden"
                    >
                      {item.market.toUpperCase()}
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-2 center border-right border-dark py-2 overflow-hidden"
                    >
                      {parseFloat(item.baseAssetsTotal.toFixed(6))}
                      <small className="pl-1">
                        {getMarketData(item) &&
                          getMarketData(item).baseAsset.toUpperCase()}
                      </small>
                    </div>
                    <div
                      style={{ fontSize: "0.7rem" }}
                      className="col-2 center border-right border-dark py-2 overflow-hidden"
                    >
                      {parseFloat(
                        item.quoteAssetsTotal.toFixed(
                          getMarketData(item) &&
                            getMarketData(item).quoteAsset.includes("usd")
                            ? 2
                            : 6
                        )
                      )}
                      <small className="pl-1">
                        {getMarketData(item) &&
                          getMarketData(item).quoteAsset.toUpperCase()}
                      </small>
                    </div>
                    <div className="col-2 center border-right border-dark py-2 overflow-hidden">
                      {props.volumeNprofitData[item.id] &&
                      props.volumeNprofitData[item.id].profit
                        ? props.volumeNprofitData[item.id].profit.toFixed(2)
                        : 0}
                    </div>{" "}
                    <div className="col-1 center border-right border-dark py-2 overflow-hidden">
                      <button
                        style={{ fontSize: "0.7rem" }}
                        onClick={() => startStoppedBot(item)}
                        className="btn btn-sm btn-success"
                      >
                        Start
                      </button>
                    </div>
                    <div className="col-1 center border-right border-dark py-2 overflow-hidden">
                      <button
                        style={{ fontSize: "0.7rem" }}
                        onClick={() => deleteStoppedBot(item)}
                        className="btn btn-sm btn-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="row  border-dark border-left border-bottom text-center bot-details-row">
                <div className="col-12 border-right border-dark py-2">
                  No Stopped Bots
                </div>
              </div>
            )}
          </div>
        </div>
        {props.pendingMarketMakerBots.length === 0 && (
          <div className="row dark-2 rounded-lg pt-3 pb-2 my-4 mx-0 px-2">
            <div className="col-12 h6">
              Pending Bots ( {props.pendingMarketMakerBots.length} )
            </div>
            <div className="col-12 " style={{ overflowX: "auto" }}>
              <div
                style={{ minWidth: "65rem" }}
                className="row  text-center  overflow-hidden py-2 dark-3 border-bottom border-dark verticalCenter mt-2"
              >
                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Bot Name
                </div>
                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Account
                </div>
                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Market
                </div>

                <div
                  className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Base Assets Total
                </div>
                <div
                  className="col-2 px-0 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                >
                  Quote Assets Total
                </div>

                <div
                  className="col-2  overflow-hidden verticalCenter text-center justify-content-center"
                  style={{ fontSize: "0.7rem", minHeight: "2rem" }}
                ></div>
              </div>

              <div className="row  border-dark border-left border-bottom text-center bot-details-row">
                <div className="col-12 border-right border-dark py-2">
                  No Pending Bots
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  activeMarketMakerBots: state.bots.activeMarketMakerBots,
  marketMakerBots: state.bots.marketMakerBots,
  pendingMarketMakerBots: state.pendingBots.marketMakerBots,
  volumeNprofitData: state.volumeNprofit.botData,
});

export default connect(mapStateToProps)(MarketMakerBotList);
