import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

import Bytedex from "../backend/bytedex";

function AvtiveDealDetails(props) {
  const [currencyPrice, setCurrencyPrice] = useState(0);
  const [quoteAssetPrice, setquoteAssetPrice] = useState(1);
  const [CurrentTrend, setValues] = useState("-");
  const [activeDealDetails, setActiveDeals] = useState({
    quoteAmount: 0,
    baseAmount: 0,
    averageBuy: 0,
  });

  useEffect(() => {
    //get active deals
    if (props.bot) {
      firebase
        .database()
        .ref(`/activeDeals/${props.bot.id}`)
        .on("value", (snapshot) => {
          if (
            snapshot.val() &&
            snapshot.val().quoteAmount &&
            snapshot.val().baseAmount &&
            Math.abs(snapshot.val().baseAmount) >
              props.marketData.minOrderSizeBase &&
            Math.abs(snapshot.val().quoteAmount) >
              props.marketData.minOrderSizeQuote
          ) {
            var avBuy =
              props.bot && props.marketData
                ? Math.floor(
                    (snapshot.val().quoteAmount / snapshot.val().baseAmount +
                      Number.EPSILON) *
                      props.marketData.tickDigits
                  ) / props.marketData.tickDigits
                : 0;

            setActiveDeals({
              quoteAmount: snapshot.val().quoteAmount,
              baseAmount: snapshot.val().baseAmount,
              averageBuy: avBuy,
            });
          } else {
            setActiveDeals({
              quoteAmount: 0,
              baseAmount: 0,
              averageBuy: 0,
            });
          }
        });
    }
  }, [props]);

  async function getTicker() {
    var ticker = await Bytedex.getTicker(props.marketData.symbol.toUpperCase());

    if (ticker && ticker.last_price) {
      setCurrencyPrice(ticker.last_price);
    }
  }

  async function getQuoteAssetPrice() {
    if (props.marketData && props.marketData.quoteAsset === "btc") {
      var ticker = await Bytedex.getTicker("BTC_USDT");

      if (ticker && ticker.last_price) {
        console.log(ticker.last_price);

        setquoteAssetPrice(ticker.last_price);
      }
    } else if (props.marketData && props.marketData.quoteAsset === "try") {
      var ticker = await Bytedex.getTicker("USDT_TRY");

      if (ticker && ticker.last_price) {
        console.log(1 / ticker.last_price);

        setquoteAssetPrice(1 / ticker.last_price);
      }
    } else if (props.marketData && props.marketData.quoteAsset === "dai") {
      var ticker = await Bytedex.getTicker("USDT_DAI");

      if (ticker && ticker.last_price) {
        console.log(1 / ticker.last_price);

        setquoteAssetPrice(1 / ticker.last_price);
      }
    } else if (props.marketData && props.marketData.quoteAsset === "eur") {
      var ticker = await Bytedex.getTicker("EUR_USDT");

      if (ticker && ticker.last_price) {
        console.log(ticker.last_price);

        setquoteAssetPrice(ticker.last_price);
      }
    } else if (props.marketData && props.marketData.quoteAsset === "usdc") {
      var ticker = await Bytedex.getTicker("USDC_USDT");

      if (ticker && ticker.last_price) {
        console.log(ticker.last_price);

        setquoteAssetPrice(ticker.last_price);
      }
    } else if (props.marketData && props.marketData.quoteAsset === "bext") {
      var ticker = await Bytedex.getTicker("BEXT_USDT");

      if (ticker && ticker.last_price) {
        console.log(ticker.last_price);

        setquoteAssetPrice(ticker.last_price);
      }
    } else {
      setquoteAssetPrice(1);
    }
  }

  useEffect(() => {
    if (props.marketData) {
      getTicker();
    }

    setTimeout(() => {
      getQuoteAssetPrice();
    }, 2000);
  }, [props.marketData]);

  return (
    <div className="row dark-2 rounded-lg py-3 mb-3 mx-0">
      <div className="col-12">
        {activeDealDetails.baseAmount !== 0 ? (
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12 text-center pb-1"
                style={{
                  fontColor: "white",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Active Deals
              </div>
            </div>

            <div className="row">
              <div className="col-5  dark-2 pt-2 rounded-lg mx-auto my-2">
                <div className="row">
                  <span className="pl-3" style={{ color: "gray" }}>
                    Entry Price :
                  </span>
                  <div className="col-12 font-weight-bold  h6 px-0 text-center">
                    {activeDealDetails.averageBuy}{" "}
                    {props.marketData
                      ? props.marketData.quoteAsset.toUpperCase()
                      : "USDT"}
                  </div>
                </div>
              </div>
              <div className="col-5  dark-2 pt-2 rounded-lg mx-auto my-2">
                <div className="row">
                  <span className="pl-3" style={{ color: "gray" }}>
                    Current Price :
                  </span>
                  <div
                    className="col-12  h6 font-weight-bold  px-0 text-center"
                    style={{
                      color:
                        (activeDealDetails.baseAmount * currencyPrice -
                          activeDealDetails.quoteAmount <
                          0 &&
                          activeDealDetails.baseAmount > 0) ||
                        (activeDealDetails.baseAmount * currencyPrice -
                          activeDealDetails.quoteAmount <
                          0 &&
                          activeDealDetails.baseAmount < 0)
                          ? "#ff3333"
                          : "green",
                    }}
                  >
                    {currencyPrice}{" "}
                    {props.marketData
                      ? props.marketData.quoteAsset.toUpperCase()
                      : "USDT"}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-5  dark-2 pt-2 rounded-lg mx-auto my-2">
                <div className="row">
                  <span className="pl-3" style={{ color: "gray" }}>
                    Trade Amount :
                  </span>
                  <div className="col-12  h6 font-weight-bold px-0 text-center">
                    {parseFloat(
                      activeDealDetails.quoteAmount.toFixed(
                        props.marketData &&
                          props.marketData.quoteAsset === "btc"
                          ? 8
                          : 2
                      )
                    )}{" "}
                    {props.marketData
                      ? props.marketData.quoteAsset.toUpperCase()
                      : "USDT"}
                  </div>
                </div>
              </div>
              <div className="col-5  dark-2 pt-2 rounded-lg mx-auto my-2">
                <div className="row  ">
                  <span className="my-tooltip pl-3" style={{ color: "gray" }}>
                    {" "}
                    Unrealized PnL :
                  </span>
                  {currencyPrice > 0 ? (
                    <div
                      className="col-12 h6 font-weight-bold px-0 text-center"
                      style={{
                        color:
                          (activeDealDetails.baseAmount * currencyPrice -
                            activeDealDetails.quoteAmount <
                            0 &&
                            activeDealDetails.baseAmount > 0) ||
                          (activeDealDetails.baseAmount * currencyPrice -
                            activeDealDetails.quoteAmount <
                            0 &&
                            activeDealDetails.baseAmount < 0)
                            ? "#ff3333"
                            : "green",
                      }}
                    >
                      $
                      {parseFloat(
                        (
                          (activeDealDetails.baseAmount * currencyPrice -
                            activeDealDetails.quoteAmount) *
                          quoteAssetPrice
                        ).toFixed(2)
                      )}{" "}
                      (
                      {(
                        ((activeDealDetails.baseAmount < 0
                          ? activeDealDetails.averageBuy - currencyPrice
                          : currencyPrice - activeDealDetails.averageBuy) *
                          100) /
                        activeDealDetails.averageBuy
                      ).toFixed(2)}
                      %)
                    </div>
                  ) : (
                    <div
                      className="col-12 h6 font-weight-bold px-0 text-center"
                      style={{
                        color: "green",
                      }}
                    >
                      $0 ( 0% )
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12 text-center pb-1"
                style={{
                  fontColor: "white",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Active Deals
              </div>
            </div>

            <div className="row">
              <div className="col-11  dark-2 py-2 rounded-lg mx-auto my-2 text-center">
                No Active Deals.
                <br />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AvtiveDealDetails;
