import React, { useEffect, useState } from "react";
import { Context } from "../../../backend/context";
import firebase from "firebase/compat/app";
import Bytedex from "../../../backend/bytedex";

function TickerBotSettings(props) {
  const { marketDataAll, exchanges, exchangesById } = React.useContext(Context);
  const [isActive, setActive] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [orderInterval, setOrderInterval] = useState(0);
  const [orderSize, setOrderSize] = useState(0);
  const [maxOrderSize, setMaxOrderSize] = useState(5);
  const [name, setName] = useState("");
  const [account, setAccount] = useState(props.bot.account);
  const [accountEx, setAccountEx] = useState("");
  const [takerAccount, setTakerAccount] = useState(props.bot.accountTaker);
  const [accountExTaker, setAccountExTaker] = useState("");
  const [secondEx, setSecondEx] = useState("binance");
  const [marketData, setMarketData] = useState({
    symbol: "btc_usdt",
    symbolOpp: "usdt_btc",
    baseAsset: "btc",
    quoteAsset: "usdt",
    step: 0.1,
    tickDigits: 10,
    minOrderSizeBase: 0.0005,
    minOrderSizeQuote: 10,
  });

  const [currencyPrice, setCurrencyPrice] = useState(0);
  const [alert, setAlert] = useState(null);

  const [assetBits, setAssetBits] = useState({
    base: 1000000,
    quote: 100,
  });

  useEffect(() => {
    setAccountEx(exchangesById[account] ? exchangesById[account].exchange : "");
    setAccountExTaker(
      exchangesById[takerAccount] ? exchangesById[takerAccount].exchange : ""
    );
  }, [account, takerAccount, exchangesById]);

  async function getTicker() {
    var ticker = await Bytedex.getTicker(marketData.symbol.toUpperCase());

    if (ticker && ticker.last_price) {
      setCurrencyPrice(ticker.last_price);
    }
  }
  useEffect(() => {
    if (isEditing) {
      getTicker();
    }
  }, [marketData, props.bot, isEditing]);
  useEffect(() => {
    if (currencyPrice && marketData.symbol) {
      var assetBitsQuote = {
        usdt: 100,
        btc: 1000000,
        try: 100,
        usdc: 100,
        dai: 100,
        eur: 100,
        bext: 1,
      };
      if (
        assetBits.base !==
          10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ) ||
        assetBits.quote !== assetBitsQuote[marketData.quoteAsset]
      ) {
        // console.log("updated assets");
        setAssetBits({
          base:
            10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ),
          quote: assetBitsQuote[marketData.quoteAsset],
        });
      }
    }
  }, [currencyPrice, marketData, assetBits]);

  useEffect(() => {
    if (isEditing) {
      if (
        orderSize > 0 &&
        orderSize <
          Math.ceil(
            Math.max(
              marketData.minOrderSizeQuote,
              marketData.minOrderSizeBase * currencyPrice * 2
            ) * assetBits.quote
          ) /
            assetBits.quote
      ) {
        setAlert(
          "Minimum Order Size is " +
            Math.ceil(
              Math.max(
                marketData.minOrderSizeQuote,
                marketData.minOrderSizeBase * currencyPrice * 2
              ) * assetBits.quote
            ) /
              assetBits.quote +
            " " +
            marketData.quoteAsset.toUpperCase()
        );
      } else {
        if (account) {
          if (takerAccount) {
            if (accountEx === "bytedex") {
              if (accountExTaker === "bytedex") {
                if (!marketData) {
                  setAlert("Unsupported Market !");
                } else if (account === takerAccount) {
                  setAlert(
                    "Maker Account & Taker Account should be different !"
                  );
                } else {
                  setAlert(null);
                }
              } else {
                setAlert("Unsupported Taker exchange's account !");
              }
            } else {
              setAlert("Unsupported Maker exchange's account !");
            }
          } else {
            setAlert("Please connect Taker exchange !");
          }
        } else {
          setAlert("Please connect Maker exchange !");
        }
      }
    }
  }, [
    orderSize,
    isEditing,
    marketData,
    currencyPrice,
    assetBits,
    account,
    accountEx,
    maxOrderSize,
  ]);

  useEffect(() => {
    if (props.bot.id) {
      setName(props.bot.name);
      setOrderSize(props.bot.orderSize);
      setMaxOrderSize(props.bot.maxOrderSize);
      setSecondEx(props.bot.secondEx);
      setActive(props.bot.isRunning);
      setOrderInterval(props.bot.orderInterval ? props.bot.orderInterval : 30);

      var symbolD =
        marketDataAll && marketDataAll[props.bot.exchange]
          ? marketDataAll[props.bot.exchange][props.bot.market]
          : null;

      if (symbolD) {
        setMarketData(symbolD);
      }
    }
  }, [props.bot, marketDataAll]);

  const reset = () => {
    if (props.bot.id) {
      setName(props.bot.name);
      setOrderSize(props.bot.orderSize);
      setMaxOrderSize(props.bot.maxOrderSize);
      setSecondEx(props.bot.secondEx);
      setOrderInterval(props.bot.orderInterval ? props.bot.orderInterval : 30);
    }
  };

  const handleSaveBot = (e) => {
    e.preventDefault();
    if (accountEx === "bytedex") {
      setEditing(false);
      //save bot
      let data = {
        ...props.bot,
        account: account,
        accountTaker: takerAccount,
        exchange: accountEx,
        name: name,
        orderSize: parseFloat(orderSize),
        maxOrderSize: parseFloat(maxOrderSize),
        orderInterval: parseFloat(orderInterval),
        secondEx: secondEx ? secondEx : "no",
      };
      firebase
        .firestore()
        .collection("bots")
        .doc(props.bot.id)
        .update(data)
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAlert("Unsupported exchange's account !");
    }
  };

  return (
    <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 text-center pb-2"
              style={{
                fontColor: "white",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Bot Settings
            </div>
          </div>

          <div className="row justify-content-around">
            <div className="col-12 px-3 rounded-lg dark-2 pt-3">
              <form onSubmit={handleSaveBot}>
                <div className="row">
                  {alert && (
                    <div className="col-12">
                      <div className="row">
                        <div className="col-10 m-auto">
                          <div
                            className="alert alert-warning py-1 text-center"
                            style={{
                              backgroundColor: "rgb(255, 204, 0,0.1)",
                              color: "yellow",
                            }}
                          >
                            {alert}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12 text-left m-auto">
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                        Maker Account :
                      </label>
                      <div className="col-12 col-sm-4  text-center">
                        <select
                          disabled={isActive || !isEditing}
                          required
                          onChange={(e) => {
                            setAccount(e.target.value);
                          }}
                          className="custom-select mr-sm-2 form-control"
                          defaultValue={props.bot.account}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {" "}
                          {!exchangesById[account] && (
                            <option key="deleted" value=""></option>
                          )}
                          {exchanges.map((item, key) => {
                            return (
                              <option key={key} value={item.id}>
                                {item.name + " ( " + item.exchange + " )"}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                        Taker Account :
                      </label>
                      <div className="col-12 col-sm-4  text-center">
                        <select
                          disabled={isActive || !isEditing}
                          required
                          onChange={(e) => {
                            setTakerAccount(e.target.value);
                          }}
                          className="custom-select mr-sm-2 form-control"
                          defaultValue={props.bot.accountTaker}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {" "}
                          {!exchangesById[takerAccount] && (
                            <option key="deleted" value=""></option>
                          )}
                          {exchanges.map((item, key) => {
                            return (
                              <option key={key} value={item.id}>
                                {item.name + " ( " + item.exchange + " )"}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row  my-3">
                      <label className="col-12 col-sm-2  col-form-label pl-3  pb-1  pt-sm-0 pt-2  verticalCenter">
                        Market :
                      </label>
                      <div className="col-12 col-sm-4">
                        <select
                          disabled
                          required
                          className="custom-select mr-sm-2 form-control"
                          value={props.bot.market}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <option value={props.bot.market}>
                            {props.bot.market.toUpperCase()}
                          </option>
                        </select>
                      </div>

                      <label className="col-12 col-sm-2  col-form-label pl-3  pb-1  pt-sm-0 pt-2  verticalCenter">
                        Price Source :
                      </label>
                      <div className="col-12 col-sm-4">
                        <select
                          disabled={!isEditing}
                          required
                          onChange={(e) => {
                            setSecondEx(e.target.value);
                          }}
                          className="custom-select mr-sm-2 form-control"
                          value={secondEx}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <option value="no">Not Available</option>{" "}
                          <option value="kucoin">Kucoin</option>
                          <option value="gateio">Gateio</option>
                          <option value="binance">Binance</option>
                          <option value="mexc">Mexc</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Min Order Size :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={
                            Math.ceil(
                              Math.max(
                                marketData.minOrderSizeQuote,
                                marketData.minOrderSizeBase * currencyPrice * 2
                              ) * assetBits.quote
                            ) / assetBits.quote || 0
                          }
                          className="form-control"
                          required
                          value={orderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Max Order Size :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMaxOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={
                            Math.ceil(
                              Math.max(
                                marketData.minOrderSizeQuote,
                                marketData.minOrderSizeBase * currencyPrice * 2
                              ) * assetBits.quote
                            ) / assetBits.quote || 0
                          }
                          className="form-control"
                          required
                          value={maxOrderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    {/* <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Order Interval <span className="px-1"> (Seconds) </span>{" "}
                        :
                      </label>
                      <div className="col-12 col-sm-3">
                        <input
                          onChange={(e) =>
                            setOrderInterval(parseFloat(e.target.value))
                          }
                          type="number"
                          step="1"
                          min="1"
                          className="form-control"
                          required
                          value={orderInterval}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                    </div> */}

                    {isEditing && (
                      <React.Fragment>
                        <div className="form-group row  my-3">
                          <div className="col-6 text-center m-auto">
                            <button
                              type="text"
                              className="btn btn-dark btn-sm  w-75"
                              onClick={() => {
                                setEditing(false);
                                reset();
                              }}
                            >
                              Back
                            </button>
                          </div>

                          <div className="col-6 text-center m-auto">
                            <button
                              disabled={alert && alert.length > 0}
                              type="submit"
                              className="btn btn-success btn-sm  w-75"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </form>
              {!isEditing && (
                <div className="form-group row  mt-2 mb-3">
                  <div className="col-6 text-center m-auto">
                    <button
                      disabled={props.bot.isPending}
                      type="text"
                      className="btn btn-dark btn-sm  w-75"
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TickerBotSettings;
