import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import CreateMarketMakerBot from "./createBot";
import MarketMakerBotList from "./botsList";

function MarketMakerBots(props) {
  const [isCreatingBot, setCreatingBot] = useState(props.location.isCreateBot);

  return (
    <div className="dark-1 container-fluid">
      <div className="row ">
        <div className="col-12 h4 ">
          <FontAwesomeIcon icon={faRobot} size="1x" color="#d9d9d9" />
          <span className="pl-2">Market Maker Bot</span>
        </div>
      </div>
      {isCreatingBot ? (
        <CreateMarketMakerBot setCreatingBot={setCreatingBot} />
      ) : (
        <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
          <div className="col-11 col-sm-10 mx-auto my-3">
            <button
              className="btn btn-dark px-5 "
              style={{
                borderRadius: "30px",
              }}
              onClick={() => setCreatingBot(true)}
            >
              Create Bot
            </button>
          </div>
        </div>
      )}
      <MarketMakerBotList />
    </div>
  );
}

export default MarketMakerBots;
