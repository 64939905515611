import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faThLarge,
  faExchangeAlt,
  faCog,
  faRobot,
  faChevronRight,
  faCaretSquareLeft,
} from "@fortawesome/free-solid-svg-icons";

import { Link, useLocation } from "react-router-dom";
import { Context } from "../backend/context";

function Sidebar() {
  const { signOut, screenWidth } = React.useContext(Context);
  const [sidebarOpen, setSidebar] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/account") {
      setSelectedTab(0);
    } else if (pathname === "/account/exchanges") {
      setSelectedTab(1);
    } else if (pathname === "/account/hedgeBots") {
      setSelectedTab(3);
    } else if (pathname === "/account/settings") {
      setSelectedTab(4);
    } else if (pathname === "/account/tickerBots") {
      setSelectedTab(5);
    } else if (pathname === "/account/mmBots") {
      setSelectedTab(7);
    } else {
      setSelectedTab(6);
    }
  }, [pathname]);

  const signOutHandler = () => {
    if (window.confirm(`Confirm Log Out ?`)) {
      signOut();
    }
  };

  function openNav() {
    document.getElementById("sidenav").style.width = "160px";
    setSidebar(true);
    // document.addEventListener("click", onClickOutsideListener, true);
    // console.log("open");
  }

  function closeNav() {
    setSidebar(false);
    document.getElementById("sidenav").style.width = "2.3rem";
    // document.removeEventListener("click", onClickOutsideListener, true);
    // console.log(screenWidth);
  }

  return (
    <div
      id="sidenav"
      className="rounded-lg"
      style={{
        width: screenWidth < 600 ? "2.3rem" : "160px",
        backgroundColor: "#240c41",
        zIndex: 1,
        position: "fixed",
        overflowY: "auto",
        overflowX: "hidden",
        transition: "0.5s",
        maxHeight: `${window.innerHeight - 50}px`,
        marginTop: screenWidth < 600 ? "3rem" : "0.8rem",
        marginLeft: screenWidth < 600 ? "-0.5rem" : 0,
      }}
    >
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12 p-0 m-0 pb-1 text-right">
            {screenWidth < 600 && (
              <button
                type="button"
                className="pl-3"
                style={{
                  background: "none",
                  borderStyle: "none",
                }}
                onClick={sidebarOpen ? () => closeNav() : () => openNav()}
              >
                <FontAwesomeIcon
                  icon={sidebarOpen ? faCaretSquareLeft : faChevronRight}
                  size="2x"
                  color="#d9d9d9"
                />
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0 m-0">
            <Link
              className={`${
                selectedTab === 0 ? "sidebarItem-active" : "sidebarItem"
              } w-100 text-nowrap`}
              to="/account"
            >
              <FontAwesomeIcon icon={faThLarge} size="1x" color="#d9d9d9" />
              <span className="pl-2">Dashboard</span>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0 m-0">
            <Link
              className={`${
                selectedTab === 1 ? "sidebarItem-active" : "sidebarItem"
              } w-100 text-nowrap`}
              to="/account/exchanges"
            >
              <FontAwesomeIcon icon={faExchangeAlt} size="1x" color="#d9d9d9" />
              <span className="pl-2 w-100">My Accounts</span>{" "}
            </Link>
          </div>
        </div>
        <hr
          style={{
            border: "none",
            height: "2px",
            color: "black",
            backgroundColor: "black",
            margin: "0.5rem 0 0.5rem 0",
          }}
        />
        <div className="row">
          <div className="col-12 p-0 m-0">
            <Link
              className={`${
                selectedTab === 5 ? "sidebarItem-active" : "sidebarItem"
              } w-100 text-nowrap`}
              to="/account/tickerBots"
            >
              <FontAwesomeIcon
                icon={faRobot}
                size="1x"
                color="#d9d9d9"
                className="mr-1"
              />
              <span className="pl-2">Ticker Bots</span>{" "}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0 m-0">
            <Link
              className={`${
                selectedTab === 7 ? "sidebarItem-active" : "sidebarItem"
              } w-100 text-nowrap`}
              to="/account/mmBots"
            >
              <FontAwesomeIcon
                icon={faRobot}
                size="1x"
                color="#d9d9d9"
                className="mr-1"
              />
              <span className="pl-2">MM Bots</span>{" "}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0 m-0">
            <Link
              className={`${
                selectedTab === 3 ? "sidebarItem-active" : "sidebarItem"
              } w-100 text-nowrap`}
              to="/account/hedgeBots"
            >
              <FontAwesomeIcon
                icon={faRobot}
                size="1x"
                color="#d9d9d9"
                className="mr-1"
              />
              <span className="pl-2">Hedge Bots</span>{" "}
            </Link>
          </div>
        </div>
        <hr
          style={{
            border: "none",
            height: "2px",
            color: "black",
            backgroundColor: "black",
            margin: "0.5rem 0 0.5rem ",
          }}
        />{" "}
        <div className="row">
          <div className="col-12  p-0 m-0">
            <Link
              className={`${
                selectedTab === 4 ? "sidebarItem-active" : "sidebarItem"
              } w-100 text-nowrap`}
              to="/account/settings"
            >
              <FontAwesomeIcon icon={faCog} size="1x" color="#d9d9d9" />
              <span className="pl-2">Settings</span>{" "}
            </Link>
          </div>
        </div>{" "}
        <hr
          style={{
            border: "none",
            height: "2px",
            color: "black",
            backgroundColor: "black",
            margin: "0.5rem 0 0.5rem ",
          }}
        />
        <div className="row pb-3">
          <div className="col-12 m-0 p-0">
            <button
              className="w-100 sidebarItem  text-nowrap"
              onClick={() => signOutHandler()}
            >
              <FontAwesomeIcon
                icon={faSignOutAlt}
                size="1x"
                color="rgb(255, 51, 51,1)"
              />
              <span className="pl-2" style={{ color: "rgb(255, 51, 51,1)" }}>
                LOGOUT
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
