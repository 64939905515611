import { SET_VOLUME_PROFIT } from "./types";
export const setVolumeNprofit = (data) => {
  // console.log("totalVolume", totalVolume);

  return (dispatch) => {
    dispatch({
      type: SET_VOLUME_PROFIT,
      payload: {
        data: data.data,
        userTodayData: data.userTodayData,
        userWeekData: data.userWeekData,
        userMonthData: data.userMonthData,
        userAllData: data.userAllData,
      },
    });
  };
};
