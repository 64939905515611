import React, { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import { connect } from "react-redux";

var dataLoc = {
  0: 0,
  2: 0,
  4: 0,
  6: 0,
  8: 0,
};

function SummeryGraph(props) {
  const [chartType, setChartType] = useState(1);
  const [chartRange, setChartRange] = useState("week");
  const [chartData, setChartData] = useState({
    labels: Object.keys(dataLoc),
    datasets: [
      {
        label: "Balance",

        fill: true,
        backgroundColor: "rgba(102, 179, 255,0.2)",

        pointRadius: 4,
        pointHitRadius: 2,
        pointBackgroundColor: "black",

        borderColor: "black",
        // fill: false,
        // borderWidth: 1,
        // hoverBackgroundColor: "black",
        // hoverBorderColor: "white",
        data: Object.keys(dataLoc).map(function (key) {
          return dataLoc[key].toFixed(2);
        }),
      },
    ],
  });
  const updateChartData = (data, label) => {
    if (data) {
      setChartData({
        labels: Object.keys(data),
        datasets: [
          {
            label: label,

            fill: true,
            backgroundColor: "rgba(102, 179, 255,0.2)",

            pointRadius: 4,
            pointHitRadius: 2,
            pointBackgroundColor: "black",

            borderColor: "black",
            data: Object.keys(data).map(function (key) {
              return data[key].toFixed(2);
            }),
          },
        ],
      });
    }
  };
  useEffect(() => {
    if (chartType === 1) {
      if (chartRange === "today" && props.userTodayData) {
        updateChartData(props.userTodayData.profit, "Profit");
      } else if (chartRange === "week" && props.userWeekData) {
        updateChartData(props.userWeekData.profit, "Profit");
      } else if (chartRange === "month" && props.userMonthData) {
        updateChartData(props.userMonthData.profit, "Profit");
      } else if (chartRange === "all" && props.userAllData) {
        updateChartData(props.userAllData.profit, "Profit");
      } else {
        updateChartData(dataLoc);
      }
    } else if (chartType === 2) {
      if (chartRange === "today" && props.userTodayData) {
        updateChartData(props.userTodayData.volume, "Volume");
      } else if (chartRange === "week" && props.userWeekData) {
        updateChartData(props.userWeekData.volume, "Volume");
      } else if (chartRange === "month" && props.userMonthData) {
        updateChartData(props.userMonthData.volume, "Volume");
      } else if (chartRange === "all" && props.userAllData) {
        updateChartData(props.userAllData.volume, "Volume");
      } else {
        updateChartData(dataLoc);
      }
    } else {
      updateChartData(dataLoc);
    }
  }, [
    props.userTodayData,
    props.userWeekData,
    props.userAllData,
    props.userMonthData,
    chartType,
    chartRange,
  ]);
  return (
    <div className="dark-1 h-100 overflow-scroll container-fluid rounded-lg  border border-dark ">
      <div className="row no-gutters">
        <div className="col-8 text-left py-3">
          <div className="row text-center no-gutters border border-dark">
            <div className="col-6 border-dark border-left border-right  ">
              <button
                className={`py-1 w-100 ${
                  chartType === 1 ? "gray-btn-active" : "gray-btn"
                }`}
                onClick={() => setChartType(1)}
              >
                Profit
              </button>
            </div>
            <div className="col-6  ">
              <button
                className={`py-1 w-100 ${
                  chartType === 2 ? "gray-btn-active" : "gray-btn"
                }`}
                onClick={() => setChartType(2)}
              >
                Volume
              </button>
            </div>
          </div>
        </div>
        <div
          className="col-4 text-right py-3"
          style={{
            backgroundColor: "#0f051b",
          }}
        >
          <select
            className=" border boder-dark rounded px-1 py-1 "
            onChange={(e) => {
              setChartRange(e.target.value);
            }}
            value={chartRange}
            style={{
              backgroundColor: "#0f051b",
              color: "#bfbfbf",
              background: "none",
            }}
          >
            <option
              style={{ backgroundColor: "#0f051b", color: "#bfbfbf" }}
              value="today"
            >
              Today
            </option>
            <option
              style={{ backgroundColor: "#0f051b", color: "#bfbfbf" }}
              value="week"
            >
              Week
            </option>
            <option
              style={{ backgroundColor: "#0f051b", color: "#bfbfbf" }}
              value="month"
            >
              Month
            </option>
            <option
              style={{ backgroundColor: "#0f051b", color: "#bfbfbf" }}
              value="all"
            >
              All
            </option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-12 py-3">
          <div className="row">
            <div className="col-12">
              <Line
                data={chartData}
                // width={100}
                height={250}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  tooltips: {
                    fontColor: "#d7d9da",
                    callbacks: {
                      label: function (tooltipItem, chart) {
                        var datasetLabel =
                          chart.datasets[tooltipItem.datasetIndex].label || "";
                        return datasetLabel + ": $" + tooltipItem.yLabel;
                      },
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: chartType !== 0,
                          fontColor: "#d7d9da",
                          callback: function (value, index, values) {
                            return "$" + value;
                          },
                        },

                        gridLines: {
                          color: "black",
                          lineWidth: 1,
                          zeroLineColor: "black",
                        },
                      },
                    ],
                    xAxes: [
                      {
                        ticks: {
                          fontColor: "#d7d9da",
                        },

                        gridLines: {
                          color: "black",
                          lineWidth: 1,
                          zeroLineColor: "black",
                        },
                      },
                    ],
                  },
                  legend: {
                    display: true,
                    labels: {
                      // This more specific font property overrides the global property
                      fontColor: "#d7d9da",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userTodayData: state.volumeNprofit.userTodayData,
  userWeekData: state.volumeNprofit.userWeekData,
  userAllData: state.volumeNprofit.userAllData,
  userMonthData: state.volumeNprofit.userMonthData,
});

export default connect(mapStateToProps)(SummeryGraph);
