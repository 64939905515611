import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Context } from "../../backend/context";
import Bytedex from "../../backend/bytedex";

function ActiveDealsList(props) {
  const { getMarketData } = React.useContext(Context);
  const [botList, setbotList] = useState([]);
  const [pnLTotal, setpnlTotal] = useState(0);

  async function setData(allBots, activeDeals) {
    var lisst = [];
    var btcPrice = 0;
    var tryPrice = 0;
    var bextPrice = 0;
    var eurPrice = 0;
    var daiPrice = 0;
    var usdcPrice = 0;
    var totalPnL = 0;

    var tickersData = {};
    var tickers = await Bytedex.getAllTickers();

    if (tickers) {
      tickersData = tickers;

      if (tickersData["BTC_USDT"]) {
        btcPrice = tickersData["BTC_USDT"].last_price;
      }
      if (tickersData["USDT_TRY"]) {
        tryPrice = 1 / tickersData["USDT_TRY"].last_price;
      }
      if (tickersData["USDT_DAI"]) {
        daiPrice = 1 / tickersData["USDT_DAI"].last_price;
      }
      if (tickersData["BEXT_USDT"]) {
        bextPrice = tickersData["BEXT_USDT"].last_price;
      }
      if (tickersData["USDC_USDT"]) {
        usdcPrice = tickersData["USDC_USDT"].last_price;
      }
      if (tickersData["EUR_USDT"]) {
        eurPrice = tickersData["EUR_USDT"].last_price;
      }
    }

    for (const key in allBots) {
      const element = allBots[key];

      var activeDealDetails = activeDeals[element.id];

      if (
        element &&
        activeDealDetails &&
        activeDealDetails.baseAmount &&
        element.isRunning
      ) {
        var marketData = getMarketData(element);

        if (marketData) {
          var avBuy = marketData
            ? Math.floor(
                (activeDealDetails.quoteAmount / activeDealDetails.baseAmount +
                  Number.EPSILON) *
                  marketData.tickDigits
              ) / marketData.tickDigits
            : 0;

          var currentPrice = marketData
            ? tickersData[element.market.toUpperCase()]
              ? tickersData[element.market.toUpperCase()].last_price
              : 0
            : 0;

          var pnl = parseFloat(
            (
              (activeDealDetails.baseAmount * currentPrice -
                activeDealDetails.quoteAmount) *
              (marketData && marketData.quoteAsset === "btc"
                ? btcPrice
                : marketData && marketData.quoteAsset === "try"
                ? tryPrice
                : marketData && marketData.quoteAsset === "bext"
                ? bextPrice
                : marketData && marketData.quoteAsset === "usdc"
                ? usdcPrice
                : marketData && marketData.quoteAsset === "dai"
                ? daiPrice
                : marketData && marketData.quoteAsset === "eur"
                ? eurPrice
                : 1)
            ).toFixed(2)
          );

          var pnlPre = (
            ((activeDealDetails.baseAmount < 0
              ? avBuy - currentPrice
              : currentPrice - avBuy) *
              100) /
            avBuy
          ).toFixed(2);

          lisst.push({
            ...element,
            ...activeDealDetails,
            entryPrice: avBuy,
            currentPrice: currentPrice,
            quoteAmount: parseFloat(
              activeDealDetails.quoteAmount.toFixed(
                marketData && marketData.quoteAsset === "btc" ? 8 : 2
              )
            ),
            pnl: pnl,
            pnlPre: pnlPre,
          });

          totalPnL += pnl;
        }
      }
    }

    setbotList(lisst);
    setpnlTotal(parseFloat(totalPnL.toFixed(2)));
  }

  useEffect(() => {
    if (props.allBots && props.activeDeals) {
      setData(props.allBots, props.activeDeals);
    }
  }, [props.allBots, props.activeDeals]);

  return (
    <div className="container-fluid rounded-lg  border border-dark ">
      <div className="row px-3">
        <div className="col-12 p-3 ">
          <div className="row">
            <div className="col-12 text-left verticalCenter h5 px-0">
              Active Deals
            </div>
          </div>

          <div className="row">
            <div className="col-12" style={{ overflowX: "auto" }}>
              <div
                style={{ minWidth: "30rem", fontWeight: "bold" }}
                className="row  text-center  overflow-hidden py-2 dark-2 border-bottom border-dark verticalCenter mt-2"
              >
                <div className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark ">
                  Market
                </div>
                <div className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark ">
                  Entry Price
                </div>{" "}
                <div className="col-2 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark ">
                  Current Price
                </div>{" "}
                <div className="col-3 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark ">
                  Trade Amount
                </div>{" "}
                <div className="col-3  px-0 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark ">
                  Unrealized PnL
                </div>
              </div>
              {botList.length > 0 ? (
                botList.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      style={{ textDecoration: "none" }}
                      to={`/account/${
                        item.type === "TICKER"
                          ? "tickerBots"
                          : item.type === "MARKET-MAKER"
                          ? "mmBots"
                          : "hedgeBots"
                      }/${item.id}`}
                    >
                      <div
                        style={{ minWidth: "30rem" }}
                        className="row  border-dark border-left border-bottom text-center bot-details-row"
                      >
                        <div className="col-2 border-right border-dark py-2 px-0 overflow-hidden">
                          {item.market.toUpperCase()}
                        </div>
                        <div className="col-2 border-right border-dark py-2 overflow-hidden">
                          {item.entryPrice}
                        </div>{" "}
                        <div className="col-2 border-right border-dark py-2 overflow-hidden">
                          {item.currentPrice}
                        </div>{" "}
                        <div className="col-3 border-right border-dark py-2 overflow-hidden">
                          {item.quoteAmount}
                          <small>
                            {" "}
                            {getMarketData(item) &&
                              getMarketData(item).quoteAsset.toUpperCase()}
                          </small>
                        </div>
                        <div className="col-3  px-0 text-nowrap  border-right border-dark py-2 overflow-hidden">
                          {item.currentPrice > 0 ? (
                            <div
                              className="col-12  px-0 text-center"
                              style={{
                                color:
                                  (item.baseAmount * item.currentPrice -
                                    item.quoteAmount <
                                    0 &&
                                    item.baseAmount > 0) ||
                                  (item.baseAmount * item.currentPrice -
                                    item.quoteAmount <
                                    0 &&
                                    item.baseAmount < 0)
                                    ? "#ff3333"
                                    : "green",
                              }}
                            >
                              $ {item.pnl} ({item.pnlPre}
                              %)
                            </div>
                          ) : (
                            <div
                              className="col-12  px-0 text-center"
                              style={{
                                color: "green",
                              }}
                            >
                              0 ( 0% )
                            </div>
                          )}
                        </div>{" "}
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div
                  style={{ minWidth: "30rem" }}
                  className="row  border-dark border-left border-bottom text-center bot-details-row"
                >
                  <div className="col-12 border-right border-dark py-2">
                    No Active Deals
                  </div>
                </div>
              )}
              <div
                style={{ minWidth: "30rem" }}
                className="row  border-dark border-left border-bottom text-center bot-details-row"
              >
                <div className="col-6 border-right border-dark py-2"></div>
                <div className="col-3 border-right border-dark py-2">
                  Total :
                </div>
                <div className="col-3 border-right border-dark py-2">
                  <div
                    className="  px-0 text-center"
                    style={{
                      color: pnLTotal < 0 ? "#ff3333" : "green",
                    }}
                  >
                    $ {pnLTotal}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  allBots: state.bots.allBots,
  activeDeals: state.activeDeals.data,
});

export default connect(mapStateToProps)(ActiveDealsList);
