import { SET_BOTS } from "../actions/types";

const initialState = {
  hedgeBots: [],
  tickerBots: [],
  marketMakerBots: [],
  activeHedgeBots: [],
  activeTickerBots: [],
  activeMarketMakerBots: [],
  allBots: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOTS:
      return {
        ...state,
        hedgeBots: action.payload.hedgeBots,
        tickerBots: action.payload.tickerBots,
        marketMakerBots: action.payload.marketMakerBots,
        activeHedgeBots: action.payload.activeHedgeBots,
        activeTickerBots: action.payload.activeTickerBots,
        activeMarketMakerBots: action.payload.activeMarketMakerBots,
        allBots: action.payload.allBots,
      };

    default:
      return state;
  }
};

export default reducer;
