import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Context } from "../../backend/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase/compat/app";

function Auth(props) {
  const { authError, emailSignIn, isLoggedIn } = React.useContext(Context);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isResetPassword, setResetPassword] = useState(false);
  const [isResetPasswordLinkSent, setResetPasswordLinkSent] = useState(false);

  const handleAuth = async (e) => {
    e.preventDefault();

    emailSignIn(email, password);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        console.log("recover email sent");
        setResetPasswordLinkSent(true);
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      setEmail("");
      setPassword("");
      if (document.getElementById("closeAuthPopup")) {
        document.getElementById("closeAuthPopup").click();
      }
    }
  }, [isLoggedIn]);

  return (
    <div className=" text-center" style={{ backgroundColor: "#0d0d0d" }}>
      {isLoggedIn ? <Redirect to="/account" /> : null}

      {isResetPassword ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center card-title h3 ">
              Reset password
            </div>{" "}
          </div>
          {!isResetPasswordLinkSent && (
            <div className="row ">
              <div className="col-12 text-center">
                Please enter the e-mail address associated with your User
                account
              </div>
            </div>
          )}
          <div className="row">
            {" "}
            <div className="col-12">
              <form onSubmit={handleForgotPassword}>
                {!isResetPasswordLinkSent ? (
                  <React.Fragment>
                    <div className="input-group form-group my-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text center"
                          style={{ minWidth: "2.8rem" }}
                        >
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            size="1x"
                            color="gray"
                          />
                        </span>
                      </div>
                      <input
                        type="email"
                        required
                        className="form-control "
                        placeholder="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div
                      id="recaptcha"
                      className="text-center center py-2 w-100 h-100"
                    ></div>
                  </React.Fragment>
                ) : (
                  <div className="py-5">
                    We have sent you an email with instructions to reset your
                    password
                  </div>
                )}

                <div className="form-group m-auto">
                  <div className="row">
                    <div className="col-5 m-auto">
                      {" "}
                      <button
                        type="button"
                        className="btn btn-dark w-100"
                        onClick={() => {
                          setResetPassword(false);
                        }}
                      >
                        Back
                      </button>
                    </div>
                    {!isResetPasswordLinkSent && (
                      <div className="col-5 m-auto">
                        <button type="submit" className="btn btn-primary w-100">
                          Confirm
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>{" "}
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center card-title h3 ">Sign In</div>

            <div className="col-12">
              {authError && (
                <div className="row py-3">
                  <div className="col-12">
                    <div className="alert alert-danger m-auto py-1">
                      {authError}
                    </div>
                  </div>
                </div>
              )}
              <form onSubmit={handleAuth}>
                <React.Fragment>
                  <div className="input-group form-group my-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text center"
                        style={{ minWidth: "2.8rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          size="1x"
                          color="gray"
                        />
                      </span>
                    </div>
                    <input
                      type="email"
                      required
                      className="form-control "
                      placeholder="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-group form-group mt-3 mb-2">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text center"
                        style={{ minWidth: "2.8rem" }}
                      >
                        <FontAwesomeIcon icon={faKey} size="1x" color="gray" />
                      </span>
                    </div>
                    <input
                      type="password"
                      required
                      className="form-control"
                      placeholder="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </React.Fragment>

                <div className="form-group m-auto">
                  <button type="submit" className="btn btn-dark w-50 mt-2 mb-3">
                    {"Sign In"}
                  </button>
                </div>
              </form>

              <div className="row mt-2">
                <div className="col-12 text-center">
                  Forgot your password ?{" "}
                  <button
                    className="px-2 btn-link h6"
                    onClick={() => setResetPassword(true)}
                    style={{
                      cursor: "pointer",
                      background: "none",
                      border: "none",
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Auth;
