import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

import { Context } from "../../../backend/context";
import LoadingScreen from "../../botLoadingScreen";

function TickerBotSummery(props) {
  const { exchangesById } = React.useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMsg, setloadingMsg] = useState("");

  const stopActiveBot = () => {
    if (window.confirm(`Stop bot ${props.bot.displayId} ?`)) {
      var docu = firebase.firestore().collection("bots").doc(props.bot.id);
      docu.update({ isRunning: false });
    }
  };
  const startStoppedBot = () => {
    if (
      window.confirm(
        `Start Bot ${
          props.bot.name && props.bot.name.length > 0
            ? props.bot.name
            : props.bot.displayId
        } ?`
      )
    ) {
      let data = {
        ...props.bot,
        startedAt: Date.now(),
        isRunning: false,
        isPending: true,
      };
      firebase
        .firestore()
        .collection("pendingBots")
        .doc(props.bot.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });
      firebase
        .firestore()
        .collection("bots")
        .doc(props.bot.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const deleteStoppedBot = async () => {
    if (window.confirm(`Delete bot ${props.bot.displayId} ?`)) {
      firebase
        .firestore()
        .collection("bots")
        .doc(props.bot.id)
        .delete()
        .then(() => {
          console.log("bot successfully deleted!");
        })
        .catch((error) => {
          console.log(error, "Error deleted bot");
        });
      firebase.database().ref(`userNotifications/${props.bot.id}`).remove();
      firebase.database().ref(`filledOrders/${props.bot.id}`).remove();
    }
  };
  useEffect(() => {
    if (
      (props.bot.isRunning || props.bot.isPending) &&
      (!props.bot.lastRunAt || props.bot.lastRunAt < props.bot.startedAt)
    ) {
      setloadingMsg("Starting");
      setIsLoading(true);
    } else if (
      !props.bot.isRunning &&
      props.bot.lastRunAt > props.bot.lastStoppedAt
    ) {
      setloadingMsg("Stopping");
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [props.bot, props.marketData]);

  return (
    <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
      {isLoading && <LoadingScreen msg={loadingMsg} />}
      <div className="col-12 col-sm-9">
        <div className="row  px-3">
          <div className="col-12 col-sm-4 h6 dark-3 p-2 rounded-lg mt-2  verticalCenter">
            Bot ID :{" "}
            <span className="pl-3 font-weight-bold">{props.bot.displayId}</span>
          </div>
          <div className="col-12 col-sm-4 h6 dark-3 p-2 rounded-lg mx-auto mt-2  verticalCenter">
            Account :{" "}
            <span className="pl-3 font-weight-bold">
              {exchangesById[props.bot.account]
                ? exchangesById[props.bot.account].name
                : "Deleted Account"}
            </span>
          </div>
          <div className="col-12 col-sm-3 h6 dark-3 p-2 rounded-lg mx-auto mt-2  ">
            <div className="row  pl-3">
              <span style={{ color: "gray" }}>Total Investment :</span>
              <div className="col-12 h6 font-weight-bold px-0 text-center">
                {parseFloat(
                  props.bot.maxOrderSize.toFixed(
                    props.marketData &&
                      props.marketData.quoteAsset.includes("usd")
                      ? 2
                      : 6
                  )
                )}{" "}
                <small>
                  {props.marketData &&
                    props.marketData.quoteAsset.toUpperCase()}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-3 col-md-3">
        <div className="row text-center">
          <div className="col-6 col-sm-12 m-auto py-2   ">
            {props.bot.isRunning ? (
              <button
                onClick={() => stopActiveBot()}
                className="btn btn-sm btn-danger w-75 "
                disabled={props.bot.isPending}
              >
                Stop
              </button>
            ) : (
              <button
                onClick={() => startStoppedBot()}
                className="btn btn-sm btn-success w-75 "
                disabled={props.bot.isPending}
              >
                Start
              </button>
            )}
          </div>

          <div className="col-6 col-sm-12 m-auto py-2 ">
            <button
              onClick={() => deleteStoppedBot()}
              className="btn btn-sm btn-danger  w-75 "
              disabled={props.bot.isRunning || props.bot.isPending}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TickerBotSummery;
