import React from "react";

function StartBotPopup(props) {
  return (
    <div
      className="modal fade p-0"
      id="StartBotPopup"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="StartBotPopupTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered p-0 " role="document">
        <div
          className="modal-content  m-0 p-2 "
          style={{ backgroundColor: "#0d0d0d" }}
        >
          <div className="text-right ">
            <button
              type="button"
              className="close mx-2 mb-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 h4">Bot Successfully Submited</div>
              </div>
              <div className="row">
                <div className="col-12 py-3">
                  <img
                    className="footer-logo "
                    src="/img/tick.png"
                    alt="Coinner"
                    style={{ maxWidth: "6rem" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 h4">
                  <button
                    id="closeStartBotPopup"
                    data-dismiss="modal"
                    className="btn btn-sm my-1 btn-dark w-50"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartBotPopup;
