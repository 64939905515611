import React, { useEffect, useState } from "react";
import { Context } from "../../../backend/context";
import firebase from "firebase/compat/app";
import Bytedex from "../../../backend/bytedex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";

function HedgeBotSettings(props) {
  const { marketDataAll, exchanges, exchangesById } = React.useContext(Context);
  const [isActive, setActive] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [baseAssetsTotal, setBaseAssetsTotal] = useState(0);
  const [quoteAssetsTotal, setQuoteAssetsTotal] = useState(0);
  const [maxOrders, setMaxOrders] = useState(10);
  const [orderAmountShapeFactor, setOrderAmountShapeFactor] = useState(50);
  const [orderPriceShapeFactor, setOrderPriceShapeFactor] = useState(0.1);
  const [maxOrderSize, setMaxOrderSize] = useState(1);
  const [name, setName] = useState("");
  const [account, setAccount] = useState(props.bot.account);
  const [quoteRebalanceAccount, setQuoteRebalanceAccount] = useState(
    props.bot.accountQuoteRebalancer
  );
  const [accountEx, setAccountEx] = useState("");
  const [takerAccount, setTakerAccount] = useState(props.bot.accountTaker);
  const [accountExTaker, setAccountExTaker] = useState("");
  const [marketData, setMarketData] = useState({
    symbol: "btc_usdt",
    symbolOpp: "usdt_btc",
    baseAsset: "btc",
    quoteAsset: "usdt",
    step: 0.1,
    tickDigits: 10,
    minOrderSizeBase: 0.0005,
    minOrderSizeQuote: 10,
  });
  const [sourceMarket, setSourceMarket] = useState("btc_usdt");

  const [currencyPrice, setCurrencyPrice] = useState(0);
  const [alert, setAlert] = useState(null);

  const [assetBits, setAssetBits] = useState({
    base: 1000000,
    quote: 100,
  });

  const [isSyntheticToken, setSyntheticToken] = useState(false);
  const [sourceList, setSourceList] = useState([]);

  useEffect(() => {
    setAccountEx(exchangesById[account] ? exchangesById[account].exchange : "");
    setAccountExTaker(
      exchangesById[takerAccount] ? exchangesById[takerAccount].exchange : ""
    );
  }, [account, takerAccount, exchangesById]);

  async function getTicker() {
    var ticker = await Bytedex.getTicker(marketData.symbol.toUpperCase());

    if (ticker && ticker.last_price) {
      setCurrencyPrice(ticker.last_price);
    }
  }
  useEffect(() => {
    getTicker();
  }, [marketData, props.bot, isEditing]);
  useEffect(() => {
    if (currencyPrice && marketData.symbol) {
      var assetBitsQuote = {
        usdt: 100,
        btc: 1000000,
        try: 100,
        usdc: 100,
        dai: 100,
        eur: 100,
        bext: 1,
      };
      if (
        assetBits.base !==
          10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ) ||
        assetBits.quote !== assetBitsQuote[marketData.quoteAsset]
      ) {
        // console.log("updated assets");
        setAssetBits({
          base:
            10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ),
          quote: assetBitsQuote[marketData.quoteAsset],
        });
      }
    }
  }, [currencyPrice, marketData, assetBits]);

  useEffect(() => {
    if (isEditing) {
      if (account) {
        if (takerAccount || isSyntheticToken) {
          if (accountEx === "bytedex") {
            if (accountExTaker !== "bytedex" || isSyntheticToken) {
              if (!marketData) {
                setAlert("Unsupported Market !");
              } else if (
                !isSyntheticToken &&
                marketData.baseAsset !== sourceMarket.split("_")[0]
              ) {
                setAlert("Unsupported Source Market !");
              } else if (
                !isSyntheticToken &&
                marketData.quoteAsset !== sourceMarket.split("_")[1] &&
                quoteRebalanceAccount === ""
              ) {
                setAlert("Unsupported Quote Rebalance Account !");
              } else {
                setAlert(null);
              }
            } else {
              setAlert("Unsupported source exchange !");
            }
          } else {
            setAlert("Unsupported exchange's account !");
          }
        } else {
          setAlert("Please connect source exchange !");
        }
      } else {
        setAlert("Please connect Account !");
      }
    }
  }, [
    isEditing,
    marketData,
    currencyPrice,
    assetBits,
    account,
    accountEx,
    takerAccount,
    accountExTaker,
    sourceMarket,
    props.bot.market,
    quoteRebalanceAccount,
    isSyntheticToken,
  ]);

  useEffect(() => {
    if (props.bot.id) {
      setName(props.bot.name);
      setBaseAssetsTotal(props.bot.baseAssetsTotal);
      setQuoteAssetsTotal(props.bot.quoteAssetsTotal);
      setMaxOrders(props.bot.maxOrders);
      setOrderAmountShapeFactor(props.bot.orderAmountShapeFactor);
      setOrderPriceShapeFactor(props.bot.orderPriceShapeFactor);
      setMaxOrderSize(props.bot.maxOrderSize);
      setActive(props.bot.isRunning);
      setSourceMarket(
        props.bot.sourceMarket ? props.bot.sourceMarket : "btc_usdt"
      );
      setSourceList(props.bot.syntheticData ? props.bot.syntheticData : []);
      setSyntheticToken(
        props.bot.isSyntheticToken ? props.bot.isSyntheticToken : false
      );

      var symbolD =
        marketDataAll && marketDataAll[props.bot.exchange]
          ? marketDataAll[props.bot.exchange][props.bot.market]
          : null;

      if (symbolD) {
        setMarketData(symbolD);
      }
    }
  }, [props.bot, marketDataAll]);

  const reset = () => {
    if (props.bot.id) {
      setName(props.bot.name);
      setBaseAssetsTotal(props.bot.baseAssetsTotal);
      setQuoteAssetsTotal(props.bot.quoteAssetsTotal);
      setMaxOrders(props.bot.maxOrders);
      setOrderAmountShapeFactor(props.bot.orderAmountShapeFactor);
      setOrderPriceShapeFactor(props.bot.orderPriceShapeFactor);
      setMaxOrderSize(props.bot.maxOrderSize);
      setSourceMarket(
        props.bot.sourceMarket ? props.bot.sourceMarket : "btc_usdt"
      );
      setSourceList(props.bot.syntheticData ? props.bot.syntheticData : []);
      setSyntheticToken(
        props.bot.isSyntheticToken ? props.bot.isSyntheticToken : false
      );
    }
  };

  const handleSaveBot = (e) => {
    e.preventDefault();
    if (accountEx === "bytedex") {
      setEditing(false);
      //save bot
      let data = {
        ...props.bot,
        account: account,
        accountTaker: takerAccount,
        takerExchange: accountExTaker,
        exchange: accountEx,
        accountQuoteRebalancer: quoteRebalanceAccount,
        name: name,
        baseAssetsTotal: parseFloat(baseAssetsTotal),
        quoteAssetsTotal: parseFloat(quoteAssetsTotal),
        maxOrders: parseFloat(maxOrders),
        orderAmountShapeFactor: parseFloat(orderAmountShapeFactor),
        orderPriceShapeFactor: parseFloat(orderPriceShapeFactor),
        maxOrderSize: parseFloat(maxOrderSize),
        isSyntheticToken: isSyntheticToken,
        syntheticData: sourceList,
      };
      firebase
        .firestore()
        .collection("bots")
        .doc(props.bot.id)
        .update(data)
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAlert("Unsupported exchange's account !");
    }
  };

  return (
    <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 text-center pb-2"
              style={{
                fontColor: "white",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Bot Settings
            </div>
          </div>

          <div className="row justify-content-around">
            <div className="col-12 px-3 rounded-lg dark-2 pt-3">
              <form onSubmit={handleSaveBot}>
                <div className="row">
                  {alert && (
                    <div className="col-12">
                      <div className="row">
                        <div className="col-10 m-auto">
                          <div
                            className="alert alert-warning py-1 text-center"
                            style={{
                              backgroundColor: "rgb(255, 204, 0,0.1)",
                              color: "yellow",
                            }}
                          >
                            {alert}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12 text-left m-auto">
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                        Account :
                      </label>
                      <div className="col-12 col-sm-4  text-center">
                        <select
                          disabled={isActive || !isEditing}
                          required
                          onChange={(e) => {
                            setAccount(e.target.value);
                          }}
                          className="custom-select mr-sm-2 form-control"
                          defaultValue={props.bot.account}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {" "}
                          {!exchangesById[account] && (
                            <option key="deleted" value=""></option>
                          )}
                          {exchanges.map((item, key) => {
                            return (
                              <option key={key} value={item.id}>
                                {item.name + " ( " + item.exchange + " )"}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <label className="col-12 col-sm-2  col-form-label pl-3  pb-1  pt-sm-0 pt-2  verticalCenter">
                        Market :
                      </label>
                      <div className="col-12 col-sm-4">
                        <select
                          disabled
                          required
                          className="custom-select mr-sm-2 form-control"
                          value={props.bot.market}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <option value={props.bot.market}>
                            {props.bot.market.toUpperCase()}
                          </option>
                        </select>
                      </div>{" "}
                    </div>
                    <div className="form-group row my-3 ">
                      <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                        Is Synthetic Token :
                      </label>
                      <div className="col-4 my-1 col-sm-3 verticalCenter ">
                        <input
                          type="checkbox"
                          className="pr-3 mr-3 verticalCenter"
                          // style={{ fontSize: "1.5rem" }}
                          checked={isSyntheticToken}
                          disabled
                        />
                      </div>

                      {isSyntheticToken && (
                        <React.Fragment>
                          <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                            Token Price :
                          </label>
                          <div className="col-4 my-1 col-sm-3 verticalCenter ">
                            {currencyPrice}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {isSyntheticToken ? (
                      <React.Fragment>
                        {" "}
                        <div
                          // style={{ minWidth: "65rem" }}
                          className="row  text-center  overflow-hidden py-1 dark-3 border-bottom border-dark verticalCenter mt-1"
                        >
                          <div
                            className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                            style={{ fontSize: "0.7rem", minHeight: "1rem" }}
                          >
                            Source Account
                          </div>
                          <div
                            className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                            style={{ fontSize: "0.7rem", minHeight: "1rem" }}
                          >
                            Source Market
                          </div>
                          <div
                            className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                            style={{ fontSize: "0.7rem", minHeight: "1rem" }}
                          >
                            Weight
                          </div>
                        </div>
                        {sourceList.map((item, index) => {
                          return (
                            <div
                              key={index}
                              // style={{ minWidth: "65rem" }}
                              className="row  border-dark border-left border-bottom text-center bot-details-row mb-3"
                            >
                              <div
                                style={{ fontSize: "0.7rem" }}
                                className="col-4 center border-right border-dark py-2 px-0 overflow-hidden"
                              >
                                {exchangesById[item.account]
                                  ? exchangesById[item.account].name
                                  : "Invalid account"}
                              </div>
                              <div
                                style={{ fontSize: "0.7rem" }}
                                className="col-4 center border-right border-dark py-2 overflow-hidden"
                              >
                                {item.market.toUpperCase()}
                              </div>
                              <div
                                style={{ fontSize: "0.7rem" }}
                                className="col-4 center border-right border-dark py-2 overflow-hidden"
                              >
                                {item.weight}
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <div className="form-group row  mb-2">
                        <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                          Source :
                        </label>
                        <div className="col-12 col-sm-4  text-center">
                          <select
                            disabled={isActive || !isEditing}
                            required
                            onChange={(e) => {
                              setTakerAccount(e.target.value);
                            }}
                            className="custom-select mr-sm-2 form-control"
                            defaultValue={props.bot.accountTaker}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {" "}
                            {!exchangesById[takerAccount] && (
                              <option key="deleted" value=""></option>
                            )}
                            <option value=""></option>
                            {exchanges.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name + " ( " + item.exchange + " )"}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <label className="col-12 col-sm-2  col-form-label pl-3  pb-1  pt-sm-0 pt-2  verticalCenter">
                          Source Market :
                        </label>
                        <div className="col-12 col-sm-4">
                          <select
                            disabled
                            required
                            className="custom-select mr-sm-2 form-control"
                            value={sourceMarket}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <option value={sourceMarket}>
                              {sourceMarket.toUpperCase()}
                            </option>
                          </select>
                        </div>{" "}
                      </div>
                    )}
                    {marketData.quoteAsset !== sourceMarket.split("_")[1] &&
                      accountExTaker !== "bytedex" && (
                        <div className="form-group row  my-2">
                          <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                            Quote Rebalance Account :
                          </label>
                          <div className="col-12 col-sm-4  text-center">
                            <select
                              disabled={isActive || !isEditing}
                              required
                              onChange={(e) => {
                                setQuoteRebalanceAccount(e.target.value);
                              }}
                              className="custom-select mr-sm-2 form-control"
                              defaultValue={props.bot.accountQuoteRebalancer}
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              {!exchangesById[account] && (
                                <option key="deleted" value=""></option>
                              )}
                              <option value=""></option>
                              {exchanges.map((item, key) => {
                                return item.exchange === "binance" ? (
                                  <option key={key} value={item.id}>
                                    {item.name + " ( " + item.exchange + " )"}
                                  </option>
                                ) : null;
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Max Order Size :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMaxOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={
                            Math.ceil(
                              Math.max(
                                marketData.minOrderSizeQuote,
                                marketData.minOrderSizeBase * currencyPrice * 2
                              ) * assetBits.quote
                            ) / assetBits.quote || 0
                          }
                          className="form-control"
                          required
                          value={maxOrderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />{" "}
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Max Orders :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMaxOrders(parseFloat(e.target.value))
                          }
                          type="number"
                          step="1"
                          min="1"
                          max="100"
                          className="form-control"
                          required
                          value={maxOrders}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="form-group row  my-3">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Initial Base Assets Total :
                      </label>
                      <div className="col-12 col-sm-4 mb-2">
                        <input
                          onChange={(e) =>
                            setBaseAssetsTotal(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={baseAssetsTotal}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.baseAsset &&
                            marketData.baseAsset.toUpperCase()}
                        </span>
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "2rem",
                            top: "2rem",
                            color: "gray",
                          }}
                        >
                          ~{" "}
                          {(baseAssetsTotal * currencyPrice).toFixed(
                            Math.log10(assetBits.quote)
                          )}{" "}
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Initial Quote Assets Total :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setQuoteAssetsTotal(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={quoteAssetsTotal}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Extra Order Price Shape Factor :
                      </label>
                      <div className="col-12 col-sm-3">
                        <input
                          onChange={(e) =>
                            setOrderPriceShapeFactor(parseFloat(e.target.value))
                          }
                          type="number"
                          step="0.01"
                          min="0.01"
                          max="100"
                          className="form-control"
                          required
                          value={orderPriceShapeFactor}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "0rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPercent}
                            size="1x"
                            color="gray"
                          />
                        </span>
                      </div>

                      <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Extra Order Amount Shape Factor :
                      </label>
                      <div className="col-12 col-sm-3">
                        <input
                          onChange={(e) =>
                            setOrderAmountShapeFactor(
                              parseFloat(e.target.value)
                            )
                          }
                          type="number"
                          step="0.01"
                          min="0.01"
                          max="100"
                          className="form-control"
                          required
                          value={orderAmountShapeFactor}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "0rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPercent}
                            size="1x"
                            color="gray"
                          />
                        </span>
                      </div>
                    </div>

                    {isEditing && (
                      <React.Fragment>
                        <div className="form-group row  my-3">
                          <div className="col-6 text-center m-auto">
                            <button
                              type="text"
                              className="btn btn-dark btn-sm  w-75"
                              onClick={() => {
                                setEditing(false);
                                reset();
                              }}
                            >
                              Back
                            </button>
                          </div>

                          <div className="col-6 text-center m-auto">
                            <button
                              disabled={alert && alert.length > 0}
                              type="submit"
                              className="btn btn-success btn-sm  w-75"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </form>
              {!isEditing && (
                <div className="form-group row  mt-2 mb-3">
                  <div className="col-6 text-center m-auto">
                    <button
                      disabled={props.bot.isPending}
                      type="text"
                      className="btn btn-dark btn-sm  w-75"
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HedgeBotSettings;
