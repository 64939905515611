import React, { Component } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/functions";
import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax

const Context = React.createContext();

var marketDataLoadingRequests = {};

const workerInstance2 = worker();

class ContextProvider extends Component {
  state = {
    screenWidth: window.innerWidth,
    isLoadingApp: true,
    email: "",
    exchanges: [],
    exchangesById: {},
    isLoggedIn: false,
    authError: "",
    alert: { isAvailable: false, msg: "", type: "warning" },
    topNotification: {
      isAvailable: false,
      msg: "Successfully Logged In",
      type: "alert-success",
    },
    emailVerificationSentAt: 0,
    telegram_notifications: {
      filled: true,
      dealComplete: true,
    },
    marketDataAll: {},
    marketDataAllList: {},
  };

  componentDidMount() {
    this.authenticateUser();
    this.loadMarketData("bytedex");

    window.addEventListener("resize", this.updateDimensions);

    workerInstance2.addEventListener("message", (message) => {
      if (message.data && message.data.type === "getMarketData") {
        var marketData = this.state.marketDataAll;
        marketData[message.data.exchange] = message.data.marketData;

        var marketDataList = this.state.marketDataAllList;
        marketDataList[message.data.exchange] = message.data.lisst;

        this.setState({
          marketDataAll: marketData,
          marketDataAllList: marketDataList,
        });
        marketDataLoadingRequests[message.data.exchange] = false;
      }
    });
  }

  getMarketData = (bot) => {
    if (!this.state.marketDataAll || !this.state.marketDataAll[bot.exchange]) {
      this.loadMarketData(bot.exchange);
    }
    return this.state.marketDataAll[bot.exchange]
      ? this.state.marketDataAll[bot.exchange][bot.market]
      : null;
  };
  loadMarketData = async (exchange) => {
    console.log("loadMarketData", exchange);
    if (
      exchange &&
      (!this.state.marketDataAll || !this.state.marketDataAll[exchange]) &&
      !marketDataLoadingRequests[exchange]
    ) {
      marketDataLoadingRequests[exchange] = true;

      firebase
        .database()
        .ref(`/marketData/${exchange}`)
        .once("value")
        .then((snapshot) => {
          workerInstance2.getMarketData(exchange, snapshot.val());
        });
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  compareDate = (a, b) => {
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    return 0;
  };

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };
  authenticateUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          email: user.email,
        });

        this.updateAccountData();

        console.log("login sucess");
      } else {
        this.setState({
          isLoggedIn: false,
          isLoadingApp: false,
          email: "",
          authError: "",
          exchanges: [],
          exchangesById: {},
          topNotification: {
            isAvailable: false,
            msg: "Successfully Logged In",
            type: "alert-success",
          },
          emailVerificationSentAt: 0,
        });
      }
    });
  };

  updateAccountData = async () => {
    firebase
      .database()
      .ref("/accounts/")
      .on(
        "value",
        (snapshot) => {
          if (snapshot.val()) {
            var exchanges = [];

            var selecExOb = {};

            for (var id in snapshot.val()) {
              var ele = snapshot.val()[id];
              if (ele) {
                exchanges.push({ ...ele, id: id });
                selecExOb[id] = { ...ele, id: id };
              }
            }

            this.setState({
              isLoggedIn: true,
              isLoadingApp: false,
              authError: "",
              exchanges: exchanges,
              exchangesById: selecExOb,
            });
          }
        },
        (error) => {
          console.log(error);

          this.setState({
            isLoggedIn: false,
            isLoadingApp: false,
            email: "",
            authError: "",
          });
        }
      );

    firebase
      .database()
      .ref("telegram_notifications/")
      .on(
        "value",
        async (data) => {
          if (data.val()) {
            this.setState({
              telegram_notifications: data.val(),
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };
  signOut = () => {
    // console.log("so");
    firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("user SignOut sucess");
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({
      email: "",
      isLoggedIn: false,
    });
  };

  emailSignIn = (email, password) => {
    this.setState({
      isLoadingApp: true,
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        console.log("Signed in Success.");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        this.setState({
          isLoadingApp: false,
        });
        if (errorCode === "auth/user-not-found") {
          this.setState({
            authError: "There is no user record corresponding to this email.",
          });
        } else if (errorCode === "auth/wrong-password") {
          this.setState({
            authError: "The password is invalid",
          });
        } else {
          this.setState({
            authError: errorMessage,
          });
        }
      });
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          emailSignIn: this.emailSignIn,
          sleep: (val) => this.sleep(val),
          getMarketData: (val) => this.getMarketData(val),
          loadMarketData: (val) => this.loadMarketData(val),
          setTopNotification: (isAvailable, msg, type) =>
            this.setState({
              topNotification: {
                isAvailable: isAvailable,
                msg: msg,
                type: type,
              },
            }),
          signOut: this.signOut,
          setAlert: (val, msg, type) =>
            this.setState({
              alert: {
                isAvailable: val,
                msg: msg,
                type: type,
              },
            }),
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export { ContextProvider, Context };
