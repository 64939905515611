import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

import { Context } from "../../../backend/context";
import LoadingScreen from "../../botLoadingScreen";
import Bytedex from "../../../backend/bytedex";

function HedgeBotSummery(props) {
  const { exchangesById } = React.useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMsg, setloadingMsg] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState(0);

  const stopActiveBot = () => {
    if (window.confirm(`Stop bot ${props.bot.displayId} ?`)) {
      var docu = firebase.firestore().collection("bots").doc(props.bot.id);
      docu.update({ isRunning: false });
    }
  };
  const startStoppedBot = () => {
    if (
      window.confirm(
        `Start Bot ${
          props.bot.name && props.bot.name.length > 0
            ? props.bot.name
            : props.bot.displayId
        } ?`
      )
    ) {
      let data = {
        ...props.bot,
        startedAt: Date.now(),
        isRunning: false,
        isPending: true,
      };
      firebase
        .firestore()
        .collection("pendingBots")
        .doc(props.bot.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });
      firebase
        .firestore()
        .collection("bots")
        .doc(props.bot.id)
        .set(data)
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const deleteStoppedBot = async () => {
    if (window.confirm(`Delete bot ${props.bot.displayId} ?`)) {
      firebase
        .firestore()
        .collection("bots")
        .doc(props.bot.id)
        .delete()
        .then(() => {
          console.log("bot successfully deleted!");
        })
        .catch((error) => {
          console.log(error, "Error deleted bot");
        });
      firebase.database().ref(`userNotifications/${props.bot.id}`).remove();
      firebase.database().ref(`filledOrders/${props.bot.id}`).remove();
    }
  };
  async function getTicker() {
    if (props.marketData) {
      var ticker = await Bytedex.getTicker(
        props.marketData.symbol.toUpperCase()
      );

      if (ticker && ticker.last_price) {
        setCurrencyPrice(ticker.last_price);
      }
    }
  }
  useEffect(() => {
    if (
      (props.bot.isRunning || props.bot.isPending) &&
      (!props.bot.lastRunAt || props.bot.lastRunAt < props.bot.startedAt)
    ) {
      setloadingMsg("Starting");
      setIsLoading(true);
    } else if (
      !props.bot.isRunning &&
      props.bot.lastRunAt > props.bot.lastStoppedAt
    ) {
      setloadingMsg("Stopping");
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    getTicker();
  }, [props.bot, props.marketData]);

  return (
    <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
      {isLoading && <LoadingScreen msg={loadingMsg} />}
      <div className="col-12 col-sm-9">
        <div className="row  px-3">
          <div className="col-12 col-sm-4 h6 dark-3 p-2 rounded-lg mt-2  verticalCenter">
            Bot ID :{" "}
            <span className="pl-3 font-weight-bold">{props.bot.displayId}</span>
          </div>
          <div className="col-12 col-sm-4 h6 dark-3 p-2 rounded-lg mx-auto mt-2  verticalCenter">
            Account :{" "}
            <span className="pl-3 font-weight-bold">
              {exchangesById[props.bot.account]
                ? exchangesById[props.bot.account].name
                : "Deleted Account"}
            </span>
          </div>
          <div className="col-12 col-sm-3 h6 dark-3 p-2 rounded-lg mx-auto mt-2  ">
            <div className="row  pl-3">
              <span style={{ color: "gray" }}>Total Investment :</span>
              <div className="col-12 h6 font-weight-bold px-0 text-center">
                {parseFloat(
                  (
                    props.bot.baseAssetsTotal * currencyPrice +
                    props.bot.quoteAssetsTotal
                  ).toFixed(
                    props.marketData &&
                      props.marketData.quoteAsset.includes("usd")
                      ? 2
                      : 6
                  )
                )}{" "}
                <small>
                  {props.marketData &&
                    props.marketData.quoteAsset.toUpperCase()}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-3">
          <div className="col-12 col-sm-4 h6 dark-3 p-2 rounded-lg mx-auto mt-2">
            <div className="row  pl-3">
              <span style={{ color: "gray" }}>Total Volume :</span>
              <div className="col-12  h6 font-weight-bold px-0 text-center">
                {props.botVnP.volume.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-4 h6 dark-3 p-2 rounded-lg mx-auto mt-2">
            <div className="row">
              <span className="pl-3" style={{ color: "gray" }}>
                Total Profit :
              </span>

              <div
                className="col-12  h6 font-weight-bold px-0 text-center"
                style={{
                  color:
                    props.botVnP.profit && props.botVnP.profit < 0
                      ? "#ff3333"
                      : "green",
                }}
              >
                <span
                  className="my-tooltip pointer"
                  style={{
                    color:
                      props.botVnP.profit && props.botVnP.profit < 0
                        ? "#ff3333"
                        : "green",
                  }}
                >
                  <span
                    className="tooltiptext font-weight-light py-3"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Last 24h : $
                    {props.botVnP.last24hProfit
                      ? props.botVnP.last24hProfit
                      : 0}{" "}
                    <br />
                    <br />
                    Last 7d : $
                    {props.botVnP.last7dProfit ? props.botVnP.last7dProfit : 0}
                  </span>
                  ${props.botVnP.profit ? props.botVnP.profit : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-3 col-md-3">
        <div className="row text-center">
          <div className="col-6 col-sm-12 m-auto py-2   ">
            {props.bot.isRunning ? (
              <button
                onClick={() => stopActiveBot()}
                className="btn btn-sm btn-danger w-75 "
                disabled={props.bot.isPending}
              >
                Stop
              </button>
            ) : (
              <button
                onClick={() => startStoppedBot()}
                className="btn btn-sm btn-success w-75 "
                disabled={props.bot.isPending}
              >
                Start
              </button>
            )}
          </div>

          <div className="col-6 col-sm-12 m-auto py-2 ">
            <button
              onClick={() => deleteStoppedBot()}
              className="btn btn-sm btn-danger  w-75 "
              disabled={props.bot.isRunning || props.bot.isPending}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HedgeBotSummery;
