import React, { useEffect, useState } from "react";
import { Context } from "../../backend/context";
import firebase from "firebase/compat/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CreateBotPopup from "../createBotPopup";
import Select from "react-select";
import Bytedex from "../../backend/bytedex";

function CreateTickerBot(props) {
  const { exchanges, exchangesById, marketDataAll, marketDataAllList } =
    React.useContext(Context);
  const [name, setName] = useState("");
  const [orderInterval, setOrderInterval] = useState(2);
  const [orderSize, setOrderSize] = useState(20);
  const [maxOrderSize, setMaxOrderSize] = useState(200);
  const [alert, setAlert] = useState(null);
  const [account, setAccount] = useState(null);
  const [takerAccount, setTakerAccount] = useState(null);
  const [accountEx, setAccountEx] = useState("");
  const [accountExTaker, setAccountExTaker] = useState("");
  const [secondEx, setSecondEx] = useState("no");

  const [currencyPrice, setCurrencyPrice] = useState(0);
  const [market, setMarket] = useState("btc_usdt");
  const [marketData, setMarketData] = useState({
    symbol: "btc_usdt",
    symbolOpp: "usdt_btc",
    baseAsset: "btc",
    quoteAsset: "usdt",
    step: 0.1,
    tickDigits: 10,
    minOrderSizeBase: 0.0005,
    minOrderSizeQuote: 10,
  });
  const [assetBits, setAssetBits] = useState({
    base: 1000000,
    quote: 100,
  });
  const [submitType, setSubmitType] = useState("start");
  const [marketList, setMarketList] = useState([]);

  useEffect(() => {
    if (marketDataAllList && marketDataAllList[accountEx]) {
      var lisst = [];
      for (
        let index = 0;
        index < marketDataAllList[accountEx].length;
        index++
      ) {
        const element = marketDataAllList[accountEx][index];

        lisst.push({
          value: element.symbol,
          label: element.symbol.replace("_", "/").toUpperCase(),
        });
      }

      setMarketList(lisst);
    }
  }, [marketDataAllList, accountEx, marketDataAll]);

  function handleSetMarket(val) {
    if (val && val.value) {
      setMarket(val.value);
    }
  }

  useEffect(() => {
    setAccountEx(exchangesById[account] ? exchangesById[account].exchange : "");
    setAccountExTaker(
      exchangesById[takerAccount] ? exchangesById[takerAccount].exchange : ""
    );
  }, [account, takerAccount, exchangesById]);

  async function getTicker() {
    var ticker = await Bytedex.getTicker(marketData.symbol.toUpperCase());

    if (ticker && ticker.last_price) {
      setCurrencyPrice(ticker.last_price);
    }
  }

  useEffect(() => {
    if (marketData && marketData.symbol) {
      Bytedex.initSocket(
        { topic: "orderbooks", symbol: marketData.symbol.toUpperCase() },
        (msg) => {
          const json = JSON.parse(msg.data);
          try {
            if (
              json.data &&
              json.data.length > 0 &&
              json.data[0].length > 0 &&
              json.data[1].length > 0
            ) {
              var price = json.data[1][0][0];
              setCurrencyPrice(price ? price : 0);
              console.log(price);
            } else if (json.data) {
              getTicker();
            }
          } catch (err) {
            console.log(err);
          }
        }
      );

      return () => {
        Bytedex.closeSocket({
          topic: "orderbooks",
          symbol: marketData.symbol.toUpperCase(),
        });
      };
    }
  }, [marketData]);
  useEffect(() => {
    if (currencyPrice && marketData.symbol) {
      var assetBitsQuote = {
        usdt: 100,
        btc: 1000000,
        try: 100,
        usdc: 100,
        dai: 100,
        eur: 100,
        bext: 1,
      };
      if (
        assetBits.base !==
          10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ) ||
        assetBits.quote !== assetBitsQuote[marketData.quoteAsset]
      ) {
        // console.log("updated assets");
        setAssetBits({
          base:
            10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ),
          quote: assetBitsQuote[marketData.quoteAsset],
        });
      }
    }
  }, [currencyPrice, marketData, assetBits]);

  //set alert
  useEffect(() => {
    if (
      orderSize > 0 &&
      orderSize <
        Math.ceil(
          Math.max(
            marketData.minOrderSizeQuote,
            marketData.minOrderSizeBase * currencyPrice * 2
          ) * assetBits.quote
        ) /
          assetBits.quote
    ) {
      setAlert(
        "Minimum Order Size is " +
          Math.ceil(
            Math.max(
              marketData.minOrderSizeQuote,
              marketData.minOrderSizeBase * currencyPrice * 2
            ) * assetBits.quote
          ) /
            assetBits.quote +
          " " +
          marketData.quoteAsset.toUpperCase()
      );
    } else {
      if (account) {
        if (takerAccount) {
          if (accountEx === "bytedex") {
            if (accountExTaker === "bytedex") {
              if (!marketData) {
                setAlert("Unsupported Market !");
              } else if (account === takerAccount) {
                setAlert("Maker Account & Taker Account should be different !");
              } else {
                setAlert(null);
              }
            } else {
              setAlert("Unsupported Taker exchange's account !");
            }
          } else {
            setAlert("Unsupported Maker exchange's account !");
          }
        } else {
          setAlert("Please connect Taker exchange !");
        }
      } else {
        setAlert("Please connect Maker exchange !");
      }
    }
  }, [
    orderSize,
    marketData,
    currencyPrice,
    assetBits,
    account,
    accountEx,
    maxOrderSize,
    takerAccount,
    accountExTaker,
  ]);

  //get  market data
  useEffect(() => {
    var symbolD =
      marketDataAll && marketDataAll[accountEx]
        ? marketDataAll[accountEx][market]
        : null;
    if (symbolD) {
      setMarketData(symbolD);
    }
  }, [market, marketDataAll, accountEx]);

  const resetData = () => {
    props.setCreatingBot(false);
    setOrderSize(0);
    setOrderInterval(0);
    setMaxOrderSize(0);
    setSecondEx("no");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitType === "start") {
      submitNewBot();
    } else {
      handleSaveBot();
    }
  };
  const submitNewBot = () => {
    if (accountEx === "bytedex") {
      if (!alert) {
        let data = {
          name: name,
          type: "TICKER",
          market: market,
          orderSize: parseFloat(orderSize),
          maxOrderSize: parseFloat(maxOrderSize),
          orderInterval: parseFloat(orderInterval),
          secondEx: secondEx,
          account: account,
          accountTaker: takerAccount,
          exchange: accountEx,
          createdAt: Date.now(),
          startedAt: Date.now(),
          runningTime: 0,
          isRunning: false,
          isRestarting: false,
          isPending: true,
          processID: "",
          displayId: "T" + (Date.now() - new Date("2022/1/1")).toString(),
        };
        firebase
          .firestore()
          .collection("pendingBots")
          .add(data)
          .catch((err) => {
            console.log(err);
          });

        document.getElementById("createBotPopupOpen").click();
      }
    } else {
      setAlert("Unsupported exchange's account !");
    }
  };
  const handleClosePopup = () => {
    // resetData();
    document.getElementById("closeCreateBotPopup").click();
  };
  const handleSaveBot = () => {
    if (accountEx === "bytedex") {
      //save bot
      let data = {
        name: name,
        type: "TICKER",
        market: market,
        orderSize: parseFloat(orderSize),
        maxOrderSize: parseFloat(maxOrderSize),
        orderInterval: parseFloat(orderInterval),
        secondEx: secondEx,
        account: account,
        accountTaker: takerAccount,
        exchange: accountEx,
        createdAt: Date.now(),
        startedAt: 0,
        runningTime: 0,
        isRunning: false,
        isPending: false,
        isRestarting: false,
        processID: "",
        displayId: "T" + (Date.now() - new Date("2022/1/1")).toString(),
      };
      firebase
        .firestore()
        .collection("bots")
        .add(data)
        .catch((err) => {
          console.log(err);
        });
      //
      resetData();
    } else {
      setAlert("Unsupported exchange's account !");
    }
  };

  return (
    <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 h5">Create Ticker Bot</div>
            <div className="col-2 h5">
              <button
                onClick={() => props.setCreatingBot(false)}
                className="border border-dark rounded-lg px-2 dark-3 sim-btn"
              >
                <FontAwesomeIcon icon={faChevronUp} size="1x" color="#d9d9d9" />
              </button>
            </div>
          </div>

          <CreateBotPopup handleClosePopup={handleClosePopup} />
          <button
            data-toggle="modal"
            data-target="#createBotPopup"
            type="button"
            id="createBotPopupOpen"
            style={{ display: "none" }}
          ></button>
          <div className="row justify-content-around">
            <div className="col-12 col-sm-10 px-3 my-2 rounded-lg dark-2 pt-2 mx-auto">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {alert && (
                    <div className="col-12 col-sm-6 mx-auto mt-2">
                      <div className="row">
                        <div className="col-10 m-auto">
                          <div
                            className="alert alert-warning py-2 text-center mb-2"
                            style={{
                              backgroundColor: "rgb(255, 204, 0,0.1)",
                              color: "yellow",
                            }}
                          >
                            {alert}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12  text-left m-auto">
                    <div className="form-group row my-2 ">
                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Maker Account :
                      </label>
                      <div className="col-12 col-sm-4 text-center">
                        {exchanges.length > 0 ? (
                          <select
                            required
                            onChange={(e) => {
                              setAccount(e.target.value);
                            }}
                            className="custom-select mr-sm-2 form-control"
                            defaultValue={null}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {exchanges.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name + " ( " + item.exchange + " )"}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <Link
                            to="/account/exchanges"
                            className="btn btn-sm btn-primary m-auto w-100"
                            // style={{ backgroundColor: "#0080ff" }}
                          >
                            Connect Exchange
                          </Link>
                        )}
                      </div>
                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Taker Account :
                      </label>
                      <div className="col-12 col-sm-4 text-center">
                        {exchanges.length > 0 ? (
                          <select
                            required
                            onChange={(e) => {
                              setTakerAccount(e.target.value);
                            }}
                            className="custom-select mr-sm-2 form-control"
                            defaultValue={null}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {exchanges.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name + " ( " + item.exchange + " )"}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <Link
                            to="/account/exchanges"
                            className="btn btn-sm btn-primary m-auto w-100"
                            // style={{ backgroundColor: "#0080ff" }}
                          >
                            Connect Exchange
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="form-group row  my-3">
                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Market :
                      </label>
                      <div className="col-12 col-sm-4">
                        <Select
                          required
                          onChange={(e) => {
                            handleSetMarket(e);
                          }}
                          defaultValue={market}
                          isClearable={true}
                          isSearchable={true}
                          name="markets"
                          options={marketList}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: "#1a75ff",
                              primary50: "#1a75ff",
                              neutral0: "black",
                              neutral5: "gray",
                              neutral10: "gray",
                              neutral20: "gray",
                              neutral50: "rgb(158, 158, 158)",
                              neutral80: "rgb(158, 158, 158)",
                              neutral90: "white",
                            },
                          })}
                        />
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3 pb-1">
                        Price Source :
                      </label>
                      <div className="col-12 col-sm-4">
                        <select
                          required
                          onChange={(e) => {
                            setSecondEx(e.target.value);
                          }}
                          className="custom-select mr-sm-2 form-control"
                          value={secondEx}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <option value="no">Not Available</option>{" "}
                          <option value="kucoin">Kucoin</option>
                          <option value="gateio">Gateio</option>
                          <option value="binance">Binance</option>
                          <option value="mexc">Mexc</option>
                          <option value="huobi">Huobi</option>
                          <option value="okx">OKX</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Min Order Size :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={
                            Math.ceil(
                              Math.max(
                                marketData.minOrderSizeQuote,
                                marketData.minOrderSizeBase * currencyPrice * 2
                              ) * assetBits.quote
                            ) / assetBits.quote || 0
                          }
                          className="form-control"
                          required
                          value={orderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3   pb-1">
                        Max Order Size :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMaxOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={
                            Math.ceil(
                              Math.max(
                                marketData.minOrderSizeQuote,
                                marketData.minOrderSizeBase * currencyPrice * 2
                              ) * assetBits.quote
                            ) / assetBits.quote || 0
                          }
                          className="form-control"
                          required
                          value={maxOrderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                    </div>
                    {/* <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pb-1">
                        Order Interval <span className="px-1"> (Seconds) </span>{" "}
                        :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setOrderInterval(parseFloat(e.target.value))
                          }
                          type="number"
                          step="1"
                          min="1"
                          className="form-control"
                          required
                          value={orderInterval}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        />
                      </div>
                    </div> */}

                    <div className="form-group row mb-3">
                      <div className="col-6 text-center">
                        <button
                          disabled={alert && alert.length > 0}
                          type="submit"
                          className="btn btn-dark btn-sm w-75"
                          onClick={() => setSubmitType("save")}
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-6 text-center">
                        <button
                          disabled={alert && alert.length > 0}
                          type="submit"
                          className="btn btn-success btn-sm w-75"
                          onClick={() => setSubmitType("start")}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTickerBot;
