import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import { Context } from "../../backend/context";
import SummeryGraph from "./summeryGraph";
import ActiveDeals from "./activeDeals";
import HandleBots from "./handleBots";

function Dashboard() {
  const { isLoggedIn } = React.useContext(Context);

  return (
    <div className="dark-1 container-fluid">
      {!isLoggedIn ? <Redirect to="/" /> : null}

      <div className="row">
        <div className="col-12 h4 ">
          <FontAwesomeIcon icon={faThLarge} size="1x" color="#d9d9d9" />
          <span className="px-2">Dashboard </span>{" "}
        </div>
      </div>
      <div className="row ">
        <div className="col-12 col-md-10 col-lg-8 mb-2 mt-2   ">
          <div className="row  ">
            <div className="col-12 ">
              <SummeryGraph />
            </div>
          </div>{" "}
          <div className="row  mt-3">
            <div className="col-12 ">
              <ActiveDeals />
            </div>
          </div>{" "}
        </div>
        <div className="col-12 col-md-2 col-lg-4 mb-2 mt-2   ">
          <div className="row  ">
            <div className="col-12 ">
              <HandleBots />
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
