import { combineReducers } from "redux";

import pendingBotsReducer from "./pendingBots";
import botsReducer from "./bots";
import volumeNprofitReducer from "./volumeNprofit";
import activeDealsReducer from "./activeDeals";

const allReducers = combineReducers({
  pendingBots: pendingBotsReducer,
  bots: botsReducer,
  volumeNprofit: volumeNprofitReducer,
  activeDeals: activeDealsReducer,
});

export default allReducers;
