import React, { useEffect } from "react";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import Chart from "chart.js";

import { firebaseConfig } from "./backend/firebaseConfig";
import firebase from "firebase/compat/app";

import { Context } from "./backend/context";
import LoadingScreen from "./components/loadingScreen";

import Platform from "./components/platform";
import Auth from "./components/auth/fixedAuth";
import Bytedex from "./backend/bytedex";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

Bytedex.init();

function App() {
  const { isLoggedIn, isLoadingApp } = React.useContext(Context);
  const history = useHistory();

  const { pathname } = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      if (pathname === "/") {
        history.push("/account");
      }
      if (document.getElementById("closeAuthPopup")) {
        document.getElementById("closeAuthPopup").click();
      }
      let body = document.querySelector(".modal-open");
      if (body) {
        body.classList.remove("modal-open");
        body.removeAttribute("style");
        let divFromHell = document.querySelector(".modal-backdrop");
        body.removeChild(divFromHell);
      }
    }
  }, [isLoggedIn]);

  return (
    <div
      className="dark-1"
      style={{
        height: "100%",
        position: "absolute",
        left: "0px",
        width: "100%",
      }}
    >
      <button
        id="closeAuthPopup"
        data-dismiss="modal"
        data-backdrop="false"
        style={{ display: "none" }}
      ></button>
      <button
        data-toggle="modal"
        data-target="#authPopup"
        type="button"
        id="authpopupOpen"
        style={{ display: "none" }}
      ></button>
      {isLoadingApp && <LoadingScreen />}

      <Switch>
        {" "}
        <Route path="/account" component={Platform} />
        <Route
          path="/create-account"
          component={() => <Auth isSignIn={false} />}
        />
        <Route path="/" component={() => <Auth isSignIn={true} />} />
      </Switch>
    </div>
  );
}

export default App;
