import { SET_VOLUME_PROFIT } from "../actions/types";

const initialState = {
  botData: {},
  userTodayData: {},
  userWeekData: {},
  userMonthData: {},
  userAllData: {},
};

const volumeNprofitReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VOLUME_PROFIT:
      return {
        ...state,
        botData: action.payload.data,
        userTodayData: action.payload.userTodayData,
        userWeekData: action.payload.userWeekData,
        userAllData: action.payload.userAllData,
        userMonthData: action.payload.userMonthData,
      };

    default:
      return state;
  }
};

export default volumeNprofitReducer;
