import React from "react";

function Navbar() {
  return (
    <nav className="navbar navbar-expand-md  dark-1 navbar-dark container-fluid fixed-top justify-content-start pb-1 pt-2 ">
      <img
        className=""
        src="/img/logo.png"
        alt="Coinner"
        style={{ maxWidth: "14rem" }}
      />{" "}
      <div className="h3"> Market Maker</div>
    </nav>
  );
}

export default Navbar;
