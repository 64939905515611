import React, { useEffect, useState } from "react";
import { Context } from "../../../backend/context";
import firebase from "firebase/compat/app";
import Bytedex from "../../../backend/bytedex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";

function MarketMakerBotSettings(props) {
  const { marketDataAll, exchanges, exchangesById } = React.useContext(Context);
  const [isActive, setActive] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [orderInterval, setOrderInterval] = useState(2);
  const [baseAssetsTotal, setBaseAssetsTotal] = useState(0);
  const [quoteAssetsTotal, setQuoteAssetsTotal] = useState(0);
  const [maxOrders, setMaxOrders] = useState(10);
  const [orderAmountShapeFactor, setOrderAmountShapeFactor] = useState(10);
  const [orderPriceShapeFactor, setOrderPriceShapeFactor] = useState(10);
  const [riskFactor, setRiskFactor] = useState(1);
  const [minSpread, setMinSpread] = useState(0.1);
  const [targetBaseAssets, setTargetBaseAssets] = useState(50);
  const [name, setName] = useState("");
  const [account, setAccount] = useState(props.bot.account);
  const [accountEx, setAccountEx] = useState("");
  const [accountTwo, setAccountTwo] = useState(props.bot.accountTwo);
  const [accountTwoEx, setAccountTwoEx] = useState("");
  const [secondEx, setSecondEx] = useState("no");
  const [sourceMarket, setSourceMarket] = useState("btc_usdt");
  const [marketData, setMarketData] = useState({
    symbol: "btc_usdt",
    symbolOpp: "usdt_btc",
    baseAsset: "btc",
    quoteAsset: "usdt",
    step: 0.1,
    tickDigits: 10,
    minOrderSizeBase: 0.0005,
    minOrderSizeQuote: 10,
  });

  const [currencyPrice, setCurrencyPrice] = useState(0);
  const [alert, setAlert] = useState(null);

  const [initialPrice, setInitialPrice] = useState(0);
  const [capitalPer1PerPriceChange, setCapitalPer1PerChange] = useState(500);
  const [tickerOrderSizeMax, setTickerOrderSizeMax] = useState(500);

  const [assetBits, setAssetBits] = useState({
    base: 1000000,
    quote: 100,
  });

  const [isSyntheticToken, setSyntheticToken] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [isGrid, setGrid] = useState(true);

  const [isTickerBotActivate, setTickerBotActivate] = useState(false);
  const [minimumOrderSize, setMinimumOrderSize] = useState(0);
  const [maximumOrderSize, setMaximumOrderSize] = useState(0);

  useEffect(() => {
    setAccountEx(exchangesById[account] ? exchangesById[account].exchange : "");
  }, [account, exchangesById]);

  useEffect(() => {
    setAccountTwoEx(
      exchangesById[accountTwo] ? exchangesById[accountTwo].exchange : ""
    );
  }, [accountTwo, exchangesById]);

  async function getTicker() {
    var ticker = await Bytedex.getTicker(marketData.symbol.toUpperCase());

    if (ticker && ticker.last_price) {
      setCurrencyPrice(ticker.last_price);
    }
  }
  useEffect(() => {
    getTicker();
  }, [marketData, props.bot, isEditing]);
  useEffect(() => {
    if (currencyPrice && marketData.symbol) {
      var assetBitsQuote = {
        usdt: 100,
        btc: 1000000,
        try: 100,
        usdc: 100,
        dai: 100,
        eur: 100,
        bext: 1,
      };
      if (
        assetBits.base !==
          10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ) ||
        assetBits.quote !== assetBitsQuote[marketData.quoteAsset]
      ) {
        // console.log("updated assets");
        setAssetBits({
          base:
            10 **
            Math.round(
              Math.log10(assetBitsQuote[marketData.quoteAsset] * currencyPrice)
            ),
          quote: assetBitsQuote[marketData.quoteAsset],
        });
      }
    }
  }, [currencyPrice, marketData, assetBits]);

  useEffect(() => {
    if (isEditing) {
      if (account) {
        if (accountTwo) {
          if (accountEx === "bytedex") {
            if (!marketData) {
              setAlert("Unsupported Market !");
            } else if (
              !isSyntheticToken &&
              secondEx !== "no" &&
              marketData.baseAsset !== "botc" &&
              marketData.baseAsset !== sourceMarket.split("_")[0]
            ) {
              setAlert("Unsupported Source Market !");
            } else if (accountTwoEx !== "bytedex" || accountTwo === account) {
              setAlert("Unsupported Taker exchange's account !");
            } else {
              setAlert(null);
            }
          } else {
            setAlert("Unsupported exchange's account !");
          }
        } else {
          setAlert("Please connect Taker exchange !");
        }
      } else {
        setAlert("Please connect exchange !");
      }
    }
  }, [
    isEditing,
    marketData,
    currencyPrice,
    assetBits,
    account,
    accountEx,
    sourceMarket,
    secondEx,
    accountTwoEx,
    accountTwo,
  ]);

  useEffect(() => {
    if (props.bot.id) {
      setName(props.bot.name);
      setBaseAssetsTotal(props.bot.baseAssetsTotal);
      setQuoteAssetsTotal(props.bot.quoteAssetsTotal);
      setMaxOrders(props.bot.maxOrders);
      setOrderAmountShapeFactor(props.bot.orderAmountShapeFactor);
      setOrderPriceShapeFactor(props.bot.orderPriceShapeFactor);
      setRiskFactor(props.bot.riskFactor);
      setMinSpread(props.bot.minSpread);
      setTargetBaseAssets(props.bot.targetBaseAssets);
      setActive(props.bot.isRunning);
      setOrderInterval(props.bot.orderInterval);
      setSecondEx(props.bot.secondEx ? props.bot.secondEx : "no");
      setSourceMarket(props.bot.sourceMarket);
      setSourceList(props.bot.syntheticData ? props.bot.syntheticData : []);
      setSyntheticToken(
        props.bot.isSyntheticToken ? props.bot.isSyntheticToken : false
      );
      // setGrid(props.bot.isGrid ? props.bot.isGrid : false);
      setTickerBotActivate(
        props.bot.isTickerBotActivate ? props.bot.isTickerBotActivate : false
      );

      setMinimumOrderSize(props.bot.minimumOrderSize);
      setMaximumOrderSize(props.bot.maximumOrderSize);

      setInitialPrice(props.bot.initialPrice ? props.bot.initialPrice : 0);
      setCapitalPer1PerChange(
        props.bot.capitalPer1PerPriceChange
          ? props.bot.capitalPer1PerPriceChange
          : 100
      );
      setTickerOrderSizeMax(
        props.bot.tickerOrderSizeMax ? props.bot.tickerOrderSizeMax : 500
      );

      var symbolD =
        marketDataAll && marketDataAll[props.bot.exchange]
          ? marketDataAll[props.bot.exchange][props.bot.market]
          : null;

      if (symbolD) {
        setMarketData(symbolD);
      }
    }
  }, [props.bot, marketDataAll]);

  const reset = () => {
    if (props.bot.id) {
      setName(props.bot.name);
      setBaseAssetsTotal(props.bot.baseAssetsTotal);
      setQuoteAssetsTotal(props.bot.quoteAssetsTotal);
      setMaxOrders(props.bot.maxOrders);
      setOrderAmountShapeFactor(props.bot.orderAmountShapeFactor);
      setOrderPriceShapeFactor(props.bot.orderPriceShapeFactor);
      setRiskFactor(props.bot.riskFactor);
      setMinSpread(props.bot.minSpread);
      setTargetBaseAssets(props.bot.targetBaseAssets);
      setOrderInterval(props.bot.orderInterval);
      setSecondEx(props.bot.secondEx ? props.bot.secondEx : "no");
      setSourceMarket(props.bot.sourceMarket);
      setSourceList(props.bot.syntheticData ? props.bot.syntheticData : []);
      setSyntheticToken(
        props.bot.isSyntheticToken ? props.bot.isSyntheticToken : false
      );
      // setGrid(props.bot.isGrid ? props.bot.isGrid : false);
      setTickerBotActivate(
        props.bot.isTickerBotActivate ? props.bot.isTickerBotActivate : false
      );

      setMinimumOrderSize(props.bot.minimumOrderSize);
      setMaximumOrderSize(props.bot.maximumOrderSize);

      setInitialPrice(props.bot.initialPrice);
      setCapitalPer1PerChange(props.bot.capitalPer1PerPriceChange);
      setTickerOrderSizeMax(props.bot.tickerOrderSizeMax);
    }
  };

  const handleSaveBot = (e) => {
    e.preventDefault();
    if (accountEx === "bytedex" && accountTwoEx === "bytedex") {
      setEditing(false);
      //save bot
      let data = {
        ...props.bot,
        account: account,
        accountTwo: accountTwo,
        exchange: accountEx,
        name: name,
        secondEx: secondEx,
        baseAssetsTotal: parseFloat(baseAssetsTotal),
        quoteAssetsTotal: parseFloat(quoteAssetsTotal),
        orderInterval: parseFloat(orderInterval),
        maxOrders: parseFloat(maxOrders),
        orderAmountShapeFactor: parseFloat(orderAmountShapeFactor),
        orderPriceShapeFactor: parseFloat(orderPriceShapeFactor),
        maximumOrderSize: parseFloat(maximumOrderSize),
        minimumOrderSize: parseFloat(minimumOrderSize),
        riskFactor: parseFloat(riskFactor),
        minSpread: parseFloat(minSpread),
        targetBaseAssets: parseFloat(targetBaseAssets),
        isSyntheticToken: isSyntheticToken,
        syntheticData: sourceList,
        // isGrid: isGrid,
        isTickerBotActivate: isTickerBotActivate,
        initialPrice: parseFloat(initialPrice),
        capitalPer1PerPriceChange: parseFloat(capitalPer1PerPriceChange),
        tickerOrderSizeMax: parseFloat(tickerOrderSizeMax),
      };
      firebase
        .firestore()
        .collection("bots")
        .doc(props.bot.id)
        .update(data)
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAlert("Unsupported exchange's account !");
    }
  };

  return (
    <div className="row dark-2 rounded-lg py-3 my-3 mx-0">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 text-center pb-2"
              style={{
                fontColor: "white",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Bot Settings
            </div>
          </div>

          <div className="row justify-content-around">
            <div className="col-12 px-3 rounded-lg dark-2 pt-3">
              <form onSubmit={handleSaveBot}>
                <div className="row">
                  {alert && (
                    <div className="col-12">
                      <div className="row">
                        <div className="col-10 m-auto">
                          <div
                            className="alert alert-warning py-1 text-center"
                            style={{
                              backgroundColor: "rgb(255, 204, 0,0.1)",
                              color: "yellow",
                            }}
                          >
                            {alert}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12 text-left m-auto">
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                        Account :
                      </label>
                      <div className="col-12 col-sm-4  text-center">
                        <select
                          disabled={isActive || !isEditing}
                          required
                          onChange={(e) => {
                            setAccount(e.target.value);
                          }}
                          className="custom-select mr-sm-2 form-control"
                          defaultValue={props.bot.account}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {" "}
                          {!exchangesById[account] && (
                            <option key="deleted" value=""></option>
                          )}
                          {exchanges.map((item, key) => {
                            return (
                              <option key={key} value={item.id}>
                                {item.name + " ( " + item.exchange + " )"}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                        Account Taker :
                      </label>
                      <div className="col-12 col-sm-4  text-center">
                        <select
                          disabled={isActive || !isEditing}
                          required
                          onChange={(e) => {
                            setAccountTwo(e.target.value);
                          }}
                          className="custom-select mr-sm-2 form-control"
                          defaultValue={props.bot.accountTwo}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {" "}
                          {!exchangesById[accountTwo] && (
                            <option key="deleted" value=""></option>
                          )}
                          {exchanges.map((item, key) => {
                            return (
                              <option key={key} value={item.id}>
                                {item.name + " ( " + item.exchange + " )"}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2  col-form-label pl-3  pb-1  pt-sm-0 pt-2  verticalCenter">
                        Market :
                      </label>
                      <div className="col-12 col-sm-4">
                        <select
                          disabled
                          required
                          className="custom-select mr-sm-2 form-control"
                          value={props.bot.market}
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <option value={props.bot.market}>
                            {props.bot.market.toUpperCase()}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row my-3 ">
                      <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                        Is Synthetic Token :
                      </label>
                      <div className="col-4 my-1 col-sm-3 verticalCenter ">
                        <input
                          type="checkbox"
                          className="pr-3 mr-3 verticalCenter"
                          // style={{ fontSize: "1.5rem" }}
                          checked={isSyntheticToken}
                          disabled
                        />
                      </div>

                      {isSyntheticToken && (
                        <React.Fragment>
                          <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                            Token Price :
                          </label>
                          <div className="col-4 my-1 col-sm-3 verticalCenter ">
                            {currencyPrice}
                          </div>
                        </React.Fragment>
                      )}
                    </div>

                    {isSyntheticToken ? (
                      <React.Fragment>
                        {" "}
                        <div
                          // style={{ minWidth: "65rem" }}
                          className="row  text-center  overflow-hidden py-1 dark-3 border-bottom border-dark verticalCenter mt-1"
                        >
                          <div
                            className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark "
                            style={{ fontSize: "0.7rem", minHeight: "1rem" }}
                          >
                            Source Exchange
                          </div>
                          <div
                            className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                            style={{ fontSize: "0.7rem", minHeight: "1rem" }}
                          >
                            Source Market
                          </div>
                          <div
                            className="col-4 overflow-hidden border-right verticalCenter text-center justify-content-center border-dark"
                            style={{ fontSize: "0.7rem", minHeight: "1rem" }}
                          >
                            Weight
                          </div>
                        </div>
                        {sourceList.map((item, index) => {
                          return (
                            <div
                              key={index}
                              // style={{ minWidth: "65rem" }}
                              className="row  border-dark border-left border-bottom text-center bot-details-row"
                            >
                              <div
                                style={{ fontSize: "0.7rem" }}
                                className="col-4 center border-right border-dark py-2 px-0 overflow-hidden"
                              >
                                {item.exchange}
                              </div>
                              <div
                                style={{ fontSize: "0.7rem" }}
                                className="col-4 center border-right border-dark py-2 overflow-hidden"
                              >
                                {item.market.toUpperCase()}
                              </div>
                              <div
                                style={{ fontSize: "0.7rem" }}
                                className="col-4 center border-right border-dark py-2 overflow-hidden"
                              >
                                {item.weight}
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <div className="form-group row  mb-2">
                        <label className="col-12 col-sm-2  col-form-label pl-3 pb-1 pt-sm-0 pt-2  verticalCenter">
                          Price Source :
                        </label>
                        <div className="col-12 col-sm-4  text-center">
                          <select
                            required
                            onChange={(e) => {
                              setSecondEx(e.target.value);
                            }}
                            className="custom-select mr-sm-2 form-control"
                            value={secondEx}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <option value="no">Not Available</option>{" "}
                            <option value="kucoin">Kucoin</option>
                            <option value="gateio">Gateio</option>
                            <option value="binance">Binance</option>
                            <option value="mexc">Mexc</option>
                            <option value="huobi">Huobi</option>
                            <option value="okx">OKX</option>
                          </select>
                        </div>

                        {
                          secondEx !== "no" && (
                            <React.Fragment>
                              <label className="col-12 col-sm-2  col-form-label pl-3  pb-1  pt-sm-0 pt-2  verticalCenter">
                                Source Market :
                              </label>
                              <div className="col-12 col-sm-4">
                                <select
                                  disabled
                                  required
                                  className="custom-select mr-sm-2 form-control"
                                  value={sourceMarket}
                                  style={{
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <option value={sourceMarket}>
                                    {sourceMarket.toUpperCase()}
                                  </option>
                                </select>
                              </div>
                            </React.Fragment>
                          )
                          // : (
                          //   <React.Fragment>
                          //     <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                          //       Is Grid Type :
                          //     </label>
                          //     <div className="col-4 my-1 col-sm-3 verticalCenter ">
                          //       <input
                          //         type="checkbox"
                          //         className="pr-3 mr-3 verticalCenter"
                          //         // style={{ fontSize: "1.5rem" }}
                          //         checked={isGrid}
                          //         onChange={(e) => {
                          //           setGrid(e.target.checked);
                          //         }}
                          //         disabled={!isEditing}
                          //       />
                          //     </div>
                          //   </React.Fragment>
                          // )
                        }
                      </div>
                    )}

                    {secondEx === "no" && (
                      <div className="form-group row  my-3">
                        <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                          Base Price :
                        </label>
                        <div className="col-12 col-sm-4 mb-2">
                          <input
                            onChange={(e) =>
                              setInitialPrice(parseFloat(e.target.value))
                            }
                            type="number"
                            step={marketData.step.toString()}
                            min={0}
                            className="form-control"
                            required
                            value={initialPrice}
                            style={{
                              fontSize: "0.8rem",
                            }}
                            disabled={!isEditing}
                          />
                          <span
                            className=""
                            style={{
                              minWidth: "2.8rem",
                              position: "absolute",
                              right: "1rem",
                              top: "0.5rem",
                              color: "gray",
                            }}
                          >
                            {marketData.quoteAsset &&
                              marketData.quoteAsset.toUpperCase()}
                          </span>
                        </div>

                        <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                          Volume per 1% Price Change :
                        </label>
                        <div className="col-12 col-sm-4">
                          <input
                            onChange={(e) =>
                              setCapitalPer1PerChange(
                                parseFloat(e.target.value)
                              )
                            }
                            type="number"
                            step={(1 / assetBits.quote).toString()}
                            min={0}
                            className="form-control"
                            required
                            value={capitalPer1PerPriceChange}
                            style={{
                              fontSize: "0.8rem",
                            }}
                            disabled={!isEditing}
                          />
                          <span
                            className=""
                            style={{
                              minWidth: "2.8rem",
                              position: "absolute",
                              right: "1rem",
                              top: "0.5rem",
                              color: "gray",
                            }}
                          >
                            {marketData.quoteAsset &&
                              marketData.quoteAsset.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="form-group row  mb-2">
                      <label className="col-8 my-1 col-sm-3 col-form-label pl-3 pr-0  pb-0">
                        Is Activate Ticker Bot :
                      </label>
                      <div className="col-4 my-1 col-sm-3 verticalCenter ">
                        <input
                          type="checkbox"
                          className="pr-3 mr-3 verticalCenter"
                          // style={{ fontSize: "1.5rem" }}
                          checked={isTickerBotActivate}
                          onChange={(e) => {
                            setTickerBotActivate(e.target.checked);
                          }}
                          disabled={!isEditing}
                        />
                      </div>

                      {isTickerBotActivate && (
                        <React.Fragment>
                          <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                            Ticker Max OrderSize :
                          </label>
                          <div className="col-12 col-sm-4 mb-2">
                            <input
                              onChange={(e) =>
                                setTickerOrderSizeMax(
                                  parseFloat(e.target.value)
                                )
                              }
                              type="number"
                              step={(1 / assetBits.quote).toString()}
                              min={0}
                              className="form-control"
                              required
                              value={tickerOrderSizeMax}
                              style={{
                                fontSize: "0.8rem",
                              }}
                              disabled={!isEditing}
                            />
                            <span
                              className=""
                              style={{
                                minWidth: "2.8rem",
                                position: "absolute",
                                right: "1rem",
                                top: "0.5rem",
                                color: "gray",
                              }}
                            >
                              USDT
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                    </div>

                    <div className="form-group row  my-3">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Min Order Size :
                      </label>
                      <div className="col-12 col-sm-4 mb-2">
                        <input
                          onChange={(e) =>
                            setMinimumOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={minimumOrderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Max Order Size :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMaximumOrderSize(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={maximumOrderSize}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row  my-3">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Initial Base Assets Total :
                      </label>
                      <div className="col-12 col-sm-4 mb-2">
                        <input
                          onChange={(e) =>
                            setBaseAssetsTotal(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={baseAssetsTotal}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.baseAsset &&
                            marketData.baseAsset.toUpperCase()}
                        </span>
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "2rem",
                            top: "2rem",
                            color: "gray",
                          }}
                        >
                          ~{" "}
                          {(baseAssetsTotal * currencyPrice).toFixed(
                            Math.log10(assetBits.quote)
                          )}{" "}
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>

                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Initial Quote Assets Total :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setQuoteAssetsTotal(parseFloat(e.target.value))
                          }
                          type="number"
                          step={(1 / assetBits.quote).toString()}
                          min={0}
                          className="form-control"
                          required
                          value={quoteAssetsTotal}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "1rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          {marketData.quoteAsset &&
                            marketData.quoteAsset.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Min Spread :
                      </label>
                      <div className="col-12 col-sm-4">
                        <input
                          onChange={(e) =>
                            setMinSpread(parseFloat(e.target.value))
                          }
                          type="number"
                          step="0.01"
                          min="0.01"
                          max="100"
                          className="form-control"
                          required
                          value={minSpread}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "0rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPercent}
                            size="1x"
                            color="gray"
                          />
                        </span>
                      </div>

                      {secondEx === "no" && !isGrid && (
                        <React.Fragment>
                          <label className="col-12 col-sm-2 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                            Target Base Assets :
                          </label>
                          <div className="col-12 col-sm-4">
                            <input
                              onChange={(e) =>
                                setTargetBaseAssets(parseFloat(e.target.value))
                              }
                              type="number"
                              step="1"
                              min="0"
                              max="100"
                              className="form-control"
                              required
                              value={targetBaseAssets}
                              style={{
                                fontSize: "0.8rem",
                              }}
                              disabled={!isEditing}
                            />
                            <span
                              className=""
                              style={{
                                minWidth: "2.8rem",
                                position: "absolute",
                                right: "0rem",
                                top: "0.5rem",
                                color: "gray",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPercent}
                                size="1x"
                                color="gray"
                              />
                            </span>
                          </div>{" "}
                        </React.Fragment>
                      )}
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Extra Order Price Shape Factor :
                      </label>
                      <div className="col-12 col-sm-3">
                        <input
                          onChange={(e) =>
                            setOrderPriceShapeFactor(parseFloat(e.target.value))
                          }
                          type="number"
                          step="0.01"
                          min="0.01"
                          max="100"
                          className="form-control"
                          required
                          value={orderPriceShapeFactor}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                        <span
                          className=""
                          style={{
                            minWidth: "2.8rem",
                            position: "absolute",
                            right: "0rem",
                            top: "0.5rem",
                            color: "gray",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPercent}
                            size="1x"
                            color="gray"
                          />
                        </span>
                      </div>

                      <React.Fragment>
                        <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                          Extra Order Amount Shape Factor :
                        </label>
                        <div className="col-12 col-sm-3">
                          <input
                            onChange={(e) =>
                              setOrderAmountShapeFactor(
                                parseFloat(e.target.value)
                              )
                            }
                            type="number"
                            step="0.01"
                            min="0.01"
                            max="100"
                            className="form-control"
                            required
                            value={orderAmountShapeFactor}
                            style={{
                              fontSize: "0.8rem",
                            }}
                            disabled={!isEditing}
                          />
                          <span
                            className=""
                            style={{
                              minWidth: "2.8rem",
                              position: "absolute",
                              right: "0rem",
                              top: "0.5rem",
                              color: "gray",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPercent}
                              size="1x"
                              color="gray"
                            />
                          </span>
                        </div>{" "}
                      </React.Fragment>
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Extra Orders :
                      </label>
                      <div className="col-12 col-sm-3">
                        <input
                          onChange={(e) =>
                            setMaxOrders(parseFloat(e.target.value))
                          }
                          type="number"
                          step="1"
                          min="1"
                          max="100"
                          className="form-control"
                          required
                          value={maxOrders}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                      </div>

                      {secondEx === "no" && !isGrid && (
                        <React.Fragment>
                          <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                            Risk Factor :
                          </label>
                          <div className="col-12 col-sm-3">
                            <input
                              onChange={(e) =>
                                setRiskFactor(parseFloat(e.target.value))
                              }
                              type="number"
                              step="1"
                              min="0"
                              max="1000"
                              className="form-control"
                              required
                              value={riskFactor}
                              style={{
                                fontSize: "0.8rem",
                              }}
                              disabled={!isEditing}
                            />
                          </div>{" "}
                        </React.Fragment>
                      )}
                    </div>

                    <div className="form-group row  mb-2">
                      <label className="col-12 col-sm-3 col-form-label pl-3  pr-0 pb-1  pt-sm-0 pt-2  verticalCenter">
                        Order Interval <span className="px-1"> (Seconds) </span>{" "}
                        :
                      </label>
                      <div className="col-12 col-sm-3">
                        <input
                          onChange={(e) =>
                            setOrderInterval(parseFloat(e.target.value))
                          }
                          type="number"
                          step="1"
                          min="1"
                          className="form-control"
                          required
                          value={orderInterval}
                          style={{
                            fontSize: "0.8rem",
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                    </div>

                    {isEditing && (
                      <React.Fragment>
                        <div className="form-group row  my-3">
                          <div className="col-6 text-center m-auto">
                            <button
                              type="text"
                              className="btn btn-dark btn-sm  w-75"
                              onClick={() => {
                                setEditing(false);
                                reset();
                              }}
                            >
                              Back
                            </button>
                          </div>

                          <div className="col-6 text-center m-auto">
                            <button
                              disabled={alert && alert.length > 0}
                              type="submit"
                              className="btn btn-success btn-sm  w-75"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </form>
              {!isEditing && (
                <div className="form-group row  mt-2 mb-3">
                  <div className="col-6 text-center m-auto">
                    <button
                      disabled={props.bot.isPending}
                      type="text"
                      className="btn btn-dark btn-sm  w-75"
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketMakerBotSettings;
