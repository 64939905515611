import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

import worker from "workerize-loader!../backend/worker"; // eslint-disable-line import/no-webpack-loader-syntax

const workerInstance = worker();

function BotNotifications(props) {
  const [notificationsOrdered, setNotificationOrderd] = useState([]);
  const [count, setCount] = useState(10);
  const [newNotifications, setnewNotifications] = useState(0);
  const [allReceived, setAllreceived] = useState(false);

  useEffect(() => {
    if (props.id) {
      workerInstance.addEventListener("message", (message) => {
        if (message.data && message.data.type === "botNotifications") {
          setNotificationOrderd(message.data.data.list);
          setnewNotifications(message.data.data.unreadCount);
          if (message.data.data.list.length < count) {
            setAllreceived(true);
          } else {
            setAllreceived(false);
          }
        }
      });
      firebase
        .database()
        .ref("/userNotifications/" + props.id)
        .orderByChild("createdAt")
        .limitToLast(count)
        .on("value", (snapshot) => {
          workerInstance.getBotNotifications(snapshot.val());
        });
    }

    return () => {
      firebase
        .database()
        .ref("/userNotifications/" + props.id)
        .orderByChild("createdAt")
        .limitToLast(count)
        .off();
    };
  }, [props.id, count]);

  const handleRead = (item) => {
    if (!item.isRead) {
      firebase
        .database()
        .ref("userNotifications/" + props.id + "/" + item.key)
        .update({
          ...item,
          isRead: true,
        });
    }
  };
  const handleReadAll = () => {
    notificationsOrdered.forEach((element) => {
      if (!element.isRead) {
        firebase
          .database()
          .ref("userNotifications/" + props.id + "/" + element.key)
          .update({
            ...element,
            isRead: true,
          });
      }
    });
  };

  return (
    <div className="container-fluid dark-2 rounded-lg py-3 my-3 mx-0">
      <div className="row">
        <div className="col-12 text-center ">
          <span
            className="notification"
            style={{
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Notifications{" "}
            {newNotifications > 0 && (
              <span className="badge">
                {newNotifications}
                {newNotifications > count - 1 && "+"}
              </span>
            )}
          </span>
        </div>{" "}
        <button
          onClick={() => handleReadAll()}
          className="border-dark px-3 dark-3"
          style={{
            borderRadius: "0.5rem",
            // background: "none",
            position: "absolute",
            right: "3rem",
          }}
        >
          <FontAwesomeIcon icon={faCheckDouble} size="1x" color="white" />
        </button>
      </div>
      <div
        className="row"
        style={{
          overflowY: "auto",
          maxHeight: "27.4rem",
        }}
      >
        <div className="col-12">
          {notificationsOrdered && notificationsOrdered.length > 0 ? (
            <React.Fragment>
              {notificationsOrdered.map((item, index) => {
                var d = new Date(item.createdAt);
                var date =
                  d.toLocaleDateString() + " | " + d.toLocaleTimeString();
                return (
                  <div
                    key={index}
                    className="row rounded-lg p-2 m-2 text-left dark-2"
                  >
                    <div
                      className="col-12 btn"
                      onClick={() => handleRead(item)}
                    >
                      <div className="row">
                        <div
                          className="col-12 text-left"
                          style={
                            item.isRead
                              ? { color: "#bfbfbf" }
                              : { color: "#ffeb99" }
                          }
                        >
                          {item.msg}
                        </div>
                      </div>{" "}
                      <div className="row">
                        <div
                          style={{ color: "#808080", fontSize: "0.6rem" }}
                          className="col-12 text-left"
                        >
                          {date}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {!allReceived && (
                <div className="row rounded-lg border-dark border p-1 m-2 dark-2">
                  <button
                    className="col-12 text-center"
                    style={{
                      background: "none",
                      border: "none",
                    }}
                    onClick={() => {
                      setCount(count + 50);
                    }}
                  >
                    Load More
                  </button>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="row rounded-lg border-dark border p-3 m-2 dark-2">
              <div className="col-12 text-center">
                {" "}
                Don't have any notification.
              </div>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BotNotifications;
