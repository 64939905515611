import React, { useEffect, useState } from "react";
import { Context } from "../../../backend/context";
import { Link } from "react-router-dom";

import TickerBotSummery from "./summery";
import TickerBotSettings from "./botSettings";

import { connect } from "react-redux";
import OrdersChart from "./ordersChart";
import AvtiveDealDetails from "../../activeDealsDetails";
import BotNotifications from "../../botNotifications";

function BotDetails(props) {
  const { getMarketData } = React.useContext(Context);
  const [bot, setBot] = useState(null);

  useEffect(() => {
    setBot(props.allBots[props.match.params.id]);
  }, [props.match.params.id, props.allBots]);

  if (bot) {
    return (
      <div className="container-fluid dark-1 ">
        <div className="row">
          <div className="col-10 h5 px-5 font-weight-bold">
            <Link
              to="/account/tickerBots"
              className=""
              style={{ color: "#b3b3b3" }}
            >
              Ticker Bot
            </Link>
          </div>{" "}
        </div>
        <div className="row ">
          <div className="col-12">
            <TickerBotSummery bot={bot} marketData={getMarketData(bot)} />
          </div>
        </div>

        <div className="row ">
          <div className={`col-12 col-lg-6`}>
            <TickerBotSettings bot={bot} />
          </div>

          <div className="col-12 col-lg-6 py-3  ">
            <div className="row">
              <div className="col-12 ">
                <AvtiveDealDetails bot={bot} marketData={getMarketData(bot)} />
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ minHeight: "10.8rem" }}>
                <OrdersChart bot={bot} marketData={getMarketData(bot)} />
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-12 ">
            <BotNotifications id={props.match.params.id} bot={bot} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-fluid dark-1 ">
        <div className="row ">
          <div className="col-12">Bot Not Found.</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allBots: state.bots.allBots,
});

export default connect(mapStateToProps)(BotDetails);
