import React from "react";
import Auth from "./auth";

function FixedAuth(props) {
  return (
    <div className="container-fluid p-0 m-0 dark-1">
      <div className="row p-0 m-0">
        <div className="col-12 p-0 m-0">
          <div
            className=" h-100 w-100 center "
            style={{
              position: "fixed",
              zIndex: 10000,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              top: "0%",
            }}
          >
            <div
              style={{ backgroundColor: "#0d0d0d" }}
              className="p-2 rounded-lg mt-0 "
            >
              {" "}
              <div className="pt-4 px-3 pb-3">
                {" "}
                <Auth isSignIn={props.isSignIn} isPopUp={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-0 m-0 verticalCenter" style={{ maxHeight: "100%" }}>
        <div className="col-12  text-center my-4 ">
          <div className="row mb-4">
            <div className="col-12">
              <h1>Coinner x Byte Exchange - Market Maker Trading bots.</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FixedAuth;
