import { SET_PENDING_BOTS } from "../actions/types";

const initialState = {
  hedgeBots: [],
  tickerBots: [],
  marketMakerBots: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PENDING_BOTS:
      return {
        ...state,
        hedgeBots: action.payload.hedgeBots,
        tickerBots: action.payload.tickerBots,
        marketMakerBots: action.payload.marketMakerBots,
      };

    default:
      return state;
  }
};

export default reducer;
