import { SET_PENDING_BOTS } from "./types";
export const setPendingBotsData = (data) => {
  // console.log(data);
  return (dispatch) => {
    dispatch({
      type: SET_PENDING_BOTS,
      payload: {
        hedgeBots: data.hedgeBots,
        tickerBots: data.tickerBots,
        marketMakerBots: data.marketMakerBots,
      },
    });
  };
};
