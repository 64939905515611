import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

function BotOrderDetails(props) {
  const [filledOrders, setfilledOrders] = useState([]);
  const [completedDeals, setcompletedDeals] = useState([]);
  const [filledOrdersRequested, setfilledOrdersRequested] = useState(false);

  useEffect(() => {
    if (props.type !== "onlyFilled") {
      firebase
        .database()
        .ref(`/completedDeals/${props.id}`)
        .orderByChild("createdAt")
        .limitToLast(100)
        .on("value", (snapshot) => {
          var botData = [];
          for (var id in snapshot.val()) {
            botData.push(snapshot.val()[id]);
          }
          setcompletedDeals(botData.sort(compareProfits));
        });
    }

    getFilledOrders();
  }, [props.id, props.type]);

  function compareProfits(a, b) {
    if (new Date(a.createdAt) < new Date(b.createdAt)) {
      return 1;
    }
    if (new Date(a.createdAt) > new Date(b.createdAt)) {
      return -1;
    }
    return 0;
  }
  function compareFilled(a, b) {
    if (new Date(a.filledAt) < new Date(b.filledAt)) {
      return 1;
    }
    if (new Date(a.filledAt) > new Date(b.filledAt)) {
      return -1;
    }
    return 0;
  }

  function getFilledOrders() {
    //get filled orders
    if (props.id && props.id.length > 0 && !filledOrdersRequested) {
      setfilledOrdersRequested(true);
      // console.log("object");
      firebase
        .database()
        .ref(`/filledOrders/${props.id}`)
        .orderByChild("filledAt")
        .limitToLast(100)
        .on("value", (snapshot) => {
          var botData = [];
          for (var id in snapshot.val()) {
            botData.push(snapshot.val()[id]);
          }
          setfilledOrders(botData.sort(compareFilled));
        });
    }
  }

  return (
    <div className="row dark-2 rounded-lg pb-3  pt-3 my-3 mx-0">
      <div className="col-12">
        <div className="container-fluid text-center px-0" id="accordion">
          <div className="row">
            <div
              className="col-12  text-center pb-2"
              style={{
                fontColor: "white",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Order Details
            </div>
          </div>
          {props.type !== "onlyFilled" && (
            <div className="cardNo">
              <button
                className="w-100 ordersDetailsButton collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Profits / Losses
              </button>

              <div
                id="collapseFour"
                className="collapse "
                aria-labelledby="headingFour"
                data-parent="#accordion"
              >
                <div
                  className="mb-2 "
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    maxHeight: "12.1rem",
                  }}
                >
                  {completedDeals.length > 0 ? (
                    completedDeals.map((item, key) => (
                      <div
                        key={key}
                        className="row dark-1 rounded-lg overflow-hidden my-1 py-1 no-gutters"
                      >
                        <div
                          className=" col-6 overflow-hidden grid-item center px-0"
                          style={{ fontSize: "0.7rem" }}
                        >
                          {item.createdAt &&
                            new Date(item.createdAt).toLocaleString()}
                        </div>
                        <div
                          className=" col-3 overflow-hidden grid-item center px-0"
                          style={{
                            color:
                              item.profit && item.profit < 0
                                ? "#ff3333"
                                : "green",
                          }}
                        >
                          {" "}
                          {item.profit.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}{" "}
                        </div>
                        <div
                          className=" col-3 overflow-hidden grid-item center px-0"
                          style={{
                            color:
                              item.profit && item.profit < 0
                                ? "#ff3333"
                                : "green",
                          }}
                        >
                          ( {parseFloat(item.profitPer.toFixed(2))} % )
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="row dark-1">
                      <div className="col-12">No Data</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="cardNo">
            <button
              className="w-100 ordersDetailsButton "
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Filled Orders
            </button>
            <div
              id="collapseTwo"
              className="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div
                className="my-2 border-left border-dark"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxHeight: "22.5rem",
                }}
              >
                <div className="row border-dark border-bottom no-gutters">
                  <div className=" border-right border-dark col-4 overflow-hidden px-0 center">
                    Date
                  </div>

                  <div className=" border-right border-dark col-2 overflow-hidden px-0  center">
                    Type
                  </div>
                  <div className=" border-right border-dark col-3 overflow-hidden px-0  center">
                    Price (
                    {props.marketData &&
                      props.marketData.quoteAsset.toUpperCase()}
                    )
                  </div>
                  <div className=" border-right border-dark col-3 overflow-hidden px-0 center">
                    Amount (
                    {props.marketData &&
                      props.marketData.baseAsset.toUpperCase()}
                    )
                  </div>
                </div>
                {filledOrders.length > 0 ? (
                  filledOrders.map((item, key) => (
                    <div
                      key={key}
                      className="row border-dark border-bottom  no-gutters"
                      style={{ color: "#999999" }}
                    >
                      <div
                        className=" border-right border-dark col-4 overflow-hidden grid-item center px-0 "
                        style={{ fontSize: "0.65rem" }}
                      >
                        {new Date(item.filledAt).toLocaleString()}
                      </div>

                      <div className=" border-right border-dark col-2 overflow-hidden grid-item center px-0">
                        {item.buyOrSell}
                      </div>
                      <div className=" border-right border-dark col-3 overflow-hidden grid-item center px-0">
                        {parseFloat(item.limitPrice)}
                      </div>
                      <div className=" border-right border-dark col-3 overflow-hidden grid-item center px-0">
                        {parseFloat(item.amount.amount)}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="row border-dark border-bottom no-gutters">
                    <div className=" border-right border-dark col-12">
                      No Orders
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BotOrderDetails;
