import React from "react";

import { LoopCircleLoading } from "react-loadingg";

function LoadingScreen(props) {
  return (
    <div
      className="center h-100 w-100"
      style={{
        position: "absolute",
        zIndex: 100000,
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
    >
      <div
        className="container-fluid dark-1 rounded-lg"
        style={{
          minWidth: "15rem",
          minHeight: "8.5rem",
          maxWidth: "0",
          maxHeight: "10rem",
        }}
      >
        <div className="row text-center p-3">
          <div className="col-12">{props.msg}</div>
        </div>
        <div className="row center">
          <div className="col-12 center mt-2">
            <LoopCircleLoading
              style={{ position: "relative" }}
              color="#0080ff"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
