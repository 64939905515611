import { SET_ACTIVE_DEALS } from "./types";
export const setActiveDealsData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_DEALS,
      payload: {
        data: data,
      },
    });
  };
};
